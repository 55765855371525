@import "../../scss/style.scss";

.blueStrip {
  width: 100%;
  background-color: $primary-color;
  .leftSection {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: $primaryFonts;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 29px;
    color: #fbfbfb;
    padding: 20px 0;
    .backArrow {
      width: 28px;
      height: 24px;
      margin-right: 20px;
    }
  }
  .rightSection {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn {
      border-radius: 4px;
      border-color: $white;
      font-family: $primaryFonts;
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 15px;
      letter-spacing: 0;
      padding: 8px 15px;
      margin-left: 10px;
      min-width: 130px;
      text-align: center;
      justify-content: center;
      &.btn-outline-white {
        color: $white;
        &:hover,
        &:focus {
          background-color: $white;
          color: #323c47;
        }
      }
      &.btn-white {
        background-color: $white;
        color: #323c47;
        &:hover,
        &:focus {
          background-color: $primary-dark;
          color: $white;
        }
      }
    }
  }
}

.CreateMeeting {
  section {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 4px;
    overflow: hidden;
    head {
      background: #efefef;
      border-bottom: 1px solid #e5e5e5;
      box-sizing: border-box;
      padding: 18px 40px;
      font-family: $primaryFonts;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 25px;
      display: flex;
      align-items: center;
      color: #444444;
    }
    .content {
      padding: 24px 70px;
      .basicForm {
        .form-group {
          margin-bottom: 16px !important;
        }
        label {
          font-family: $primaryFonts;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 0;
          text-transform: none;
          color: #333333;
          padding: 10px 0 !important;
        }
        .to {
          font-family: $primaryFonts;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #333333;
        }
        input,
        select,
        textarea {
          margin: 0;
          padding: 10px 12px;
          height: auto;
          background: #ffffff;
          border: 1px solid #e5e5e5;
          box-sizing: border-box;
          border-radius: 4px;

          font-family: $primaryFonts;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 0;
          color: #333333;
        }
        textarea {
          height: 80px;
          resize: none;
        }
        select {
          padding-right: 20px;
          background: url(/static/media/keyboard_arrow_down.7a3f5270.svg)
            no-repeat right 12px center/10px 8px;
        }
        .time {
          font-style: normal;
          font-weight: 600;
          font-size: 13.2152px;
          line-height: 16px;
          color: #323c47;
        }
        [class^="col-sm"] {
          padding: 0;
        }
        .timeSection {
          .react-datepicker-wrapper {
            display: block;
          }
          [class^="col-sm"] {
            padding: 0 10px 0 0;
          }
        }
        h4 {
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
          color: #323c47;
          margin: 0;
          padding: 0;
        }
        .addParticipantSec {
          .form-group {
            align-items: center;
            margin-bottom: 0 !important;
            .addParticipants {
              margin-left: auto;
            }
          }
        }
        .addParticipants {
          font-family: $primaryFonts;
          font-style: normal;
          font-weight: 600;
          font-size: 11px;
          line-height: 13px;
          text-align: center;
          color: $primary-color;
          padding: 8px 13px;
          background: $white;
          border: 1px solid $primary-color;
          box-sizing: border-box;
          border-radius: 4px;
          &:hover,
          &:focus {
            outline: none;
            box-shadow: none;
            background-color: $primary-color;
            color: $white;
          }
        }

        .participantsList {
          margin: 0;
          padding: 0;
          list-style: none;
          display: flex;
          flex-wrap: wrap;
          margin-top: 25px;
          li {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 5px;
            cursor: pointer;
            min-width: 145px;
            margin-right: 8px;
            span {
              padding: 5px 10px;
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #fff;
              font-style: normal;
              font-weight: 500;
              font-size: 11px;
              line-height: 17px;
              text-transform: uppercase;
              border-radius: 3px;
              margin-right: 1px;
              background-color: #c00;
              flex: 0 0 30px;
            }
            em {
              padding: 7px 5px;
              width: calc(100% - 33px);
              height: 30px;
              display: block;
              font-family: $primaryFonts;
              font-style: italic;
              font-weight: bold;
              font-size: 10px;
              line-height: 16px;
              text-align: left;
              letter-spacing: 0;
              text-transform: uppercase;
              color: #444444;
              text-shadow: 0px 0px 5px #e6e6e6;
              border-radius: 3px 0px 0px 3px;
              background-color: #f2f2f2;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .closeIcon {
              padding: 3px 0px;
              height: 30px;
              display: flex;
              align-items: center;
              font-family: $primaryFonts;
              font-style: italic;
              font-weight: bold;
              font-size: 10px;
              line-height: 16px;
              text-align: left;
              letter-spacing: 0;
              text-transform: uppercase;
              color: #444444;
              text-shadow: 0px 0px 5px #e6e6e6;
              border-radius: 0px 3px 3px 0px;
              background-color: #f2f2f2;
              white-space: nowrap;
              overflow: hidden;
              svg {
                width: 12px;
                height: 12px;
                path {
                  stroke: #444444;
                }
              }
            }
          }
        }
        .timeAreaWrap {
          display: flex;
          .timeSection.timeArea {
            width: 50%;
            label.col-form-label {
              flex: 0 0 35%;
              max-width: 35%;
            }
            .col-sm-2 {
              flex: 0 0 32%;
              max-width: 32%;
            }
            .to {
              flex: 0 0 20%;
              max-width: 20%;
              margin-left: 14px;
            }
          }
        }
      }
    }
  }
}

.modal-dialog.assignModal {
  max-width: 450px;
  width: 90%;
  margin: auto;
  .modal-content {
    .modal-header {
      background-color: $white;
      border: none !important;
      padding: 16px 30px !important;
      .closeBtn {
        svg {
          path {
            stroke: #9c9c9c;
          }
        }
      }
      .modal-title {
        color: #333333;
        display: flex;
        align-items: center;
        line-height: normal;
        .whiteIcon {
          background-color: $primary-color !important;
          width: 28px !important;
          height: 28px !important;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          svg {
            padding: 1px;
            path {
              fill: $primary-color;
              stroke: $white;
            }
          }
        }
      }
      button.closeBtn {
        background-color: transparent;
        border: 0;
        padding: 0;
      }
    }
    h5.modal-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 40px;
      letter-spacing: 0;
      color: #333333;
    }
  }
  .modal-body {
    //max-height: 75vh;
    .box {
      width: 85% !important;
      padding: 0;
      margin: 0;
      float: left;
      .assignModalSearch {
        background-color: #f1f1f1;
        border-radius: 8px;
        input.form-control {
          margin: 0;
          border: none !important;
          background-color: transparent;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0;
          color: #3c444c;
          height: 37px;
        }
        .btn.btn-secondary {
          background-color: transparent;
          border: none;
          img {
            width: 15px;
          }
        }
      }
      .listing {
        width: 100%;
        height: auto;
        background: #ffffff;
        box-shadow: 1px 1px 12px rgba(144, 168, 190, 0.33);
        border-radius: 8px;
        margin: 22px 0 0;
        padding: 0;
        h4 {
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 22px;
          color: #3c444c;
          padding: 18px 20px 12px;
          margin: 0;
          cursor: pointer;
          background: url("../../assets/img/arrow_down_gray_icon.svg") no-repeat
            right 20px center / 12px 12px;
        }
        .list {
          margin: 0;
          padding: 0;
          list-style: none;
          li {
            width: 100%;
            border-bottom: 1px solid #e5e5e5;
            padding: 8px 30px !important;
            // input.form-check-input {
            // 	//display: none;
            // }
          }
          &.staff {
            li {
              .form-check-label {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                clear: both;
                cursor: pointer;
                padding: 0;
                &::before {
                  content: "";
                  display: block;
                  width: 20px;
                  height: 20px;
                  border-radius: 20px;
                  margin-right: 8px;
                  background: rgba($color: $grey-rgb-color, $alpha: 0.17)
                    url("../../assets/img/university/tick_white_icon.svg")
                    no-repeat center center / 11px 11px;
                }
                &.selected {
                  &::before {
                    background: $primary-color
                      url("../../assets/img/university/tick_white_icon.svg")
                      no-repeat center center / 11px 11px;
                  }
                }
                input {
                  display: none;
                }
                .profilePic {
                  width: 33px;
                  height: 33px;
                  border-radius: 33px;
                  background-repeat: no-repeat;
                  background-position: center center;
                  background-size: cover;
                  margin-right: 10px;
                  text-indent: -5000px;
                  overflow: hidden;
                }
                .personalDetials {
                  max-width: 120px;
                  overflow: hidden;
                  span {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    display: block;
                    font-size: 10px;
                  }
                  strong {
                    display: block;
                    text-transform: capitalize;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    display: block;
                  }
                }
                .groupName {
                  // width: 25%;
                  text-align: right;
                  text-transform: capitalize;
                  color: $primary-color;
                  font-size: 10px;
                  font-weight: 400;
                  margin-left: auto !important;
                  width: auto !important;
                  max-width: 85px;
                }
              }
            }
          }
        }
      }
    }
    .alphaSorting {
      width: 15% !important;
      float: right;
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        width: 100%;
        padding-left: 8px !important;
        li {
          text-align: center;
          label {
            display: block;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0;
            color: #3c444c;
            margin: 0;
            padding: 0;
            cursor: pointer;
            input.form-check-input {
              display: none !important;
            }
          }
        }
      }
    }
  }
  .modal-footer {
    background-color: #f9f9f9;
    border-top: 0;
    padding: 23px 20px;
    justify-content: center;
    .btn.btn-primary {
      background: #4da1ff;
      border-radius: 4px !important;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0;
      padding: 7px 50px;
      width: auto !important;
    }
  }
}

@media only screen and (max-width: 991px) {
  .blueStrip {
    .leftSection {
      font-weight: 700 !important;
      font-size: 16px !important;
      line-height: 19px !important;
      padding: 20px 20px 16px !important;
      .backArrow {
        width: 15px !important;
        height: 12px !important;
        margin-right: 12px !important;
      }
    }
    .rightSection {
      justify-content: center !important;
      padding-bottom: 20px;
      .btn {
        padding: 8px 5px !important;
        min-width: 30% !important;
        margin: 0 2%;
      }
    }
  }
  .CreateMeeting {
    section {
      head {
        font-weight: 500;
        font-size: 12px;
        line-height: 25px;
        justify-content: center;
        padding: 12px 20px;
      }
      .content {
        padding: 24px 20px;
        .basicForm {
          label {
            padding: 10px 15px !important;
          }
          [class^="col-sm"] {
            padding-left: 15px;
            padding-right: 15px;
          }
          .react-datepicker-wrapper {
            padding-left: 15px;
            padding-right: 5px;
          }
          .timeAreaWrap {
            .timeSection.timeArea {
              margin-left: 0;
              margin-right: 0;
              label.col-form-label {
                flex: 0 0 100%;
                max-width: 100%;
                padding-left: 0 !important;
              }
              .col-sm-2 {
                flex: 0 0 50%;
                max-width: 50%;
                padding: 0 5px;
              }
              .to {
                flex: 0 0 100%;
                max-width: 100%;
                padding: 10px 15px;
                padding-left: 0 !important;
                justify-content: flex-start;
              }
            }
          }
        }
      }
    }
  }
  .modal-dialog.assignModal {
    .modal-body {
      padding: 0 5px 0 18px !important;
      .box .listing .list li {
        padding: 8px 18px !important;
      }
      .box .listing .list.staff li .form-check-label .groupName {
        max-width: 52px;
        text-align: center !important;
      }
    }
  }
}
