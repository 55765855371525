@import "../../scss/style.scss";

.primaryModal {
  .modal-content {
    border-radius: 10px !important;
    border: 0 none;
    box-shadow: 1px 4px 10px rgba(0, 82, 180, 0.25);

    .modal-footer.shareProfile {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 30px;

      .btn {
        width: 120px;
        height: auto;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px !important;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #979797;
        padding: 5px 10px;

        &.btn-primary {
          background: #2d6163 !important;
          border: 1px solid #2d6163;
          color: #ffffff;
        }

        &.btn-outline-secondary {
          background: transparent !important;
          border: 1px solid #bdbdbd;
          color: #979797;
        }
      }
    }

    .modal-body {
      border-radius: 10px;
      background-color: #fff;
      padding: 0;

      &.addfilepopup {
        padding: 40px 70px !important;
        text-align: center;
        h2 {
          font-weight: 600;
          font-size: 20px;
          line-height: 50px;
          text-align: start;
          color: #000000;
          padding: 0;
          margin: 0 auto 40px;

          img,
          svg {
            margin-top: -3px;
            margin-right: 10px;
            width: 22px;
            height: 22px;
          }
        }

        .form-group {
          width: 100%;

          .dropdown {
            width: 100%;

            .dropdown-toggle {
              width: 100%;
              border: 1.467px solid #BDBDBD;
              border-radius: 4px !important;

              &::after {
                position: absolute;
                right: 0;
                left: auto;
              }
            }

            .dropdown-menu {
              width: 100%;
            }
          }
        }
        .closeIcon {
        svg.pointer {
          width: 14px;
      }
    }
      }

      .modalBody {
        padding: 30px 50px;

        .title {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 27px;
          color: #444444;
        }

        .subTitle {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #555;
        }

        .customCheckbox {
          margin: 25px 0 0;
          padding: 0;
          list-style: none;

          li {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            flex: 0 0 100%;
            margin-bottom: 15px;

            .card-label {
              margin: -3px 0 0;
              padding: 0;
              font-size: 13px;
              line-height: 20px;
              text-transform: none;
              color: #555;
            }
          }
        }
      }

      .modalActionBtn {
        margin-bottom: 20px;
      }

      .input-group {
        input {
          padding: 14px 20px;
          border-right: none;
          font-size: 12px;
          line-height: 18px;
          height: auto;
        }
      }

      input,
      select.form-control,
      select.custom-select {
        border: 1px solid #e0e0e0 !important;
        border-right: 1px solid #e0e0e0 !important;
        padding: 13px 15px;
        margin: 0;
        border-right: none;
        font-size: 12px;
        line-height: 18px;
        height: auto;
      }

      .form-check-input {
        position: relative;
        margin-right: 10px !important;
        display: inline-block;
      }

      .viewerList {
        width: 100%;
        flex: 0 0 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: 600;
        font-size: 15px;
        line-height: 25px;
        text-align: left;
        color: #000000;
        margin-bottom: 10px;

        .logo {
          background-color: #f1f1f1;
          border: 1px solid #ddd;
          width: 57px;
          height: 43px;
          margin: 0 30px 0 25px;
          position: relative;

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .justify-content-center {
        justify-content: center !important;
        text-align: center !important;
      }

      .btn {
        &.btn-outline-primary {
          background-color: transparent;
          border-color: #4da1ff;
          border-radius: 3px !important;
          color: #4da1ff;
          font-weight: 600 !important;
          font-size: 12px !important;
          line-height: 20px !important;
          text-align: center;
          color: #109cf1;
          padding: 6px 25px !important;
          margin: auto;

          &:hover,
          &:focus {
            background-color: #4da1ff;
            color: #fff;
          }
        }
      }
    }

    .modal-header {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #4da1ff;
      color: #fff;
      padding: 30px;
      position: relative;
      text-align: left;
      // display: block;
      border-radius: 10px 10px 0 0;

      .modal-title {
        font-weight: 700;
        font-size: 20px;
        line-height: 33px;
        text-align: left;
      }

      .close {
        text-shadow: none;
        color: #fff;
        opacity: 1;
        font-weight: 400;
        font-size: 30px;
        line-height: 30px;
        position: absolute;
        top: 10px;
        right: 20px;
        padding: 0;
        margin: 0;
      }

      .closeBtn {
        background-color: transparent;
        border: none;
        width: 24px;
        height: 24px;
        padding: 0;
        margin: 0;
        transition: all 0.3s ease-in-out;
        transform: scale(1);

        img {
          width: 100%;
          height: auto;
          display: block;
        }

        &:focus,
        &:hover {
          border: none;
          outline: none;
          transform: scale(1.2);
        }
      }

      &.text-left {
        justify-content: space-between;

        .modal-title {
          display: flex;
          align-items: center;

          .whiteIcon {
            width: 26px;
            height: 26px;
            border-radius: 30px;
            background-color: #fff;
            display: inline-flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      &.font16 {
        .modal-title {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 40px;
          letter-spacing: 0;
        }
      }
    }

    .modal-footer {
      border-top:none;
      padding-bottom: 60px;

      .btn {
        &.refreshEligibility {
          background-color: #2d6163;
          color: #ffffff;
          border-color: #2d6163;
          padding: 10px 20px;
          box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
          border-radius: 3px;
          font-family: $primaryFonts;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 15px;
          text-align: center;
          text-transform: uppercase;

          &:hover,
          &:focus {
            background-color: $text-dark;
            border-color: $text-dark;
            color: $white;
          }
        }
        &.backtoBtn {
          border: 3px solid #2d6163;
          box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
          border-radius: 3px;
          padding: 10px 50px;
          font-family: $primaryFonts;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 15px;
          text-align: center;
          text-transform: uppercase;
          background-color: transparent;
          color: #2d6163;
          margin-right: 30px;
          
          &:hover,
          &:focus {
            background-color: $text-dark;
            border-color: $text-dark;
            color: $white;
          }
        }
      }

      &.text-center {
        justify-content: center  !important;
      }

      &.offWhite {
        border: none;
        background-color: #f9f9f9;
      }
    }
  }

  &.noRadius {
    .modal-content,
    .modal-footer {
      border-radius: 0;
    }
  }

  &.changePasswordModal {
    .modal-header {
      background: transparent;
      color: #444444;
      text-align: left;
      justify-content: flex-start;
      padding-left: 95px;
      border-bottom: 0px;
      padding-bottom: 0px;
      padding-top: 20px;
    }
    .card-label {
      font-size: 12px !important;
      font-weight: 400 !important;
      line-height: 24px !important;
  }

    .modal-body {
      .left-error-msg {
        display: block;
        flex-basis: 100%;
        width: 100%;
        margin: 8px 0 10px;
      }
    }

    .modal-footer { 
      
      .btn {
      max-width: 180px !important;
      min-width: 180px !important;
      padding: 14px 10px;
      font-size: 14px;
      font-weight: 400;
      font-family: 'Open Sans';
    }
    button.btn.btn-secondary {
      border-radius: 2.45px;
      border: 1px solid $portalMainColor;
      background: var(--white, #FFF);
      box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
  }
    button.btn.btn-primary {
      background: $primary-green-color;
      border-color: $primary-green-color !important;
      margin-right: 16px;
  }
    }
  }

  &.eligibiltyModal {
    max-width: 630px;

    .eligibilityModalContent {
      padding: 60px 90px 0px 90px;


      img {
        margin-bottom: 0;
      }

      span {
        font-family: $primaryFonts;
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 38px;
        display: block;
        color: #333333;
        margin: 30px 0 30px;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: #4f4f4f;
        margin-bottom: 30px;
      }
    }

    &.applyRadio {
      max-width: 600px;

      .modal-body {
        padding: 0;

        .modal_header {
          padding: 20px 20px 0;
        }

        .eligibilityModalContent {
          padding: 20px 0 0;
          label {
            font-weight: 400;
          }

          .box {
            padding: 0 70px;
            text-align: left;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 80px;

            label {
              padding: 0;
              margin: 0;

              .option {
                padding-left: 15px;

                strong {
                  font-family: "Open Sans";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 20px;
                  line-height: 27px;
                  color: #000000;
                }

                p {
                  font-family: "Open Sans";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 18px;
                  color: #333333;
                  padding: 0;
                  margin: 10px 0 0;
                }
              }
            }

            .form-check-input {
              accent-color: #2b6a6c;
              width: 20px;
              height: 20px;
            }

            &.one {
              background-color: #eaf0f0;
            }
          }
        }
      }

      .modal-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        // border-top: 1px solid rgba(216, 216, 216, 0.5);
        padding: 22px;

        .btn {
          &.btn-secondary {
            background-color: #2b6a6c;
            box-shadow: none;
            border-radius: 10px !important;
            border: 1px solid #2b6a6c;
            padding: 12px 40px;
            font-family: "Open Sans";
            font-weight: 400;
            font-size: 11px;
            line-height: 12px;
            text-align: center;
            letter-spacing: 0.04em;
            text-shadow: none;
            color: #ffffff;
            &:hover,
            &:focus {
              background-color: #fff;
              color: #2b6a6c;
            }
          }
          &.backtoBtn {
            border: 3px solid #2d6163 !important;
            box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06) !important;
            border-radius: 3px !important;
            padding: 15px 20px !important;
            background-color: transparent !important;
          }
        }
      }
    }
  }
}

.primaryModal2 {
  box-shadow: 1px 4px 10px rgba(0, 82, 180, 0.25);

  .modal-content {
    border-radius: 8px;
    border: 0 none;
    margin-top: 15em;

    .modal-body {
      .input-group {
        input {
          padding: 14px 20px;
          border-right: none;
          font-size: 12px;
          line-height: 18px;
          height: auto;
        }
      }

      input,
      select.form-control,
      select.custom-select {
        border: 1px solid #e0e0e0 !important;
        border-right: 1px solid #e0e0e0 !important;
        padding: 13px 15px;
        margin: 5px 0;
        border-right: none;
        font-size: 12px;
        line-height: 18px;
        height: auto;
      }

      .form-check-input {
        position: relative;
        margin-right: 10px !important;
        display: inline-block;
      }
    }

    .modal-header {
      justify-content: center;
      align-items: center;
      background-color: #4da1ff;
      color: #fff;

      .modal-title {
        font-weight: bold;
        font-size: 25px;
        line-height: 29px;
        letter-spacing: 0;
      }

      .closeBtn {
        background-color: transparent;
        border: none;
        width: 24px;
        height: 24px;
        padding: 0;
        margin: 0;
        transition: all 0.3s ease-in-out;
        transform: scale(1);

        img {
          width: 100%;
          height: auto;
          display: block;
        }

        &:focus,
        &:hover {
          border: none;
          outline: none;
          transform: scale(1.2);
        }
      }

      &.text-left {
        justify-content: space-between;

        .modal-title {
          display: flex;
          align-items: center;

          .whiteIcon {
            width: 26px;
            height: 26px;
            border-radius: 30px;
            background-color: #fff;
            display: inline-flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      &.font16 {
        .modal-title {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 40px;
          letter-spacing: 0;
        }
      }
    }

    .modal-footer {
      border-top: 1px solid #dedede;

      &.text-center {
        justify-content: flex-end; 
      }

      &.offWhite {
        border: none;
        background-color: #f9f9f9;
      }
    }
  }

  &.noRadius {
    .modal-content,
    .modal-footer {
      border-radius: 0;
    }
  }
}

.reviewform {
  .modal-body {
    padding: 50px 80px;
    position: relative;

    .close {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 5;
      width: 20px;
      height: 20px;
      cursor: pointer;

      line {
        stroke: #000;
      }
    }

    h3.title {
      font-family: $primaryFonts;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 31px;
      color: #444444;
    }

    h4.title {
      font-family: $primaryFonts;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 16px;
      color: #363b40;
      margin: 15px 0 30px;
    }

    h5.title {
      font-family: $primaryFonts;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #363b40;
      margin: 10px 0 20px;
    }

    form {
      font-family: $primaryFonts;
      font-style: normal;

      .form-group {
        margin-bottom: 30px;

        label {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-weight: normal;
          font-size: 13px;
          line-height: 20px;
          color: #363b40;
          margin: 20px 0;

          .form-check-input {
            width: 20px;
            height: 20px;
            padding: 0;
            margin: 0;
            position: relative;
          }
        }
      }

      textarea.form-control {
        resize: none;
        background: #ffffff;
        border: 1px solid $portalMainColor;
        box-sizing: border-box;
        border-radius: 3px;
        width: 100%;
        height: 100px;
        padding: 15px 20px;
        margin: 0;
      }

      .btn {
        margin: 30px auto 0;
        background-color: $portalMainColor;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        text-transform: uppercase;
        color: #ffffff;
        padding: 10px 20px;
        box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
        border-radius: 2px;

        &:hover,
        &:focus {
          background-color: #18494b;
        }
      }
    }

    .date {
      font-family: $primaryFonts;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 30px;
      text-align: center;
      color: #363b40;
      margin-bottom: 40px;
    }

    strong.title {
      display: block;
      font-weight: bold;
      font-size: 15px;
      line-height: 16px;
      color: #363b40;
      margin-bottom: 10px;
    }

    .reviewComments {
      background-color: #fff;
      border: 2px solid #e9eff4;
      border-radius: 4px;
      padding: 10px 15px;
    }
  }
}

.reviewform2 {
  .modal-body {
    padding: 50px 80px;
    position: relative;

    .close {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 5;
      width: 20px;
      height: 20px;
      cursor: pointer;

      line {
        stroke: #000;
      }
    }

    h3.title {
      font-family: $primaryFonts;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 31px;
      color: #444444;
    }

    h4.title {
      font-family: $primaryFonts;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 16px;
      color: #363b40;
      margin: 15px 0 30px;
    }

    h5.title {
      font-family: $primaryFonts;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #363b40;
      margin: 10px 0 20px;
    }

    form {
      font-family: $primaryFonts;
      font-style: normal;

      .form-group {
        margin-bottom: 30px;

        label {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-weight: normal;
          font-size: 13px;
          line-height: 20px;
          color: #363b40;
          margin: 20px 0;

          .form-check-input {
            width: 20px;
            height: 20px;
            padding: 0;
            margin: 0;
            position: relative;
          }
        }
      }

      textarea.form-control {
        resize: none;
        background: #ffffff;
        border: 1px solid $portalMainColor;
        box-sizing: border-box;
        border-radius: 3px;
        width: 100%;
        height: 100px;
        padding: 15px 20px;
        margin: 0;
      }

      .btn {
        margin: 30px auto 0;
        background-color: $portalMainColor;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        text-transform: uppercase;
        color: #ffffff;
        padding: 10px 20px;
        box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
        border-radius: 2px;

        &:hover,
        &:focus {
          background-color: #18494b;
        }
      }
    }

    .date {
      font-family: $primaryFonts;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 30px;
      text-align: center;
      color: #363b40;
      margin-bottom: 40px;
    }

    strong.title {
      display: block;
      font-weight: bold;
      font-size: 15px;
      line-height: 16px;
      color: #363b40;
      margin-bottom: 10px;
    }

    .reviewComments {
      background-color: #fff;
      border: 2px solid #e9eff4;
      border-radius: 4px;
      padding: 10px 15px;
    }
  }
}

.leaveReviewSec {
  .ratingReview {
    input[type="checkbox"] {
      margin-right: 10px;
    }

    svg {
      margin-right: 5px;
      vertical-align: text-top;
    }
  }
}

.leaveReviewPage {
  margin-top: 75px;
  padding-top: 60px;

  // background-color: #fff;
  .leave-review {
    background: #ffffff;
    border: 1px solid #d8d8d8;
    border-radius: 3px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    padding: 30px;

    label.card-label {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      text-transform: none;
      font-family: $primaryFonts, sans-serif;
    }

    textarea {
      border: 1px solid #e9eff4 !important;
      border-radius: 4px !important;
      height: 109px !important;
      resize: none;
      padding: 15px;
    }

    .leaveReviewRight {
      .partner-info {
        padding-bottom: 32px;
        border-bottom: 1px solid #444444;
      }

      .cont-rgt {
        h3 {
          font-weight: bold;
          font-size: 26px;
          line-height: 31px;
          color: #444444;
        }

        .cont-rgt-head {
          font-weight: normal;
          font-size: 19px;
          line-height: 26px;
          color: #444444;
          margin-bottom: 13px;
        }

        .reviewLoc h4 {
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #444444;
          text-align: left;
          margin-bottom: 0;
        }
      }

      .leaveReviewAdd {
        padding-top: 35px;
        padding-bottom: 35px;
        display: flex;

        label.card-label {
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          color: #333333;
          text-transform: none;
          padding-right: 20px;
        }

        .reviewStar {
          label.custom-control-label {
            margin-right: 10px;
            padding-left: 8px;
          }
        }
      }

      .textareaSec {
        padding-bottom: 60px;
        border-bottom: 1px solid #444444;
      }

      .reviewBtn {
        padding-top: 56px;

        button {
          min-width: 135px;
          min-height: 40px;
          border: 1px solid #333333;
          box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
          border-radius: 2px;
          font-weight: bold;
          font-size: 10px;
          line-height: 12px;
          text-transform: uppercase;
          color: #333333;
          margin: 0 auto;
        }
      }
    }
  }

  @media only screen and (max-width: 991px) {
    .leave-review {
      padding: 20px;

      .leaveReviewRight {
        padding: 33px 25px;

        .partner-info {
          padding-bottom: 24px;

          .cont-rgt {
            h3 {
              font-size: 20px;
            }

            .cont-rgt-head {
              font-size: 14px;
            }

            .reviewLoc h4 {
              font-size: 9px;
            }
          }
        }

        .leaveReviewAdd {
          padding-top: 26px;
          flex-wrap: wrap;

          label.card-label {
            font-size: 11px;
            padding-right: 0px;
          }
        }

        .reviewBtn {
          padding-top: 25px;

          button {
            min-width: 102px;
            min-height: 30px;
          }
        }
      }
    }
  }
}
// tooltip
.fade.show {
  .tooltip-inner  {
    height: 100% !important;
  }
}