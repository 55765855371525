@import "../../../scss/style.scss";
body.header-fixed {
  .messageSection {
    width: 100%;
    position: relative;
    padding: 20px;
    .btn {
      &.btn-link {
        border: none;
      }
    }

    header {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      border: none;
      padding: 0;
      margin: 0;
      padding: 24px 10px;
      background-color: transparent;
      .title {
        font-family: $primaryFonts;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
        display: flex;
        align-items: center;
        letter-spacing: 0;
        text-transform: capitalize;
        color: #444444;
        margin: 0;
        padding: 0;
        img {
          width: 24px;
          height: 24px;
          border-radius: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: transparent;
          padding: 5px;
          margin-right: 25px !important;
          &:hover,
          &:focus {
            background-color: rgb(237, 237, 237);
          }
        }
        .badge {
          padding: 5px 10px;
          margin: auto;
          margin-left: 20px;
          font-family: $primaryFonts;
          font-style: normal;
          font-weight: normal;
          font-size: 11px;
          line-height: 12px;
          letter-spacing: 0;
          text-align: center;
          color: $text-dark;
          border-radius: 8px;
          &.badge-primary {
            background-color: #dbecff;
          }
        }
      }
    }
    .mailBox {
      margin: 0;
      .mailHeader {
        padding: 20px 0;
        border-bottom: 1px solid rgba(68, 68, 68, 0.1);
        cursor: pointer;
        transition: all 0.5s ease-in-out;
        display: block;
        // align-items: center;
        // justify-content: space-between;
        &.hide {
          display: none;
        }
        &.show {
          display: block;
        }
        .topPart {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .leftSide {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-family: $primaryFonts;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0;
            color: $text-dark;

            .avatar {
              width: 40px;
              height: 40px;
              background-image: none !important;
              background-color: rgba($color: $grey-rgb-color, $alpha: 0.1);
              border-radius: 40px;
              overflow: hidden;
              padding: 0;
              margin: 0 10px 0 0;
              img {
                object-fit: cover;
              }
            }
          }
          .rightSide {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .dateTime {
              font-family: $primaryFonts;
              font-style: normal;
              font-weight: 500;
              font-size: 10px;
              line-height: 24px;
              text-align: right;
              letter-spacing: 0;
              color: $text-dark;
              opacity: 0.5;
            }
            .StarredIcon {
              cursor: pointer;
              margin: auto 25px auto 15px;
              width: 17px;
              height: 17px;
            }
            .AttachmentIcon {
              cursor: pointer;
              margin: auto 15px;
              width: 17px;
              height: 17px;
            }
            button.ThreeDotIcon.btn.btn-link {
              width: 46px;
              height: 46px;
              background-color: transparent;
              border-radius: 50px !important;
              border: 1px solid transparent;
              display: flex;
              align-items: center;
              justify-content: center;
              &:after {
                display: none;
              }
              &:hover,
              &:focus {
                border: 1px solid #ddd;
                background-color: #f8f8f8;
              }
              img {
                transform: rotate(90deg);
              }
            }
            .rightSideicon {
              display: flex;
            }
            .currentHireSec {
              margin-right: 22px;
              h5,
              p {
                font-weight: 600;
                font-size: 12px;
                letter-spacing: 0;
                color: #4f4f4f;
                line-height: 30px;
              }
              p {
                font-weight: 400;
                margin-left: 5px;
              }
              a {
                font-weight: 500;
                font-size: 12px;
                line-height: 14px;
                img.uni-icon {
                  vertical-align: middle;
                  margin-right: 5px;
                  margin-top: -2px;
                }
              }
            }
            button.shareProfileBtn {
              font-weight: 400;
              font-size: 13px;
              line-height: 16px;
              color: #4da1ff;
              letter-spacing: 0;
              background: transparent;
              border: 1px solid transparent;
              border-radius: 3px !important;
              min-width: 113px;
              padding: 9px 5px;
              margin-right: 11px;
              text-align: center;
              justify-content: center;
              transition: all 0.5s ease;
              &:hover,
              &:focus {
                background: rgba(219, 236, 255, 0.7);
                border: 1px solid rgba(219, 236, 255, 0.7);
              }
            }
            button.hireNowBtn {
              font-weight: 400;
              font-size: 13px;
              line-height: 16px;
              color: #333333;
              border-radius: 1px;
              min-width: 113px;
              letter-spacing: 0;
              padding: 9px 5px;
              background: #eaeaea;
              border: 1px solid #eaeaea;
              border-radius: 3px !important;
              margin-right: 20px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              &:hover,
              &:focus {
                background: transparent;
              }
            }
            button#shared1 {
              margin-right: 11px;
              background-image: none;
              background-repeat: no-repeat;
              background-position: center right 8px;
              background-color: rgba(219, 236, 255, 0.7);
              border: 1px solid rgba(219, 236, 255, 0.7);
              border-radius: 3px !important;
              font-weight: 400;
              font-size: 13px;
              line-height: 16px;
              letter-spacing: 0;
              color: #4da1ff;
              min-width: 113px;
              padding: 9px 15px;
              margin-right: 11px;
              text-align: center;
              justify-content: center;
              transition: all 0.5s ease;
              svg {
                width: 24px;
                height: auto;
                path {
                  stroke: #fff;
                }
                circle {
                  fill: #4da1ff;
                  stroke: #4da1ff;
                }
              }
              &:hover,
              &:focus {
                background-color: rgba(219, 236, 255, 0.7);
                border: 1px solid rgba(219, 236, 255, 0.7);
              }
            }
          }
        }
        .content {
          width: 70%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 6px 0 0 50px;
          font-family: $primaryFonts;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 22px;
          color: $text-dark;
          p {
            display: inline;
            padding-right: 3px;
          }
          br {
            display: none;
          }
        }
      }
      .mailBody {
        padding: 0;
        &.hide {
          display: none;
        }
        &.show {
          display: block;
        }
        .mailHeader {
          border: none;
          .MailSummary {
            padding: 5px 0 0;
            text-transform: none;
            font-family: $primaryFonts;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: -0.056px;
            color: $text-dark;
          }
          .content {
            font-family: $primaryFonts;
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 24px;
            text-align: left;
            letter-spacing: 0;
            color: $text-dark;
            opacity: 0.5;
            margin: 0 0 0 50px;
          }
        }
        .mailContent {
          width: 85%;
          padding: 00px 20px 20px 50px;
          font-family: $primaryFonts;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 22px;
          text-transform: none;
          color: $text-dark;
        }
      }
    }
    .attachement {
      padding: 25px 50px;
      width: 85%;
      .detail {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 13px;
        color: #444444;
        .HasAttachmentIcon {
          display: inline-block;
          width: 18px;
          height: 21px;
          margin-right: 10px;
          path {
            stroke: #333;
          }
        }
      }
      .fileList {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;
        margin: 10px 0;
        .box {
          width: 240px;
          border: 3px solid #edf6ff;
          border-radius: 8px;
          background-color: $white;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          text-decoration: none;
          color: $text-dark;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 15px;
          margin: 10px 20px 0 0;
          cursor: pointer;
          .fileName {
            padding: 0;
            .fileSize {
              text-decoration: none;
              opacity: 1;
              margin: 10px 0 0;
            }
            .DownloadAttachemnet {
              width: 16px;
              height: 20px;
              opacity: 1;
              right: 0;
            }
          }
          &:hover,
          &:focus {
            background-color: #f6f6f6;
            border-color: $primary-color;
          }
        }
      }
    }

    footer {
      border-top: 1px solid rgba(213, 217, 217, 0.4);
      padding: 38px 70px 70px;
      margin: 0 -20px;
      .btn {
        background: #ffffff;
        border: 1px solid #bdbdbd;
        border-radius: 3px !important;
        margin: 0;
        padding: 14px 35px 14px 11px;
        font-family: $primaryFonts;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        text-align: left;
        letter-spacing: 0;
        color: #333333;
        width: 140px;
        svg {
          margin-right: 15px;
          path {
            fill: #333333;
          }
        }
        &:hover,
        &:focus {
          background-color: $primary-color;
          border-color: $primary-color;
          color: $white;
          svg {
            path {
              fill: #fff;
            }
          }
        }
      }
    }
  }

  @media print {
    .foe-inbox-container {
      top: 0;
    }
    .container {
      max-width: 100% !important;
      width: 100% !important;
      .viewMessageSection {
        flex: 0 0 100% !important;
        max-width: 100% !important;
        width: 100% !important;
        height: 100vh !important;
      }
    }
    .LeftInboxMenu,
    #live-chat-widget,
    .print {
      display: none;
    }
  }
}

// .foe-inbox-container {
//   &.viewMessageInboxPage {
//     top: 110px;
//   }
// }
.disputeModal {
  max-width: 541px;
  .modal-saveSearch {
    width: auto;
    height: auto;
    padding: 56px;
    margin: 0;
    background-color: #fff;
    .modal_header {
      position: absolute;
      top: 20px;
      right: 20px;
    }
    .saveSearchFont-header {
      justify-content: flex-start;
      h4 {
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0;
        color: #000000;
      }
    }
    label.card-label {
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 22px;
      color: #333333;
      text-transform: none;
      font-family: $primaryFonts;
    }
    select {
      border: 1px solid #e9eff4 !important;
      border-radius: 4px;
      height: 45px;
      font-weight: 500;
      font-size: 10px;
      line-height: 14px;
      letter-spacing: 0;
      color: #323c47;
      padding: 0 50px 0 20px;
      background-image: url(../../../assets/img/picker.png);
      background-repeat: no-repeat;
      background-position: right;
    }
    textarea {
      height: 132px;
      resize: none;
      border: 1px solid #e9eff4;
      border-radius: 4px;
      padding: 20px 20px;
      font-weight: 500;
      font-size: 10px;
      line-height: 14px;
      letter-spacing: 0;
      color: #323c47;
    }
    button.score-save {
      min-width: 120px;
    }
  }
}
.successModal {
  .successMsg {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 38px;
    text-align: center;
    color: #333333;
    margin-bottom: 0;
  }
  .modal_header {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .modal-body {
    padding: 80px 56px;
    width: auto;
    height: auto;
    margin: 0;
  }
}
.shareProfileModal {
  .saveSearchFont-header {
    justify-content: flex-start;
    h4 {
      font-weight: bold;
      font-size: 20px;
      line-height: 35px;
      color: #333333;
    }
  }
  .modal_header {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .modal-content {
    background-color: #fff;
  }
  .modal-body {
    padding: 0;
    .sucess-text-saveSearch {
      margin-top: 0;
      h5 {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #333333;
      }
    }
    .customCheckbox {
      margin-top: 35px;
      padding: 0;
      label.card-label {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0;
        color: #444444;
        text-transform: capitalize;
        margin-bottom: 24px;
        font-family: $primaryFonts;
      }
      li {
        position: relative;
        padding-left: 25px;
        input.form-check-input {
          margin: 0 !important;
          left: 0;
          top: 2px;
        }
      }
    }
    .modalBody {
      padding: 40px 65px 25px;
      border-bottom: 1px solid rgba(216, 216, 216, 0.5);
    }
    .modalActionBtn {
      padding: 22px 0;
      button.score-save {
        margin: 0 10px 0;
        min-width: 126px;
      }
    }
  }
}

.confirmModal {
  .modal_header {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .modal-body {
    padding: 0;
    background: #fff;
    width: 100%;
    height: auto;
    .saveSearchFont-header {
      max-width: 300px;
      margin: 0 auto;
      padding: 62px 0;
      h4 {
        font-weight: 600;
        font-size: 20px;
        line-height: 35px;
        text-align: center;
        color: #333333;
      }
    }
    .filter-bottom {
      padding: 22px 0;
      border-top: 1px solid rgba(216, 216, 216, 0.5);
      button {
        margin: 0 10px 0;
        min-width: 126px;
      }
    }
  }
}

.terminateModal {
  &.modal-dialog {
    max-width: 785px;
  }
  .satisf_radio .radio-margin {
    padding-left: 22px;
    position: relative;
    span {
      font-weight: normal;
      font-size: 12px;
      letter-spacing: 0;
      text-transform: capitalize;
      color: #323c47;
      font-family: $primaryFonts;
    }
    .form-check-input {
      left: 0;
      margin: 0 !important;
      top: 6px;
    }
    .filter-bottom button.score-save {
      margin-top: 40px !important;
    }
  }
  // .modal-body {
  //   padding: 50px 58px;
  // }
  // .modal-content {
  //   background-color: #fff;
  // }
  // .saveSearchFont-header {
  //   justify-content: flex-start;
  //   h4 {
  //     font-weight: bold;
  //     font-size: 20px;
  //     line-height: 30px;
  //     letter-spacing: 0
  //     color: #000000;
  //   }
  // }
}

.emailExtraOptions {
  .popover {
    background: #ffffff;
    box-shadow: 1px -1px 12px rgba(144, 168, 190, 0.33);
    border: none;
    padding: 0;
    .popover-body {
      padding: 0;
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          border-bottom: 1px solid #f2f2f2;
          padding: 0px 17px;
          font-family: $primaryFonts;
          font-style: normal;
          font-weight: normal;
          font-size: 11px;
          line-height: 35px;
          letter-spacing: 0;
          color: $black;
          cursor: pointer;
          &:hover,
          &:focus {
            background-color: rgba($color: $grey-rgb-color, $alpha: 0.06);
          }
        }
      }
    }
    &.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0);
    }
  }
}

.MailSummaryData {
  .popover {
    max-width: initial;
    .popover-body {
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          padding: 5px 10px;
          span {
            display: inline-block;
            width: 60px;
            text-align: right;
            opacity: 0.8;
            margin-right: 5px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  body.header-fixed {
    .viewMessageSection {
      > .row {
        margin: 0;
        > .col-12 {
          padding: 0;
        }
      }
    }
    .messageSection {
      &.createMessage {
        .mailBox {
          margin: 0px 0 0 10px !important;
          max-width: none !important;
          width: auto !important;
        }
      }
      padding: 0px;
      header {
        margin-top: 0;
        padding-top: 0;
        flex-wrap: wrap;
        .title {
          font-size: 17px;
          line-height: 20px;
          margin-bottom: 10px;
          img {
            margin-right: 10px !important;
          }
        }
      }
      .mailBox {
        .mailHeader {
          padding: 10px 0;
          .topPart {
            flex-wrap: wrap;
            .rightSide {
              width: 100% !important;
              justify-content: flex-end !important;
              flex-wrap: wrap;
              span {
                margin-right: 15px;
              }
              .currentHireSec {
                margin-right: 16px;
                h5,
                p {
                  font-size: 10px;
                  line-height: normal;
                }
                a {
                  font-size: 10px;
                  img.uni-icon {
                    width: 11px;
                    margin-right: 2px;
                  }
                }
              }
              .StarredIcon {
                margin: auto 16px auto 10px;
              }
              button.hireNowBtn,
              button.shareProfileBtn {
                min-width: 76px;
                padding: 8px 5px;
                margin-right: 16px;
                font-size: 9px;
                line-height: normal;
              }
            }
            .leftSide {
              font-size: 11px;
              div strong {
                margin-right: 5px !important;
              }
              .MailSummary {
                font-size: 11px;
                line-height: 10px;
                padding-top: 0;
              }
            }
          }
        }
        .content .createBox .fileList .box {
          width: 100%;
          margin: 10px 0 0 0;
        }
      }
      footer {
        padding: 22px 20px !important;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 10px;
        .btn {
          min-width: 80px;
          margin: 0 0;
          font-size: 11px;
          padding: 10px 12px 10px 8px;
          width: 46%;
          svg {
            margin-right: 5px;
          }
        }
      }
      .replyBox {
        padding: 20px !important;
        .replySection header {
          flex-wrap: wrap;
          justify-content: flex-start !important;
          .rightIcons {
            margin-top: 10px;
            justify-content: flex-end !important;
            width: 100% !important;
            span {
              margin-right: 15px;
            }
          }
        }
        .editorSection .fileList .box {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }
  .shareProfileModal.modal-dialog {
    margin: 0 auto;
    max-width: 90%;
    .modal-body .modalBody {
      padding: 26px 25px 15px;
      .saveSearchFont-header h4 {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 15px;
      }
      .sucess-text-saveSearch h5 {
        font-size: 14px;
      }
      .modalActionBtn button.score-save {
        min-width: 108px;
      }
    }
  }
  .confirmModal.modal-dialog {
    max-width: 90%;
    margin: 0 auto;
    .modal-body {
      .saveSearchFont-header h4 {
        font-size: 18px;
        line-height: 28px;
      }
      .filter-bottom button {
        min-width: 108px;
      }
    }
  }
  .disputeModal {
    max-width: 90%;
    margin: 0 auto;
    .modal-saveSearch {
      padding: 18px 25px;
      .saveSearchFont-header h4 {
        font-size: 18px;
        line-height: 28px;
        text-align: left;
      }
      button.score-save {
        min-width: 108px;
      }
      label.card-label {
        text-align: left;
      }
    }
  }
  .modal-dialog.terminateModal {
    max-width: 90%;
    margin: 0 auto;
    .satisf_radio > .d-flex {
      flex-wrap: wrap;
      justify-content: space-between;
      .radio-margin {
        margin-right: 23px;
      }
    }
  }
  .successModal.modal-dialog {
    max-width: 90%;
    margin: 0 auto;
    .modal-body {
      padding: 50px 25px;
      .successMsg {
        font-size: 18px;
        line-height: 28px;
      }
    }
  }
  // .foe-inbox-container.viewMessageInboxPage {
  //   top: 58px;
  // }
  .foe-inbox-container .LeftInboxMenu .inboxMenuList li a:hover,
  .foe-inbox-container .LeftInboxMenu .inboxMenuList li a:focus {
    border: 0;
  }
}

#mailContent {
  width: 85%;
  padding: 00px 20px 20px 50px;
  font-family: $primaryFonts;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 22px;
  text-transform: none;
  color: $text-dark;
  margin: 0;
  p {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
  }
  @media only screen and (max-width: 991px) {
    width: 100%;
    padding: 0px 0px 20px 50px;
  }
}

// new update for reason for termination contract popup
.primaryModal.terminate-form {
.termi-contr {
  padding: 20px 25px !important;
  margin: 0px !important;
  .saveSearchFont-header {
   h4 {
    color: #54595E;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
 }
}
.select-wrapper4 {
  .card-label {
  font-size: 12px;
  font-weight: 400;
 }
 .select-sort2 {
  background-color: #ffffff;
  background-image: url(../../../assets/img/select_pick.png) !important;
  background-repeat: no-repeat;
  background-position: right -1px center !important;
  background-size: 38px 43px !important;
  border: 1px solid #d8d8d8 !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
}
#review.form-control {
  min-height: 80px !important;
  border-radius: 8px;
  border: 1px solid #D1D1D1 !important;
  background: var(--white, #FFF);
  color: #ACACAC;
  font-size: 14px !important;
  font-weight: 300;
}
.satisf_radio {
  .d-flex {
    align-items: center;
    justify-content: space-between;
}
  .mt-3.radio-margin {margin-right: 0px !important;}
  .form-check-input {
    margin-right: 5px !important;
  }
}
.btn.btn-primary {
  border-radius: 2.93px;
  background: var(--dark-medium, #4F4F4F);
  box-shadow: 0px 0px 2.930000066757202px 0px rgba(0, 0, 0, 0.25);
  min-width: 150px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-left: 0px !important;
  }
 }
}