@import "../../../scss/style.scss";

span.commentBtn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 0rem);
  z-index: 5;
  .commentCount {
    position: absolute;
    top: -15px;
    left: 80px;
    .commentCountIcon{
      width: 45px;
      height: auto;
      path{
        fill: $primary-color;
      }      
    }
    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-weight: 500;
      font-size: 17px;
      line-height: 30px;
      letter-spacing: 0;
      width: 30px;
      margin: -3px 0;
      text-align: center;
    }
    &.read{
      svg{path{fill: #D7D7D7;}}
      span{color: #6a6a6a;}
    }
  }
}

.applicationComment {
  .popover {
    max-width: 380px;
    border: 0;
    margin: -20px 0 0 80px !important;
    span.arrow {
      display: none;
    }
  }
  .msgBox ul li {
    margin: 0 0 24px;
    &:last-child {
      margin: 0;
    }
  }
  .commentSection {
    position: static;
    top: auto;
    right: auto;
    box-shadow: 1px 1px 12px rgba(144, 168, 190, 0.4);
    border-radius: 5px;
    border: 1px solid #DADADA;
    .commentCount {
      position: absolute;
      top: -50px;
      left: 15px;
      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        font-weight: 500;
        font-size: 17px;
        line-height: 30px;
        letter-spacing: 0;
      }
    }
  }
  .commentTextarea {
    border-top: 1px solid #E6E6E6;
    padding: 20px;
    textarea {
      resize: none;
      border: 0;
      padding: 0;
    }
  }
  .commentFooter {
    padding: 8px 0 0;
    .buttons {
      width: 100%;
      justify-content: flex-start;
      .btn {
        width: auto;
        min-width: 81px;
      }
    }
  }
  .resolvedIcon button.actionBtn {
    background-color: transparent;
    padding: 0;
    border: 0;
  }
}
