@import "../scss/style.scss";

.sidebarMenuBtn {
  width: 55px;
  height: 50px;
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 82px;
  left: 0;
  z-index: 10;
  background: $portalMainColor;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 0px 5px 5px 0px;
  cursor: pointer;
  svg {
    width: 30px;
    height: 30px;
    path {
      fill: rgba(255, 255, 255, 0.8);
    }
  }
  @media (max-width: 991px) {
    display: flex;
  }
}

.sidebarFixed {
  background-color: $portalMainColor;
  position: fixed;
  left: 0;
  z-index: 1010;
  width: 280px;
  height: 100vh;
  top: 0;
  padding: 149px 0 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  // overflow-y: auto;
  box-shadow: none;

  .innerSidebarWrap {
    width: 250px;
    position: relative;
    .title {
      font-family: $primaryFonts;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #ffffff;
      padding: 0 15px;
      margin: 15px 0 15px;
    }
    .closeMenu {
      display: none;
      position: absolute;
      right: 0;
      z-index: 1100;
      margin: 15px 0 15px;
      cursor: pointer;
      svg {
        width: 12px;
        height: 12px;
        path {
          fill: #ffffff;
        }
      }
    }
    .uni-left-card {
      background-color: transparent;
      border: none;
      min-height: unset;
      .card-body {
        padding: 0;
        // ul.left-list.list-group{
        //   max-height: 74vh;
        //   overflow-y: auto;
        // }
        li.list-group-item {
          padding: 10px !important;
          margin: 0 0 10px !important;
          font-family: $primaryFonts;
          font-style: normal;
          font-weight: 400;
          font-size: 14px !important;
          line-height: 18px !important;
          color: #ffffff;
          position: relative;
          border-radius: 18px;
          width: 100%;
          .optionIcon {
            position: absolute;
            right: 15px;
            top: 15px;
            path {
              fill: #ffffff;
            }
          }
          span.iconBox {
            display: inline-flex;
            justify-content: center;
            width: 16px;
            margin-right: 6px;
            opacity: 0.8;
            .icon {
              width: 14px;
              height: auto;
              max-width: 14px;
              max-height: 14px;
              path {
                fill: #ffffff;
                stroke: transparent;
              }
              &.rect {
                rect {
                  fill: #ffffff;
                }
              }
              &.stroke {
                path {
                  fill: transparent;
                  stroke: #ffffff;
                }
              }
            }
          }
          a {
            color: #ffffff;
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 13px !important;
            line-height: 18px !important;
            white-space: noWrap;
            .linkCount {
              font-weight: 700;
              font-size: 12px;
              line-height: 12px;
              color: #ffffff;
              background: #023335;
              width: 21px;
              height: 21px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: auto;
            }

            &.libraryList{
              display: block;
              text-align: left;
              font-size: 13px;
              strong{
                width: 90%;
                display: block;
                white-space: normal;
                word-break:normal;
                font-size: 14px;
              }
            }
          }

          &.compose {
            padding: 0 !important;
            border-radius: 12px;
            background-color: transparent !important;
            &:hover,
            &:focus,
            &:active,
            &.active {
              background-color: transparent !important;
            }

            a {
              background: #ffffff;
              box-shadow: 0px 2px 4px rgba(18, 18, 23, 0.5);
              border-radius: 12px;
              padding: 10px 15px !important;
              font-weight: 700;
              font-size: 11px !important;
              line-height: 15px !important;
              display: flex;
              align-items: center;
              letter-spacing: 0.5px;
              text-transform: uppercase;
              color: $epGreen;
              width: 120px;
              flex: 0 0 120px;
              span.iconBox {
                .icon {
                  path {
                    fill: $epGreen;
                  }
                }
              }
              &:hover {
                background: #ffffff;
                box-shadow: 0px 2px 5px #121217;
              }
              &:focus,
              &:active {
                background-color: #eaf0f0 !important;
                box-shadow: 0px 2px 5px #121217 !important;
              }
            }
          }

          .submenu {
            width: 100%;
            flex: 0 0 100%;
            margin-top: 15px;
            li {
              padding: 0 0 0 24px !important;
              background-color: transparent !important;
              margin: 10px 0 0;
              a {
                font-size: 13px !important;
                font-weight: 400;
                line-height: 14px !important;
                padding: 5px;
              }
              &.active {
                a {
                  font-weight: 700;
                  text-decoration: none;
                }
              }
            }
          }
          &:hover,
          &:focus,
          &.active {
            background-color: rgba(255, 255, 255, 0.09) !important;
            a,
            div {
              font-weight: 700;
              text-decoration: none;
            }
            span.iconBox {
              opacity: 1;
            }
            .optionIcon {
              transform: rotate(90deg);
            }
          }
          &.seprator {
            cursor: default;
            hr {
              width: 100%;
              height: 3px;
              margin: 0;
              padding: 0;
              background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0),
                rgba(255, 255, 255, 0.28) 50%,
                rgba(255, 255, 255, 0)
              );
              border: none;
            }
            &:hover,
            &:focus,
            &.active {
              background-color: transparent !important;
            }
          }
        }

        .campusDropdown {
          width: 100%;
          background-color: rgba(255, 255, 255, 0.05);
          border-radius: 3px;
          margin-bottom: 30px;
          .btn {
            &.dropdown-toggle {
              font-family: $primaryFonts;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 19px;
              letter-spacing: 0.05em;
              color: #ffffff;
              background-color: transparent;
              border: none;
              padding: 10px 15px;
              margin: 0;
              display: block;
              text-align: left;
              white-space: normal;
              &:after {
                position: absolute;
                top: 50%;
                right: 15px;
                transform: translateY(-50%);
              }
              span {
                font-weight: 600;
                font-size: 9px;
                line-height: 12px;
                letter-spacing: 0.05em;
                text-transform: uppercase;
                opacity: 0.5;
                display: block;
                margin-bottom: 5px;
              }
            }
          }

          .dropdown-menu {
            width: 100%;
          }
        }
      }
    }
  }

  &.inbox {
    width: 235px;
    .innerSidebarWrap {
      width: 190px;
      margin: auto;
    }
  }
  @media (max-width: 1599px) {
    position: fixed;
    width: 280px;
  }
  @media (max-width: 991px) {
    width: 280px;
    left: -280px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.5s ease-in-out;
    padding: 80px 0 0;
    &.open {
      left: 0;
      &::before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.25);
      }
    }
    .innerSidebarWrap {
      .closeMenu {
        display: block;
      }
    }
  }
}

.campusNameAfter {
  &.dropdown-toggle {
    &:after {
      display: none !important;
    }
  }
}
