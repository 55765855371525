@import "../../scss/scss2/style.scss";

.notification {
  .popover {
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: none;
    width: 360px;
    max-width: 360px;
    .popover-inner {
      .popover-body {
        padding: 0;
        ul {
          padding: 0;
          margin: 0;
          list-style: none;
          li {
            border-bottom: 1px solid #e5e5e5;
            padding: 10px 0;
            display: flex;
            align-items: center;
            cursor: pointer;

            .avatarIcon {
              width: 32px;
              height: 32px;
              border-radius: 32px;
              background-position: center;
              background-size: 32px 32px;
              background-repeat: no-repeat;
              margin: 0 20px;
              flex-basis: 32px;
            }
            .avatarText {
              width: 32px;
              height: 32px;
              border-radius: 32px;
              margin: 0 20px;
              flex-basis: 32px;
              font-family: $primaryFonts;
              font-style: normal;
              font-weight: normal;
              font-size: 18px;
              line-height: 27px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #ffffff;
            }
            .details {
              font-family: $primaryFonts;
              font-style: normal;
              font-weight: normal;
              font-size: 13px;
              line-height: 15px;
              color: #333333;
              width: calc(100% - 75px);
              p {
                margin: 0 0 4px;
                padding: 0;
                font-size: 13px;
                line-height: 15px;
                color: #333333;
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 95%;
              }
              date {
                font-weight: 600;
                font-size: 11px;
                line-height: 13px;
                color: #999999;
              }
            }
            &.unread {
              .details {
                p {
                  font-weight: 600;
                }
              }
            }
            &:last-child {
              border-bottom: none;
            }
            &:hover,
            &:focus {
              background-color: #f4f9ff;
            }
          }
        }
      }
      .popover-header {
        background-color: #f4f9ff;
        border-radius: 5px;
        border-top: 1px solid #e5e5e5;
        padding: 0;
        overflow: hidden;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        a {
          display: block;
          font-family: $primaryFonts;
          font-style: normal;
          font-weight: 600;
          font-size: 12.4771px;
          line-height: 15px;
          text-align: center;
          letter-spacing: 0.05em;
          text-transform: capitalize;
          color: #4da1ff;
          padding: 14px;
          &:hover,
          &:focus {
            text-decoration: none;
            background-color: #4da1ff;
            color: #fff;
          }
        }
      }
    }
  }
}
.userProfile {
  .popover {
    width: 176px;
    max-width: 176px;

    background: #ffffff;
    // box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.33);
    box-shadow: 0px 0px 10px rgba(189, 189, 189, 0.3);
    border-radius: 6px;
    border: none;
    .popover-body {
      padding: 0;
      ul {
        padding: 0;
        margin: 0;
        li {
          border: none;
          font-family: $primaryFonts;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          text-transform: capitalize;
          color: #323c47;
          position: relative;
          padding: 0;
          margin: 6px;
          a {
            font-weight: 400;
            font-size: 10px;
            line-height: 14px;
            color: #787878;
            display: block;
            position: relative;
            padding: 8px 10px 8px 30px;
            border-radius: 2px;

            svg {
              width: 11px;
              height: 11px;
              position: absolute;
              top: 10px;
              left: 8px;
              path {
                fill: #787878;
              }
            }
            &:hover,
            &:focus,
            &.active {
              background-color: #f0f5f8;
            }
            &#userDetails {
              color: $primary-green-color;
              text-transform: none;
              span {
                display: block;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin: 2px 0;
                padding: 0;
              }
              svg {
                top: 12px;
                width: 16px;
                height: 16px;
                path {
                  fill:  $primary-green-color;
                }
              }
              &.active {
                background-color: transparent;
              }
              &:hover,
              &:focus {
                background-color: rgba(239, 239, 239, 0.6);
              }
            }
          }
        }
      }
    }
    .arrow {
      &:before {
        display: none;
      }
    }
  }
}
.header-fixed .app-header.headerWithLogoUserNoti {
  height: auto;
  padding: 4px 0;
}
header.app-header.header {
  background: #ffffff;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.25);
  min-height: unset;
  height: auto;
  padding: 25px 0;
  border: none !important;
  &::before {
    display: none;
  }
  .foe-nav-right {
    margin: auto 0 !important;
    .prof-header {
      min-width: 1px;
      max-width: 60px;
      min-height: 1px;
      margin-left: 10px;
      span {
        display: block;
        padding-top: 4px;
        font-weight: 500;
        font-size: 10px;
        letter-spacing: 0.05em;
        text-transform: capitalize;
        color: #109cf1 !important;
      }
    }
    .prof-header-flag {
      margin-right: 17px;
      span {
        display: block;
        padding-top: 4px;
        font-weight: 500;
        font-size: 10px;
        letter-spacing: 0.05em;
        text-transform: capitalize;
        color: #3c444c !important;
      }
    }
  }
  .navbar-nav.foe-nav-right {
    margin-top: 10px;
    li {
      font-family: $primaryFonts;
      margin: 0px 13px;
      border: 0;
      color: #444444;
      a.nav-link {
        font-family: $primaryFonts;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        color: #444444;
        display: inline-flex;
        align-items: center;
        &:hover,
        &:focus,
        &.active {
          color: #109cf1;
        }
      }
    }
    li.iconLink {
      border-left: 1px solid rgba(68, 68, 68, 0.5);
      padding-left: 10px;
      span {
        padding: 0 15px;
        cursor: pointer;
      }
      .nav-link.active {
        &.notiLink,
        &.favLink {
          svg path {
            fill: #109cf1;
          }
        }
        &.inboxLink {
          svg path {
            stroke: #109cf1;
          }
        }
      }
    }
  }
  .navbar-brand {
    width: 200px;
  }
}

// .userProfile .bs-popover-bottom > .arrow,
.bs-popover-auto[x-placement^="bottom"] > .arrow:before,
.notification .bs-popover-bottom > .arrow,
.bs-popover-auto[x-placement^="bottom"] > .arrow:before {
  display: none;
}
// header.foe-header-container.app-header.navbar:before {
//   background: #f2f2f2;
// }
// .foe-studen-container {
//   background-color: #fafafa;
// }
#SendMessageHeader {
  padding-top: 85px;
  min-height: 100vh;
  .headerWithLogoUserNoti {
    margin-top: 10px;
    z-index: 0;
  }
  @media (max-width: 767px) {
    padding-top: 45px;
  }
}
.headerWithLogoUserNoti {
  &:before {
    display: none !important;
  }
  .foe-py-2.container {
    padding-top: 0;
    height: 100%;
  }
  // .navbar-brand-full {
  //   max-width: 184px;
  //   min-width: 184px;
  // }
  .navbar-nav.foe-nav-right {
    margin-top: 0;
  }
  .navbar-nav.foe-nav-right li.iconLink {
    border-left: 0;
  }
}
header.header.headerWithLogoUserNoti.app-header {
  min-height: 85px !important;
  background: #f2f2f2;
}
@media only screen and (max-width: 1199px) {
  header.app-header.header {
    // .navbar-brand {
    //   width: 220px;
    //   min-width: 220px !important;
    //   height: 62px;
    //   position: relative;
    //   img {
    //     object-fit: contain;
    //     width: 100%;
    //     height: 100%;
    //     position: absolute;
    //   }
    // }
    .nav-item {
      &.d-lg-none {
        min-width: 28px;
        .navbar-toggler {
          margin-left: 0;
        }
      }
    }
  }
}
@media only screen and (max-width: 991px) {
  header.app-header.header {
    padding: 10px 20px;
    // position: relative;
    .navbar-brand {
      width: 130px;
      min-width: 130px !important;
      height: auto;
    }
    .container {
      width: 100%;
      flex: 0 0 100%;
      padding: 0;
      margin: 0;
      max-width: 100%;
    }
    .foe-nav-right .prof-header {
      display: none;
    }
  }
  .app-body.foe-body {
    margin: 58px 0 0 !important;
  }
}
// @media only screen and (max-width: 520px) {
//   header.app-header.header {
//     .navbar-brand {
//       width: 180px;
//       min-width: 180px !important;
//     }
//   }
// }
