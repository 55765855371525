.react-datepicker-wrapper {
    display: inline !important;
}

.add{
   float: right;
   margin-right: 40px;
}

.addquestion{
  margin: 0 auto;
  display: block;
}

.save{
  margin-top: 30px;
}



