.card {
  &.applicationListing {
    font-family: $primaryFonts;
    font-style: normal;
    width: 100%;
    padding: 0;
    margin: 0 0 30px;
    background: #ffffff;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    border: none;

    .card-body {
      padding: 0;
      margin: 0;

      .table-responsive {
        border: none;
        padding-top: 200px;
        margin-top: -200px;
      }

      .header {
        margin: 0;
        padding: 0;
        background: #eaf0f0;
        border-bottom: 1px solid #d8d8d8;

        .left {
          padding: 20px;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .title {
            font-weight: 600;
            font-size: 18px;
            line-height: 25px;
            text-transform: capitalize;
            color: #4f4f4f;

            small {
              display: block;
              margin-top: 5px;
              font-weight: 400;
              font-size: 14px;
              line-height: 16px;
              text-transform: none;
            }
          }
        }

        .right {
          padding: 20px;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          // button {
          //   border: 1px solid #d8d8d8;
          //   display: flex;
          //   align-items: center;
          //   justify-content: center;
          //   font-weight: 400;
          //   font-size: 12px;
          //   line-height: 16px;
          //   transition: all 0.5s ease-in-out;
          //   svg{
          //     transition: all 0.3s ease-in-out;
          //   }
          //   &:hover, &:focus{
          //     background-color: $portalMainColor;
          //     svg{
          //       path{
          //         fill: #fff;
          //       }
          //     }
          //   }
          // }
          select.form-control {
            background-color: #ffffff;
            background-image: url("../assets/img/select_pick.png") !important;
            background-repeat: no-repeat;
            background-position: right -1px center !important;
            background-size: 38px 43px !important;
            border: 1px solid #d8d8d8 !important;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
          }
        }
      }

      .table {
        width: 100%;
        margin: 0;

        thead {
          th {
            font-weight: 700;
            font-size: 9px;
            line-height: 11px;
            text-align: center;
            letter-spacing: 0;
            text-transform: uppercase;
            color: #333333;
            vertical-align: middle;
            border: 0 !important;
            // border-top: 1px solid #d8d8d8 !important;
            border-bottom: 1px solid #d8d8d8 !important;
            background: #eaf0f0;
            white-space: nowrap;
          }
        }

        tbody {
          td {
            font-weight: 400;
            font-size: 11px;
            line-height: 16px;
            text-align: center;
            vertical-align: middle;
            letter-spacing: 0;
            color: #4f4f4f;
            border: none !important;
            border-bottom: 1px solid #d8d8d8 !important;
            background-color: #ffffff;

            &.status {
              font-weight: 700;
              font-size: 10px;
              line-height: 12px;
              text-transform: capitalize;
            }

            p {
              margin-bottom: 0;
            }

            button {
              margin: auto !important;
              display: flex;
              align-items: center;
              width: 115px;
              height: 33px;
              line-height: 10px;

              img {
                margin-right: 8px;
              }

              &.add-staff-bn.btn-outline-primary {
                margin-bottom: 8px !important;
              }

              &.updateReview {
                color: #4da1ff;
                background-color: #ffffff !important;
                border: 1px solid #4da1ff;
                border-radius: 2px;
              }
            }

            .educationProviderThumb {
              width: 98px;
              height: 42px;
              // border: 1px solid #d8d8d8;
              margin: auto;

              img {
                width: 98px;
                height: 42px;
                object-fit: scale-down;
              }
            }

            .continuepayment {
              margin-top: 20px;
              background: #2ca7f1;
              border-radius: 2px;
              border: none;
              box-shadow: none;
              outline: none;
              font-style: normal;
              font-weight: 500;
              font-size: 11px;
              line-height: 14px;
              display: flex;
              align-items: center;
              text-align: center;
              letter-spacing: 0;
              text-transform: capitalize;
              color: #ffffff;
              min-width: 160px;
              min-height: 45px;
              justify-content: center;
            }

            .viewapplication .btn {
              margin-top: 20px;
              background: #f2994a;
              border-radius: 2px;
              border: none;
              box-shadow: none;
              outline: none;
              font-style: normal;
              font-weight: 500;
              font-size: 11px;
              line-height: 14px;
              display: flex;
              align-items: center;
              text-align: center;
              letter-spacing: 0;
              text-transform: capitalize;
              color: #ffffff;
              min-width: 240px;
              min-height: 55px;
              justify-content: center;
            }

            .viewoffer {
              text-align: center;
              margin: auto;
              display: block;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 2px;
              font-weight: 500;
              width: 115px !important;
              height: 33px;
              font-size: 11px;

              img {
                width: 12px;
                margin-right: 8px;
                display: inline-block;
                vertical-align: middle;
              }

              .btn {
                margin-top: 20px;
                background: $portalMainColor;
                border-radius: 2px;
                border: none;
                box-shadow: none;
                outline: none;
                font-style: normal;
                font-weight: 500;
                font-size: 11px;
                line-height: 14px;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0;
                text-transform: capitalize;
                color: #ffffff;
                min-width: 115px;
                min-height: 33px;
                justify-content: center;
              }
            }

            .payNow {
              font-weight: 600;
              font-size: 10px;
              line-height: 14px;
              background: #eff6ff;
              border: 1px solid #4da1ff;
              border-radius: 2px;
              width: 115px !important;
              min-height: 33px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #4da1ff;
              margin: 0 auto;
            }

            .overDue {
              font-weight: 700;
              font-size: 10px;
              line-height: 12px;
              color: #ff0000;

              span {
                font-weight: 400;
                font-size: 10px;
                line-height: 13px;
                color: #4f4f4f;
                display: block;
                margin-top: 4px;
              }
            }
          }

          tr {
            &:last-child {
              td {
                border: none !important;
              }
            }
          }
        }
      }
    }

    @media (max-width: 767px) {
      .card-body {
        .header {
          .left {
            .title {
              margin-top: 30px;
            }
          }
        }
      }
    }
  }
}

.agentcontracts {
  .btn.btn-secondary {
    width: 50px !important;
  }
}