// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import "~@coreui/coreui/scss/_dropdown-menu-right.scss";

// ie fixes
@import "ie-fix";

// If you want to add something do it here
/*@import "custom";*/

// Helping css for margin, padding, width, etc...
@import "helper";

// @import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&display=swap");
// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");

body.header-fixed,
body {
  background-color: #f2f2f2;
  overflow-x: hidden;
  font-family: $primaryFonts;
}

.bgGray {
  background-color: f2f2f2;
}
.whiteBg {
  background-color: #fff;
}

.borderRight {
  border-right: 1px solid #dddfec;
  min-height: calc(100vh - 180px);
}

h3.title {
  font-family: $primaryFonts;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 31px;
  color: #323c47;
}

.header {
  .topStrip {
    height: 70px;
    width: 100%;
    background-color: $text-dark;
    padding: 0 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .leftSide {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-family: $primaryFonts;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #ffffff;
        .icon {
          width: 14px;
          height: 10px;
          margin-right: 18px;
          path,
          g {
            fill: #ffffff !important;
            stroke: #ffffff !important;
          }
        }
      }
    }
    .rightSide {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .btn {
        font-family: $primaryFonts;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #ffffff;
        padding: 12px 18px;
        margin-left: 15px;
        &.btn-primary {
          color: #ffffff;
          background-color: $primary-color;
          border-radius: 6px;
          &:hover,
          &:focus,
          &:active:focus {
            outline: none;
            box-shadow: none;
            background-color: $primary-dark;
          }
        }
      }
    }
  }
  &.no {
    .topStrip {
      height: 70px;
    }
  }
  &.yes {
    .topStrip {
      height: 42px;
      padding: 0 20px;
      .rightSide {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .btn {
          font-size: 9px;
          line-height: 10px;
          padding: 8px 12px;
          border-radius: 6px;
          transition: all 0.3s ease-in-out;
          &.btn-white {
            background-color: $white;
            color: $primary-color;
            &:hover,
            &:focus {
              background-color: $primary-color;
              color: $white;
            }
          }
          &.btn-primary {
            background-color: $primary-color;
            color: $white;
            &:hover,
            &:focus {
              background-color: $primary-dark;
              color: $white;
            }
          }
          &:hover,
          &:focus,
          &:focus-within,
          &:active:focus,
          &:active {
            outline: none;
            box-shadow: none;
          }
        }
      }
    }
  }
}

// Top Banner
.coverPhoto {
  width: 100%;
  height: 375px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  .preferedPartnerSummary {
    background: #ffffff;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    width: 500px;
    //height: 190px;
    margin-bottom: -60px;
    padding: 35px 35px 28px;

    .ppLogo {
      width: 100%;
      height: auto;
      padding: 10px;
      border: 1px solid #e9eff4;
      border-radius: 4px;
    }
    h3.title {
      font-family: $primaryFonts;
      font-style: normal;
      font-weight: bold;
      font-size: 19px;
      line-height: 22px;
      color: $text-dark;
    }
    .locations {
      font-family: $primaryFonts;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $text-dark;
      margin-top: 14px;
    }
    .favorite {
      cursor: pointer;
      &.active {
        path {
          fill: $text-dark;
        }
      }
    }
    .icons {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 35px;
      svg {
        width: 14px;
        height: 12px;
        margin-right: 8px;
        path {
          fill: $text-dark;
        }
      }
      .details {
        font-family: $primaryFonts;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 15px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: $text-dark;
      }
    }
  }
}

// Menu Css
.anchorMenu {
  width: 100%;
  border-bottom: 1px solid rgba($color: $grey-rgb-color, $alpha: 0.17);
  margin: 120px 0 60px;
  padding: 0;
  .container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: -6px;
    ul {
      margin: 0;
      padding: 0;
      li {
        margin: 0 0;
        padding: 0;
        float: left;
        width: 80px;
        text-align: center;
        a {
          display: inline-block;
          padding: 4px 0;
          margin: 0;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 0.05em;
          text-transform: capitalize;
          color: $text-dark;
          border-bottom: 6px solid transparent;
          &:hover,
          &:focus {
            text-decoration: none;
            font-weight: bold;
            border-bottom: 6px solid $green-dark;
          }
        }
      }
    }
  }
}

.container.viewSection {
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  padding: 0;
  margin-bottom: 140px;
}

.title {
  font-family: $primaryFonts;
  font-style: normal;
  text-transform: uppercase;
  color: $text-dark;
  &.textWhite {
    color: #fff;
  }
}
h2.title {
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 10px;
}
h4.title {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
}
h5.title {
  font-weight: 800;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.05em;
}

// About
.aboutSection {
  background-color: $white;
  padding: 50px 85px 70px;
  scroll-margin: 150px;

  ul.summary {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    li {
      margin-bottom: 5px;
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      width: 100%;
      strong {
        background-color: #f2f2f2;
        border: 1px solid #f2f2f2;
        border-right: 3px solid #ffffff;
        width: 180px;
        padding: 14px;
        display: flex;
        align-items: center;
        font-family: $primaryFonts;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: $text-dark;
      }
      span {
        background-color: #fff;
        border: 1px solid #f2f2f2;
        padding: 12px;
        display: flex;
        align-items: center;
        flex-grow: 1;
        font-family: $primaryFonts;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.1em;
        color: #2d3032;
        width: calc(100% - 150px);
      }
    }
  }
  .content {
    margin: 50px 0 30px;
    font-family: $primaryFonts;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1em;
    color: #2d3032;
    p {
      margin: 0 0 20px;
      padding: 0;
    }
  }
  .servicesOffered {
    background-color: #f2f2f2;
    padding: 27px;
    ul {
      margin: 0;
      padding: 0 0 0 20px;
      li {
        font-family: $primaryFonts;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.1em;
        color: #2d3032;
        list-style: disc;
        padding-bottom: 10px;
      }
    }
  }

  .contact {
    margin: 20px 0 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    li {
      margin-bottom: 20px;
      font-family: $primaryFonts;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 17px;
      text-transform: capitalize;
      &.tel,
      &.email {
        width: 50%;
        svg {
          width: 14px;
          height: 14px;
          margin-right: 10px;
        }
      }
      &.address {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        svg {
          width: 16px;
          height: 16px;
          margin-left: 2px;
          margin-right: 10px;
          margin-top: 4px;
        }
      }
      a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: $text-dark;
        &:hover,
        &:focus {
          color: $primary-color;
          text-decoration: none;
          svg {
            path {
              fill: $primary-color;
            }
          }
        }
      }
    }
  }
}

.ChartDonut {
  width: 100px;
  height: 100px;
  margin: 20px;
  font-family: $primaryFonts;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: $text-dark;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  svg {
    text {
      margin: -20px 0 0;
      tspan {
        font-size: 12px !important;
      }
    }
  }
  &.twoColor {
    svg {
      // width: 160px !important;
      // height: 170px !important;
      // margin: 0 0 0 -10px !important;
      // padding: 0 !important;
      path:first-child {
        // margin: 0 !important;
        // padding: 0 !important;
        stroke: #fff !important;
        stroke-width: 4px !important;
        fill: $portalMainColor !important;
      }
      path:nth-child(2) {
        // margin: 0 !important;
        // padding: 0 !important;
        stroke: #fff !important;
        stroke-width: 6px !important;
        fill: #30363d !important;
      }
      tspan {
        font-size: 12px !important;
      }
    }
  }
}

.barChart {
  svg {
    g[role="presentation"] {
      margin: auto 10px;
    }
  }
}

//portfoilioSection
.portfoilioSection {
  padding: 50px 130px 70px;
  background-color: $portalMainColor;
  color: #fff;
  text-align: center;
  font-family: $primaryFonts;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.1em;
  p {
    margin: 15px 0 0;
  }
}
//certificationsSection
.certificationsSection {
  padding: 50px 130px 50px;
  background-color: #fff;
  color: $text-dark;
  text-align: center;
  font-family: $primaryFonts;
  font-style: normal;
  strong {
    margin: 0 auto 20px;
    display: inline-block;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }
  .certificatList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    a {
      display: block;
      padding: 15px 20px;
      border: 1px solid $portalMainColor;
      border-radius: 50px;
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0.07em;
      text-decoration-line: underline;
      color: $portalMainColor;
      margin: 10px;
      transition: all 0.3s ease-in-out;
      svg {
        width: 18px;
        height: 20px;
        margin-right: 10px;
        svg {
          path {
            fill: $portalMainColor;
            transition: all 0.8s linear;
          }
        }
      }
      &:hover,
      &:focus {
        background-color: $portalMainColor;
        color: $white;
        svg {
          path {
            fill: $white;
            transition: all 0.8s linear;
          }
        }
      }
    }
  }
}

//contactSection
.contactSection {
  background-color: rgba(198, 198, 198, 0.3);
  padding: 50px 85px 70px;
  .subTitle {
    font-family: $primaryFonts;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #333333;
    margin-bottom: 30px;
  }
  .content {
    font-size: 12px;
    line-height: 18px;
    text-transform: capitalize;
    color: $text-dark;
    font-family: $primaryFonts;
    font-style: normal;
    font-weight: normal;
    .row {
      margin-bottom: 0px;
    }
    h3.head {
      font-weight: bold;
      font-size: 19px;
      line-height: 26px;
      color: $text-dark;
      margin-bottom: 20px;
    }
    span {
      &.note {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: rgba(68, 68, 68, 0.5);
      }
    }
    label {
      font-family: $primaryFonts;
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.1em;
      text-transform: uppercase;

      color: #444444;
    }
    label.subTitle {
      font-family: $primaryFonts;
      font-style: normal;
      font-weight: 600;
      font-size: 8px;
      line-height: 9px;
      display: flex;
      align-items: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #444444;
    }
    input,
    select,
    textarea,
    .form-check-input {
      border: 1px solid $primary-color;
      margin: 0 0 10px;
      padding: 0 10px;
      height: 30px;
    }
    textarea {
      height: 100px;
      resize: none;
    }
    label.check {
      padding: 22px 0;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      input.form-check-input#publicholidays {
        margin: 0;
        padding: 0;
        width: 24px;
        height: 24px;
        border-radius: 24px !important;
        position: relative;
      }
      span {
        display: inline-block;
        font-family: $primaryFonts;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        color: $text-dark;
        width: calc(100% - 30px);
      }
    }
    .icon {
      background-repeat: no-repeat;
      background-size: 13px 13px;
      background-position: top left;
      padding: 0 0 0 16px;
      margin: 0 0 10px;
      text-transform: none;
      a {
        display: block;
        color: $text-dark;
        text-transform: none;
        &:hover,
        &:focus {
          color: $green-dark;
        }
      }
    }
    .btn.btn-outline-secondary {
      max-width: 160px;
      width: 90%;
      border: 1px solid #333333;
      border-radius: 2px;
      color: $green-dark;
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      color: #333333;
      box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
      background-color: $white;
      transition: all 0.2s ease-in-out;
      margin-bottom: 20px;
      img {
        display: inline-block;
        width: 18px;
        height: 18px;
        margin-right: 5px;
      }
      &:hover,
      &:focus {
        box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.35);
        transform: translateY(-3px);
      }
    }
  }
}

//staffListSection
.staffListSection {
  padding: 50px 85px 90px;
  background-color: #ffffff;
  color: $text-dark;
  text-align: center;
  font-family: $primaryFonts;
  font-style: normal;
  text-align: center;
  .listing {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    li {
      width: 30%;
      margin: 1% 1.5%;
      padding: 21px 16px 18px;
      background: #ffffff;
      box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .profilePic {
        width: 50px;
        height: 50px;
        border-radius: 50px;
        background-position: center;
        background-size: 50px 50px;
        background-repeat: no-repeat;
        text-indent: -5000px;
        overflow: hidden;
      }
      div {
        width: calc(100% - 50px);
        text-align: left;
        padding-left: 20px;
        strong {
          display: block;
          font-weight: bold;
          font-size: 13px;
          line-height: 21px;
          color: $text-dark;
        }
        em {
          display: block;
          font-weight: 500;
          font-size: 13px;
          line-height: 21px;
          color: $text-dark;
        }
        p {
          display: block;
          font-weight: 500;
          font-size: 11px;
          line-height: 16px;
          color: #828282;
          margin: 5px 0 0;
        }
      }
    }
  }
}

.profileBox {
  display: flex;
  background-color: #f7f7f7 !important;
  padding-bottom: 130px;
  .container {
    &.profileContainer {
      position: relative;
      width: 950px;
      max-width: 950px;
      margin: -10px auto 0;
      padding: 60px 0 0;
      background-color: #f7f7f7 !important;
      &.foe-inbox-container {
        width: calc(100% - 235px);
        max-width: calc(100% - 235px);
        padding: 60px;
        margin: -10px 0 0 0;
        left: 235px;
      }
      &.chatList {
        padding: 0;
      }
    }
  }
  // @media (max-width: 1649px) {
  //   .container {
  //     &.profileContainer {
  //       max-width: 950px;
  //     }
  //   }
  // }
  @media (max-width: 1570px) {
    .container {
      &.profileContainer {
        max-width: calc(100vw - 320px);
        width: calc(100vw - 320px);
        left: 300px;
        margin: 0;
        &.foe-inbox-container {
          width: calc(100% - 235px);
          max-width: calc(100% - 235px);
          left: 235px;
          margin: 0;
        }
      }
    }
  }
  @media (max-width: 991px) {
    display: block;
    padding-bottom: 70px;
    .container {
      &.profileContainer {
        max-width: 90%;
        width: 90%;
        left: auto;
        margin: 0 auto;
        padding: 20px 0 0;
        &.foe-inbox-container {
          max-width: 90%;
          width: 90%;
          left: auto;
          margin: 0 auto;
          padding: 60px 0 0;
        }
      }
    }
  }
}

.modal {
  &.primary {
    .modal-content {
      background: #ffffff;
      box-shadow: 0px 4px 18px 10px rgba(0, 0, 0, 0.08);
      border-radius: 0;
      border: none;

      .modal-header {
        border: none;
        .close {
          font-size: 25px;
          font-weight: 300;
          color: #555;
        }
      }
      .modal-body {
        font-family: $primaryFonts;

        &.addfilepopup {
          max-width: 440px;
          margin: 0 auto;
          padding: 10px 0 !important;
        }
        h2 {
          font-weight: 400;
          font-size: 20px;
          line-height: 27px;
          text-transform: capitalize;
          text-align: center;
          color: #444444;
        }
        .add-file-header {
          padding-left: 15px;
          font-family: $primaryFonts;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.05em;
          border-radius: 4px !important;
          border: 1px solid #bdbdbd !important;
          text-transform: uppercase;
          color: #000000 !important;
          height: 40px !important;
          .afh_icon {
            width: 40px !important;
            text-align: center;
            height: 35px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            border-left: 1px solid #bdbdbd !important;
            border-radius: 0 4px 4px 0 !important;
            position: relative;
            overflow: hidden;
            img {
              width: 43px;
              height: 43px;
              object-fit: cover;
              display: inline-block;
              margin: 0;
              position: absolute;
              top: -3px;
            }
          }
        }

        .select-wrapper {
          select {
            background: url("../../assets/img/select_pick.png") !important;
            background-repeat: no-repeat !important;
            background-size: 43px 43px !important;
            background-position: right -1px center !important;
            border: 1px solid #bdbdbd !important;
            border-radius: 4px !important;
            font-family: $primaryFonts;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.05em;
            color: #000000;
            height: 40px !important;
          }
        }
      }
      .modal-footer {
        border: none;
        padding: 30px 0 60px;
        text-align: center;
        align-items: center;
        &.center {
          justify-content: center;
        }
        .btn {
          margin: 0 10px;
          background-color: #4da1ff;
          border-radius: 2px;
          border: 1px solid #4da1ff;
          padding: 10px 30px;
          font-family: $primaryFonts;
          font-style: normal;
          font-weight: 700;
          font-size: 11px;
          line-height: 15px;
          color: #ffffff;
          transition: all 0.5s ease-in-out;
          &.btn-primary {
            background-color: #4da1ff;
            color: #fff;
            border-color: #4da1ff;
            &:hover,
            &:focus {
              background-color: $portalMainColor;
              border-color: $portalMainColor;
            }
          }
          &.btn-outline-primary {
            background-color: #fff;
            color: #4da1ff;
            border-color: #4da1ff;
            &:hover,
            &:focus {
              border-color: #c00;
              color: #c00;
            }
          }
        }
      }
    }
  }
}

#pagination {
  width: 100%;
  font-family: $primaryFonts;
  position: relative;
  #page-numbers {
    width: 80%;
    margin: 10px auto;
    display: block;
    button {
      border: none;
      background-color: transparent;
      font-weight: 400;
      font-size: 16px !important;
      line-height: 20px !important;
      text-align: center;
      color: #4f4f4f !important;
      &#pagination-back {
        position: absolute;
        left: 0;
        background-image: url(../../assets/img/icons/chevron-left.svg);
        background-repeat: no-repeat;
        background-position: left center;
        padding-left: 20px;
        span {
          display: inline-block;
          padding: 0 5px;
        }
        svg {
          margin-top: -3px;
        }
      }
      &#pagination-next {
        position: absolute;
        right: 0;
        background-image: url(../../assets/img/icons/chevron-right-light-gray.svg);
        background-repeat: no-repeat;
        background-position: right center;
        padding-right: 20px;
        span {
          display: inline-block;
          padding: 0 5px;
        }
        svg {
          margin-top: -3px;
        }
      }
    }
  }
  @media only screen and (max-width: 991px) {
    #page-numbers {
      width: 90%;
      button {
        &#pagination-back,
        &#pagination-next {
          span {
            display: none;
          }
        }
      }
    }
  }
}
