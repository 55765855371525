@import "../../../../scss/style.scss";

.emailContent{
  padding: 20px 0 60px;
  font-family: $primaryFonts;
  font-style: normal;
  color: $text-dark;
  .header{    
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    &.bigFont{
      font-size: 18px;
      line-height: 24px;
    }
  }
  .contentBox{
    width: 100%;
    max-width: 600px;
    padding: 30px 70px;
    margin: 20px 0;
    background-color: #F4F4F4;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    line-height:16px;
    letter-spacing: 0.05em;
    color: #000000;
    a {
      color: #000000;
      text-decoration: underline;
      &.linkOnly{
        font-size: 12px;
        line-height:38px;
        letter-spacing: 0.05em;
      }
    }
    .btn{
      background-color: $portalMainColor;
      color: #FFFFFF;
      box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.06);
      border-radius: 2px;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 17px;
      text-align: center;
      text-decoration: none;
      margin: 10px;
      padding: 15px 40px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 35%;
      height: 50px;
      &.btn-green{
        background-color: $portalMainColor;
        color: #ffffff;
        &:hover, &:focus{
          background-color: #1b4b4d;
        }
      }
      &.btn-outline-green{
        background-color: #fff;
        color: $portalMainColor;
        border: 1px solid $portalMainColor;
        &:hover, &:focus{
          background-color: $portalMainColor;
          color: #fff;
        }
      }
    }

    p {
      &.bigFont{
        display: block;
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        color: #333333;
        margin: 0 0 15px;
      }
      &.smallFonts{
        display: block;
        font-size: 10px;
        line-height: 16px;
        text-align: center;
        color: $text-dark;
        margin: 30px 0 0;
      }
    }
    
    table{
      width: 100%;
      text-align: left;
      th {
        width: 25%;
        font-weight: bold;
        font-size: 10px;
        line-height: 16px;
        color: #979797;
      }
      td {
        width: 25%;
        font-weight: 500;
        font-size: 11px;
        line-height: 22px;
        color: #000000;
        padding: 8px 0 0;
        span{
          text-transform: uppercase;
          font-weight: bold;
          &.terminated{
            color: #FF4F4F;
          }
        }
      }
    }
  }
  strong.reasonText{
    font-weight: bold;
    font-size: 13px;
    line-height: 22px;
    color: #000000;
    margin: 30px 0 5px;
    display: block;
  }
  p.reportText{
    font-weight: 500;
    font-size: 11px;
    line-height: 38px;
    letter-spacing: 0.05em;
    color: #000000;
    svg{
      width: 14px;
      height: auto;
      margin-right: 8px;
    }
    u{
      cursor: pointer;
    }
  }
  .footer{    
    font-weight: normal;
    font-size: 11px;
    line-height: 20px;
    p {
      margin: 0 0 5px;
    }
    a {
      color: $text-dark;
    }
  }
}

.primaryModal {
  &.viewAdded{
    width: 480px;
    //background-color: #F2F2F2;
    .modal-body {
      background-color: #F2F2F2;
      text-align: center;
      padding: 45px;
      &.bgWhite{
        background-color: #fff;
      }
      .form-group{
        text-align: left;
        label{
          font-family: $primaryFonts;
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          line-height: 12px;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          color: #323C47;
          margin: 0;
          padding: 0;
        }
        textarea.form-control{
          height: 130px;
          border: 1px solid #E9EFF4;
          border-radius: 4px;
          resize: none;
          font-family: $primaryFonts;
          font-style: normal;
          font-weight: 500;
          font-size: 11px;
          line-height: 14px;
          letter-spacing: 0.05em;
          color: #323C47;
        }     
      }
      
      .closeIcon {
        position: absolute;
        right: 25px;
        top: 25px;
        z-index: 5;
        width: 20px;
        height: 20px;
        cursor: pointer;
        line {
          stroke:#828282;
        }
      }
      .greenTickIcon {
        width: 30px;
        height: 30px;
        margin-bottom: 10px;
        path {
          fill:$portalMainColor;
        }
      }
      .WarningIcon {
        width: 30px;
        height: 30px;
        margin-bottom: 10px;
        path {
          fill:#c00;
        }
      }
      h2.title{
        font-family: $primaryFonts;
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 38px;
        color: #333333;
        margin-bottom: 30px;
      }
      p{
        font-family: $primaryFonts;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 26px;
        color: #333333;
        padding: 0;
        margin: 0 0 5px;
        a {color: $portalMainColor; text-decoration: underline;}
      }
      .btn{
        background-color: $portalMainColor;
        color: #FFFFFF;
        box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.06);
        border-radius: 2px;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 12px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        margin: 10px;
        padding: 15px 20px;
        &.btn-green{
          background-color: $portalMainColor;
          color: #ffffff;
          &:hover, &:focus{
            background-color: #1b4b4d;
          }
        }
        &.btn-outline-green{
          background-color: #fff;
          color: $portalMainColor;
          border: 1px solid $portalMainColor;
          &:hover, &:focus{
            background-color: $portalMainColor;
            color: #fff;
          }
        }
      }
      .staffName{
        background-color: $white;
        border-radius: 50px;
        width: 250px;
        margin: 40px auto 0;
        padding: 15px;
        font-family: $primaryFonts;
        font-style: normal;
        font-weight: normal;
        font-size: 12.2212px;
        line-height: 15px;
        align-items: center;
        color: #333333;
        svg{
          margin-right: 10px;
          width: 20px;
          height: auto;
        }
      }
    }
  }
  &.hire {
    width: 90%;
    max-width: 650px;
    .modal-body {
      background-color: #F2F2F2;
      text-align: center;
      padding: 45px;
      font-family: $primaryFonts;
      font-style: normal;
      font-weight: normal;
      text-align: center;
      &.bgWhite{
        background-color: #fff;
      }
      .title {
        font-size: 19px;
        line-height: 24px;
        margin-bottom: 15px;
      }
      p{
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.05em;
      }
      .form-group{
        text-align: left;
        label{
          font-family: $primaryFonts;
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          line-height: 12px;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          color: #323C47;
          margin: 0;
          padding: 0;
        }
        input.form-control{
          font-family: $primaryFonts;
          font-style: normal;
          font-weight: 500;
          font-size: 11px;
          line-height: 14px;
          letter-spacing: 0.05em;
          color: #323C47;
        }        
      }
      legend{
        font-family: $primaryFonts;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: #333333;
        text-align: left;
      }
      #selectStaff {
        border: 1px solid #E9EFF4;
        border-radius: 4px;
        width: 360px;
        margin: 10px 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        .icon{
          background: rgba(244, 247, 249, 0.4);
          border-left: 1px solid #E9EFF4;
          // border-radius: 0px 4px 4px 0px;
          padding: 15px 20px;
          svg {            
            width: 10px;
            height: auto;
            path{
              fill: #828282;
            }
          }
        }
        .staffDetail{
          display: flex;
          align-items: center;
          cursor: pointer;
          line-height: 16px;
          padding: 10px;
          width: 300px;
          .profilePic{
            width: 33px;
            height: 33px;
            border-radius: 33px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            margin-right: 10px;
            text-indent: -5000px;
            overflow: hidden;
          }
          .personalDetials {
            strong {
              display: block;
              line-height: 20px;
            }
          }
          .groupName {
            width: 100px;
            text-align: right;
            text-transform: capitalize;
            color: $primary-color;
            margin: auto 0 auto auto;
          }
        }
      }
    }
  }
}


.emailExtraOptions .popover{
  max-width: 500px;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    width: 100%;
    border-bottom: 1px solid #e5e5e5;
    padding: 8px 20px;
    // input.form-check-input {
    // 	//display: none;
    // }
  }
  &.staff {
    li {
      line-height: 16px;
      .form-check-label {
        display: flex;
        align-items: center;
        clear: both;
        cursor: pointer;
        line-height: 16px;
        padding: 10px 0;
        &::before {
          content: "";
          display: block;
          width: 20px;
          height: 20px;
          border-radius: 20px;
          margin-right: 8px;
          background: rgba(
              $color: $grey-rgb-color,
              $alpha: 0.17
            )
            url("../../../../assets/img/university/tick_white_icon.svg")
            no-repeat center center / 11px 11px;
        }
        &.selected {
          &::before {
            background: $primary-color
              url("../../../../assets/img/university/tick_white_icon.svg")
              no-repeat center center / 11px 11px;
          }
        }
        input {
          display: none;
        }
        .profilePic{
          width: 33px;
          height: 33px;
          border-radius: 33px;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
          margin-right: 10px;
          text-indent: -5000px;
          overflow: hidden;
        }
        .personalDetials {
          strong {
            display: block;
            line-height: 20px;
          }
        }
        .groupName {
          width: 100px;
          text-align: right;
          text-transform: capitalize;
          color: $primary-color;
          margin: auto 0 auto auto;
        }
      }
    }
  }
}