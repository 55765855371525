/* @font-face {
  font-family: "raleway";
  src: url("../src/assets/fonts/raleway-regular-webfont.woff2") format("woff2"),
    url("../src/assets/fonts/raleway-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ralewaybold";
  src: url("../src/assets/fonts/raleway-bold-webfont.woff2") format("woff2"),
    url("../src/assets/fonts/raleway-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ralewaysemibold";
  src: url("../src/assets/fonts/Raleway-SemiBold.woff2") format("woff2"),
    url("../src/assets/fonts/Raleway-SemiBold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ralewayextrabold";
  src: url("../src/assets/fonts/raleway-extrabold-webfont.woff2")
      format("woff2"),
    url("../src/assets/fonts/raleway-extrabold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: "Open Sans";
  src: url("../src/assets/fonts/montserrat-bold-webfont.woff2") format("woff2"),
    url("../src/assets/fonts/montserrat-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
/*
@font-face {
  font-family: "robotoblack";
  src: url("../src/assets/fonts/roboto-black-webfont.woff2") format("woff2"),
    url("../src/assets/fonts/roboto-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotobold";
  src: url("../src/assets/fonts/roboto-bold-webfont.woff2") format("woff2"),
    url("../src/assets/fonts/roboto-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotolight";
  src: url("../src/assets/fonts/roboto-light-webfont.woff2") format("woff2"),
    url("../src/assets/fonts/roboto-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../src/assets/fonts/roboto-regular-webfont.woff2") format("woff2"),
    url("../src/assets/fonts/roboto-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: "quicksand";
  src: url("../src/assets/fonts/quicksand-variablefont_wght-webfont.woff2")
      format("woff2"),
    url("../src/assets/fonts/quicksand-variablefont_wght-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("../src/assets/fonts/lato-regular-webfont.woff2") format("woff2"),
    url("../src/assets/fonts/lato-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");

body {
  font-family: "Open Sans" !important;
}

ul,
li {
  list-style: none;
}

/******v css start*******/

.schatnd h3#demo {
  margin-top: 40px;
}

.form-group {
  margin-bottom: 10px !important;
}

.pi_wrap .w-20 {
  flex: 0 0 20%;
  max-width: 20%;
}

.pi_wrap .w-26 {
  flex: 0 0 26.66%;
  max-width: 26.66%;
}

h3.cntct_detail {
  margin-bottom: 25px;
}

.personal-box-inner.address.score-grt .pr-header {
  margin-top: 0;
}

.personal-box-inner.address.score-grt {
  margin-top: 0 !important;
}

h3.add_qua {
  margin-bottom: 20px;
}

.upldoctable_width {
  margin-top: 20px;
  width: 100%;
}
/*
.upldoctable table tr th {
  font-family: "Open Sans";
  font-size: 10px;
  font-style: italic;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0;
  padding-top: 16px;
  padding-bottom: 16px;
  text-transform: uppercase;
  color: #444;
}

.upldoctable2 table tr th {
  font-family: "Open Sans";
  font-size: 10px;
  font-style: italic;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0;
  padding-top: 0px;
  padding-bottom: 0px;
  text-transform: uppercase;
  color: #444;
  vertical-align: middle;
}

.upldoctable table tr th,
.upldoctable table tr td {
  border: 1px solid #ededed;
  border-right-color: transparent;
  position: relative;
  overflow: hidden;
  /*vertical-align: center;*/
/* }

.upldoctable table tr th:last-child,
.upldoctable table tr td:last-child {
  border-right-color: #ededed;
}

.upldoctable table tr td:after {
  content: "";
  background-image: url(../src/assets/img/Line-table.png);
  width: 1px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

.upldoctable table tr td:last-child:after {
  opacity: 0;
}

.upldoctable table tr th:after {
  content: "";
  background-image: url(../src/assets/img/Line-table.png);
  width: 1px;
  height: 69px;
  position: absolute;
  right: 0;
  top: -10px;
}

.upldoctable table tr th:last-child:after {
  opacity: 0;
}

.upldoctable table tr th .select-wrapper select {
  padding: 0;
  height: auto;
  font-family: "Open Sans";
  font-size: 10px;
  font-style: italic;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0;
  text-align: center;
  border: 0;
  background-color: red;
  background: url(../src/assets/img/table_drp.png);
  background-position: center right;
  background-size: 10px;
  background-repeat: no-repeat;
  color: #444;
}

.upldoctable table tr th p {
  margin: 0;
} */

.progress-bar-new {
  background-color: #2d6163;
  height: 15px;
  border-radius: 20px;
}
/*
.app-personal-savebtn {
  background-color: #4da1ff !important;
  font-size: 10px !important;
  padding: 5px 25px 5px 25px !important;
}

.upldoctable table tr td {
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0;
  text-align: center;
  font-family: "Open Sans";
  text-transform: uppercase;
  padding-top: 21px;
  padding-bottom: 21px;
  position: relative;
  width: 100%;
}

.upldoctable table tr td p {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 24px;
}

.upldoctable table tr th.nameth {
  width: 35%;
}

.upldoctable table tr th.docth {
  width: 35%;
  background-color: transparent;
  background-color: #f2f2f2;
}

.upldoctable table tr th.docth .select-wrapper {
  width: 170px;
  margin: 0 auto;
  background-color: transparent;
  background-color: #f2f2f2;
}

.docth2 {
  width: 50%;
  background-color: transparent;
  background-color: #f2f2f2;
  margin-right: 20px;
}

.docth2 .select-wrapper4 {
  width: 163px;
  margin: 0 auto;
  background-color: transparent;
  background-color: #f2f2f2;
  float: right;
  margin-right: -25px;
  margin-top: -3px;
  margin-bottom: 15px;
} *

/* .select-wrapper4 option:nth-child(0) {
  font-weight:bold;
  border:0px;
    outline:0px;
    font-weight:bold;
    background-color: transparent;
    background-color: #F2F2F2;
} */

/* .docth2 .select-wrapper4 select {
  position: absolute;
  width: 140px;
  margin: 0 auto;
  background: #f2f2f2;
  background-color: transparent;
  background-color: #f2f2f2;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  outline: none;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-weight: 700;
  font-size: 11.5px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0;
  text-transform: capitalize;
  border: 0;
  background: url(../src/assets/img/table_drp.png);
  background-position: center right;
  background-size: 13px;
  background-repeat: no-repeat;
  color: #444;
  transition: border-color 0s ease-in-out, box-shadow 0s ease-in-out;
  box-shadow: 1px 1px 12px rgba(144, 168, 190, 0.33);
  border-radius: 4px;
} */
/*
.docth2 .select-wrapper4 select:hover {
  outline: 0px;
  outline-offset: 0px;
  background: #f2f2f2;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  outline: none;
  border: 0;
  text-transform: uppercase;
  background: url(../src/assets/img/table_drp.png);
  background-position: center right;
  background-size: 13px;
  background-repeat: no-repeat;
  color: #444;
  box-shadow: 0px 0px 0px 0px;
  box-shadow: 1px 1px 12px rgba(144, 168, 190, 0.33);
  border-radius: 4px;
} */
/*
.doct2h .select-wrapper4 select option:not(:checked) {
  background-color: #fff;
  border-color: transparent #fff;
  text-transform: uppercase;
}

.dropdown-menu a:hover {
  color: #fff;
  background-color: #b91773;
  border-color: #fff;
}

#tableDocType > option:nth-child(1):hover {
  color: #fff;
  background-color: #b91773;
  border-color: #fff;
} */

/*.doct2h .select-wrapper4 select option:hover {
    box-shadow: 0 0 10px 100px #1882A8 inset;
    background-color: red;
    color: red;
}*/

/*.select-wrapper4 select option[value="0"] {
  background: red;
}*/

.select-sort {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  /*align-items: center;
  text-align: right;*/
  letter-spacing: 0;
  text-transform: capitalize;
  color: #444444;
  text-shadow: 0px 0px 5px #e6e6e6;
}

.select-sort2 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  /*align-items: center;
  text-align: right;*/
  letter-spacing: 0;
  text-transform: capitalize;
  color: #444444;
  text-shadow: 0px 0px 0px #e6e6e6;
  background: #f2f2f2;
}

.div-align-left {
  float: left;
  width: 75%;
}

.align-left1 {
  margin-left: -1.7em;
}

.align-left2 {
  margin-left: -0.7em;
}

.align-left3 {
  margin-top: 0.5em;
  margin-left: -1em;
}

.align-left4 {
  margin-top: 0.8em;
  margin-left: 13px;
  margin-right: 0px;
}

.align-left5 {
  margin-top: 0.2em;
  margin-left: 10px;
  margin-right: 0px;
}

.align-left6 {
  margin-top: 0em;
  margin-left: -1.8em;
  margin-right: -4em;
}

#meeting_date_hour {
  width: 6em;
}

#meeting_date_hour_ampm {
  width: 4em;
}

#meeting_date_hour2 {
  width: 5em;
}

#meeting_date_hour_ampm2 {
  width: 4em;
}

.select-wrapper3 {
  width: 280px;
  margin: 0 auto;
}

.upldoctable
  .custom-checkbox
  .custom-control-input
  ~ .custom-control-label::after {
  background-image: none;
  width: 15px;
  height: 15px;
  border-radius: 4px;
}

.upldoctable .custom-checkbox .custom-control-label::before {
  background-color: transparent;
  border: 1px solid #e0e0e0 !important;
  width: 15px !important;
  height: 15px !important;
  border-radius: 4px !important;
}

.upldoctable
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::after {
  background: #f2994a !important;
  border: 1px solid #f2994a !important;
}

.upldoctable .custom-control-input:checked ~ .custom-control-label::before {
  background: #e0e0e0;
  width: 11px !important;
  height: 11px !important;
  border-radius: 2px !important;
  border: 0 !important;
  margin-top: 2px;
  margin-left: 2px;
}

.upldoctable .custom-control.custom-checkbox {
  padding-left: 31px;
}

.upldoctable .custom-control-input {
  left: 8px;
  top: 3px;
}

.addfilepopup h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 50px;
  text-align: center;
  color: #000000;
  padding: 0;
  margin: 0 auto 30px;
}

.addfilepopup h3 {
  color: #444;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0;
  text-align: center;
  margin-top: 26px;
  margin-bottom: 18px;
}

/* .drop-img-sec > div {
  border: 1px dashed #109cf1 !important;
  border-radius: 4px !important;
  padding: 20px !important;
  width: 100% !important;
  background-color:  rgba(42, 139, 242, 0.1) !important;
} */

.addfilepopup {
  max-width: 100%;
  margin: 0 0;
  padding: 10px 0;
}

.tscolumn.form-group {
  margin-bottom: 15px !important;
}

.drop-img-sec .file-drop-target {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  text-transform: none;
}

.addfile-pop-outer .modal-header {
  border: 0;
}

/* .addfilepopup {
  padding: 20px 0 30px 0;
} */

/***toggle button css start***/

.togle_switch.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 18px;
}

.togle_switch.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.togle_switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.togle_switch .slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  right: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.togle_switch input:checked + .slider {
  background-color: #2196f3;
}

.togle_switch input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.togle_switch input:checked + .slider:before {
  -webkit-transform: translateX(-10px);
  -ms-transform: translateX(-10px);
  transform: translateX(-10px);
}

.togle_switch .slider.round {
  border-radius: 34px;
}

.togle_switch .slider.round:before {
  border-radius: 50%;
}

/***toggle button css ends***/

/* .react-datepicker__header select {
  background: transparent;
  border: 0;
}

.react-datepicker {
  font-family: "Open Sans";
}

.react-datepicker .react-datepicker__header,
.react-datepicker .react-datepicker__month {
  background-color: #f2f2f2 !important;
  border: 0;
  margin: 0;
}

.react-datepicker .react-datepicker__day-name {
  color: #808080;
  text-transform: uppercase;
  font-family: "Open Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 0.9166666865348816px;
  text-align: center;
}

.react-datepicker .react-datepicker__day-names {
  margin-top: 15px;
}

.react-datepicker .react-datepicker__day {
  color: #999 !important;
  font-size: 11px;
  font-family: "Open Sans";
  position: relative;
}

.react-datepicker .react-datepicker__day--selected:before {
  content: "•";
  position: absolute;
  top: 5px;
  right: 4px;
  font-size: 25px;
  line-height: 0px;
  color: #4da1ff;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
} */

/*******v css ends*****/
/*
header.foe-header-container.app-header.navbar:before {
  content: "";
  position: absolute;
  width: 101%;
  height: 100%;
  background: #f2f2f2;
  transform: rotate(-2deg) translate(-2px, -24px);
  z-index: -1;
} */

.foe-py-2 {
  padding-top: 3em;
}

.foe-nav-right li {
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #444444;
  /* text-shadow: 0px 0px 5px #e6e6e6; */
}

.foe-nav-right li .active-link {
  font-weight: bold;
}

.foe-nav-right li.foe-start-btn.nav-item {
  background: #bcbcbc;
  border-radius: 2px;
  height: 43px;
  padding: 0px 25px;
  font-weight: bold;
  font-size: 13px;
}

.foe-nav-right li.foe-start-btn.nav-item a {
  color: #444444;
}

.foe-nav-right li.foe-login-btn.nav-item {
  background: #ffffff;
  border-radius: 2px;
  height: 43px;
  padding: 0px 40px;
  font-weight: bold;
  font-size: 13px;
}

.foe-footer-container.app-footer {
  background: #444444;
  color: #f2f2f2;
}

.foe-footer-box {
  width: 100%;
}

.footer-blocks li.list-group-item {
  background-color: transparent;
  border: none;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #f2f2f2;
  mix-blend-mode: normal;
  opacity: 0.55;
  padding: 3px 0px;
}

.footer-blocks li.list-group-item a {
  color: #f2f2f2;
}

.footer_logo {
  text-align: center;
}

.footer-blocks .list-group-item-heading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #f2f2f2;
  text-transform: capitalize;
}

.foe-row-border.row:before {
  content: "";
  position: absolute;
  width: 86%;
  height: 0.5px;
  background: transparent;
  top: 0;
  margin: auto;
  left: 1em;
  right: auto;
  border-top: 0.5px solid rgba(255, 255, 255, 0.5);
}

.p-13 {
  font-size: 13px !important;
}

.foe-row-border.row {
  /* border-top: 1px solid #dedede; */
  margin-top: 15px;
  padding-top: 15px;
  position: relative;
  padding-bottom: 15px;
}

.foe-banner-box {
  background: url(../src/assets/img/aboutus-banner.jpg);
  min-height: 788px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.main .foe-no-padd.container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}

button.foe-mail-btn.btn.btn-primary {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50px;
  font-family: "Open Sans";
  font-size: 13px;
  line-height: 15px;
  color: #333333;
  min-width: 225px;
  min-height: 47px;
  border: none;
  box-shadow: none;
  margin-right: 10px;
}

button.foe-sub-btn.btn.btn-secondary {
  background: #2b6a6c;
  border-radius: 50px;
  border: none;
  box-shadow: none;
  min-width: 110px;
  min-height: 47px;
  font-family: "Open Sans";
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
}

p.foe-footer-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 100%;
  color: #f2f2f2;
  text-align: center;
  padding-top: 15px;
}

.foe-about-block {
  background: #ffffff;
  box-sizing: border-box;
  min-height: 788px;
  display: flex;
  align-items: center;
  width: 100%;
}

.about-block-1 {
  background: #ffffff;
  border-bottom: 1px solid rgba(68, 68, 68, 0.3);
}

img.about-circle {
  max-width: 456px;
}

.foe-about-block .card {
  border: none;
}

.foe-card-subtitle.card-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #444444;
}

.foe-card-title.card-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  color: #444444;
}

.foe-card-body.card-text {
  font-family: "Open Sans";
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0;
  color: #2d3032;
  text-align: justify;
}

.foe-green-a.card-link {
  background: #2b6a6c;
  box-shadow: 0px 5px 15px rgba(68, 68, 68, 0.24);
  border-radius: 2px;
  width: 130px;
  height: 43px;
  display: block;
  float: left;
  font-family: "quicksand";
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  /* display: flex; */
  align-items: center;
  color: #ffffff;
  text-align: center;
  padding-top: 12px;
}

a.foe-read-a.card-link {
  background: #ffffff;
  border: 1px solid rgba(68, 68, 68, 0.3);
  box-sizing: border-box;
  min-width: 130px;
  max-width: 130px;
  float: left;
  min-height: 43px;
  text-align: center;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #444444;
  padding-left: 30px;
}

.about-block-2,
.about-block-2 .foe-about-block {
  background-color: #f2f2f2;
}

.foe-service-header {
  text-align: center;
}

.foe-service-left img.foe-service-img.card-img,
.foe-service-center img.foe-service-img.card-img {
  width: 96px;
}

.foe-service-right img.foe-service-img.card-img {
  width: 158px;
}

.foe-img-block {
  min-height: 229px;
}

.foe-card.card {
  text-align: center;
  background: #f2f2f2;
}

.foe-service-left img.foe-service-img.card-img,
.foe-service-right img.foe-service-img.card-img {
  padding-top: 3em;
}

.foe-card .foe-card-title.card-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0;
  color: #2d3032;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.foe-white-a.card-link {
  min-width: 185px;
  min-height: 47px;
  background: #ffffff;
  box-shadow: 1px 1px 12px rgba(144, 168, 190, 0.33);
  border-radius: 50px;
  max-width: 185px;
  margin: auto;
  display: block;
}

a.foe-white-a.card-link span {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 17px;
}

.foe-card .foe-card-body.card-text {
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0;
  color: #2d3032;
  max-width: 140px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 3em;
  padding-top: 1em;
}

.foe-service-header .foe-card-subtitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #444444;
}

.foe-service-header .foe-card-title.card-title {
  padding-top: 1em;
  padding-bottom: 2em;
}

.foe-banner-boxx {
  min-height: 788px;
  display: flex;
  align-items: center;
  width: 100%;
}

.foe-student-box.foe-institute .foe-about-boxx {
  width: 84%;
  margin: auto;
}

.foe-top-1 select {
  text-align-last: center;
}

.foe-subject .form-control {
  height: 47px;
  min-width: 185px;
  text-align: center;
  background: #ffffff;
  box-shadow: 1px 1px 12px rgba(144, 168, 190, 0.33);
  border-radius: 50px;
  font-family: "Open Sans semibold";
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0;
  color: #000000;
}

.foe-service-container {
  box-sizing: border-box;
  min-height: 900px;
  display: flex;
  align-items: center;
  width: 100%;
  background: url(../src/assets/img/service_background.jpg);
  background-size: cover;
  background-attachment: initial;
  background-repeat: no-repeat;
}

.foe-service-block {
  box-sizing: border-box;
  min-height: 447px;
  display: flex;
  align-items: center;
  width: 100%;
  background: linear-gradient(199.86deg, #b80d48 12.77%, #9f1041 73.11%);
}

.foe-service-block .foe-service-header .foe-card-title.card-title {
  padding-bottom: 15px;
  color: #ffffff;
  font-family: "Open Sans";
  font-weight: 300;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  color: #ffffff;
  padding-top: 15px;
}

.foe-service-body {
  font-family: "Open Sans";
  font-weight: normal;
  letter-spacing: 0;
  color: #ffffff;
  padding-bottom: 2em;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 300;
  font-size: 35px;
  line-height: 41px;
  text-align: center;
  color: #ffffff;
}

.foe-service-block .foe-service-header .foe-card-subtitle {
  color: #ffffff;
}

.foe-subject button.btn.service-btn {
  height: 47px;
  min-width: 185px;
  text-align: center;
  background: #ffffff;
  box-shadow: 1px 1px 12px rgba(144, 168, 190, 0.33);
  border-radius: 50px;
  font-family: "Open Sans";
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0;
  color: #000000;
}

.foe-top-1.foe-subject {
  text-align: center;
}

.foe-service-block .foe-service-header .foe-card-title.card-title span {
  font-weight: 600;
}

.f-orange {
  color: #f29724 !important;
}

.f-purple {
  color: #ab0f45 !important;
}

.f-blue {
  color: #2b6a6c !important;
}

.foe-student-block {
  box-sizing: border-box;
  min-height: 700px;
  display: flex;
  align-items: top;
  width: 100%;
  background: linear-gradient(199.86deg, #fafafa 12.77%, #f5f5f5 73.11%);
}

.foe-student-service-banner {
  background: url(../src/assets/img/student-banner.jpg);
  background-size: cover;
  min-height: 100vh;
  background-position: top center;
}

.partners-overview .foe-student-service-banner {
  background: url(../src/assets/img/partner_bg.jpg);
  background-size: cover;
  min-height: 100vh;
  background-position: top center;
  background-repeat: no-repeat;
}

.partners-overview .foe-service-left .foe-partner-bg {
  min-height: 544px;
  background: url(../src/assets/img/partner_inner_bg.jpg);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

.partners-overview
  .university-overview-inner
  .foe-service-left
  .foe-partner-bg {
  min-height: 544px;
  background: url(../src/assets/img/university-block-1.jpg);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

.partners-overview
  .university-overview-inner
  .university-row-2
  .foe-service-left
  .foe-partner-bg {
  min-height: 544px;
  background: url(../src/assets/img/university-block-2.jpg);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

.partners-overview .foe-partner-bottom {
  min-height: 448px;
  background: url(../src/assets/img/partner_bottom_bg.jpg);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

.partners-overview.university-overview .foe-partner-bottom {
  min-height: 448px;
  background: url(../src/assets/img/university_bottom_bg.jpg);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

.university-overview .foe-student-service-banner {
  background: url(../src/assets/img/partner_bg.jpg);
  background-size: cover;
  min-height: 100vh;
  background-position: top center;
}

.foe-university-list {
  box-sizing: border-box;
  min-height: 400px;
  display: flex;
  align-items: center;
  width: 100%;
}

.student-overview-inner.partners-overview-inner.university-overview-inner {
  padding-top: 75px;
  padding-bottom: 75px;
}

.university-row-2 {
  padding-top: 3em;
}

.university-overview .foe-university-list .service-ul li {
  width: 50%;
  font-family: "Open Sans";
  font-weight: normal;
  font-size: 28px;
  line-height: 28px;
  color: #828282;
  padding-bottom: 30px;
}

.university-overview .foe-university-list .service-ul {
  width: 94%;
  margin: auto;
}

.partners-overview.university-overview
  .foe-student-block
  .foe-service-left
  img.foe-service-img.card-img {
  width: 235px;
}

.university-overview
  .student-overview-inner
  .foe-service-header
  .foe-card-title.card-title {
  padding-bottom: 0;
}

.university-overview
  .student-overview-inner
  .foe-service-header
  .partner-header-sub {
  font-family: "Open Sans";
  color: #333333;
  font-weight: 300;
  font-size: 25px;
  line-height: 29px;
  text-align: center;
  color: #333333;
  padding-bottom: 2em;
}

.student-overview.partners-overview .foe-service-left.foe-partner-right .card {
  background: transparent;
  color: #333333;
  padding-top: 0px;
  border: none;
}

.partners-overview
  .foe-timeline
  .foe-service-header
  .foe-card-title.card-title {
  text-transform: capitalize;
}

.student-overview.partners-overview.university-overview
  .foe-partner-right
  .foe-card
  .foe-card-body.card-text {
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  padding-top: 1em;
}

.student-overview.partners-overview
  .foe-service-left.foe-partner-right
  .card
  .card-body {
  padding-top: 0px;
}

.student-overview.partners-overview
  .foe-partner-right
  .foe-card
  .foe-card-body.card-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 25px;
  line-height: 34px;
  color: #333333;
  width: 100%;
  max-width: 100%;
  padding-top: 0;
}

.service-ul {
  padding-left: 10px;
}

.service-ul li {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #333333;
  text-align: left;
  padding-left: 5px;
  padding-bottom: 6px;
}

.university-overview .service-ul li {
  display: inline-flex;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 15px;
}

.service-ul li:before {
  content: "\f058";
  font-family: FontAwesome;
  color: #67aefd;
  /* margin-left: -20px; */
  position: relative;
  left: -13px;
  font-size: 24px;
}

.student-overview.partners-overview .foe-partner-right .foe-white-a.card-link {
  height: 47px;
  background: #67aefd;
  max-width: 185px;
  min-width: 185px;
  margin-left: 5px;
  text-align: center;
  font-family: "Open Sans";
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0;
  color: #ffffff;
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.foe-timeline
  .partners-overview
  .foe-service-header
  .foe-card-title.card-title {
  font-size: 32px;
  text-transform: capitalize;
}

p.foe-card-sb-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 25px;
  line-height: 34px;
  text-align: center;
  color: #333333;
  width: 90%;
  margin: auto;
}

.student-overview.partners-overview
  .foe-partner-right
  .foe-white-a.card-link
  span {
  padding-top: 0px;
}

.partners-overview
  .foe-student-block
  .foe-service-left
  img.foe-service-img.card-img {
  width: 127px;
  padding-top: 0px;
}

.foe-no-margin {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.student-overview .foe-card .foe-card-title.card-title {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: #333333;
  text-align: left;
  font-family: "Lato";
}

.student-overview .foe-card .foe-card-body.card-text {
  font-family: "Open Sans";
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0;
  color: #4f4f4f;
  max-width: 70%;
  text-align: left;
  padding-bottom: 10px;
}

.student-overview .foe-card-subtitle {
  font-family: "Lato";
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #333333;
  text-align: left;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.student-overview .foe-white-a.card-link {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #3f3f3f;
  border: none;
  box-shadow: none;
  background: transparent;
  text-align: left;
  max-width: 70%;
  min-width: 70%;
}

.student-overview .foe-white-a.card-link span {
  justify-content: left;
}

.fo-trans {
  transform: rotate(270deg);
  filter: invert(1);
  -webkit-filter: invert(1);
}

.student-overview-inner {
  background-color: #fff;
}

.student-overview-inner .foe-card {
  background-color: #333333;
}

.foe-accordian h5 .btn-link {
  font-family: "Quicksand";
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0;
  color: #444444;
  text-decoration: none !important;
}

.student-overview .foe-accordian .foe-card-subtitle {
  text-align: center;
}

.foe-accordian .accordion > .card .card-header {
  background-color: transparent;
  border-bottom: 1px solid #444444;
  margin-bottom: 0px;
}

.foe-accordian .accordion button.btn.btn-link.collapsed:before {
  content: "\f107";
  font-family: FontAwesome;
  position: absolute;
  left: 0;
  font-size: 28px;
}

/* button.btn.btn-link:before {
	content: "\f106";
	font-family: FontAwesome;
	position: absolute;
	left: 0;
	font-size: 28px;
} */

.foe-acc-body p {
  font-family: "Open Sans";
  font-weight: normal;
  font-size: 14px;
  line-height: 27px;
  letter-spacing: 0;
  color: #a7a7a7;
  /* min-width: 300px; */
  /* max-width: 400px; */
  width: 70%;
}

button.btn.btn-doc {
  width: 195px;
  height: 43px;
  background: #bcbcbc;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

button.btn.btn-doc span {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  text-align: center;
  color: #ffffff;
  width: 100%;
}

/* services*/

.foe-service-container.foe-services-box .foe-service-block {
  background: linear-gradient(199.86deg, #026265 12.77%, #2b6a6c 73.11%);
}

/* services end*/

@media only screen and (min-width: 992px) {
  /* .header-fixed header.foe-header-container.app-header {
    min-height: 167px;
    background: transparent;
    flex-direction: column;
    border-bottom: none;
  } */
  .header-fixed .app-body.foe-body {
    margin-top: 120px;
  }
  .foe-header-container.app-header .foe-nav-right li {
    margin: 0px 11px;
  }
  .nav-right-box.nav-item a {
    padding: 0px 15px;
  }
  .foe-header-container.app-header .nav-item .nav-link {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0;
    text-transform: uppercase;
    color: #444444;
    text-shadow: 0px 0px 5px #e6e6e6;
  }
  .student-overview .foe-card.card {
    padding-top: 10em;
  }
  .student-overview .student-overview-inner .foe-card.card {
    padding-top: 1em;
  }
  .foe-timeline-box {
    max-width: 300px;
    padding-left: 20px;
  }
  .timeline .timeline-item:nth-child(even) .timeline-panel .foe-timeline-box {
    float: right;
    text-align: right;
  }
  .timeline
    .timeline-item:nth-child(even)
    .timeline-panel
    .foe-timeline-box
    .timeline-title {
    margin-right: 0px;
    margin-left: auto;
  }
  .foe-pp-container {
    padding-top: 40px;
    background-color: #ffffff;
  }
  /* .foe-studen-container {
    padding-top: 80px;
    background-color: #fafafa;
  } */
  .foe-studen-container.foe-institute-box {
    padding-top: 0px;
  }
}

.foe-studen-container.foe-institute-box {
  padding-top: 0px;
}

/*
.uni-left-card.card {
  background: #ffffff;
  border-radius: 3px;
  border: none;
  min-height: 310px;
  border-left: 3px solid #2d6163;
}
.uni-left-card.app-inprogress{border-left: none !important;} */

/* .uni-right-card.card {
  border: none;
  background: #ffffff;
  border-radius: 3px;
} */

.left-list {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  color: #bdbdbd;
}

ul.left-list.list-group li {
  border: none;
}
/*
h4.media-heading {
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 18px;
  line-height: 136.4%;
  display: flex;
  align-items: center;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #4f4f4f;
}

.media {
  font-family: "Open Sans";
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #4f4f4f;
  text-shadow: 0px 0px 5px #e6e6e6;
  margin-bottom: 10px;
}

.media-bottom {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #bdbdbd;
}

.media-left {
  padding-top: 15px;
}

.media-right {
  padding-right: 0px;
} */

/* .profile-btn button.btn.btn-primary {
  background: #4da1ff;
  border: 1px solid rgba(77, 161, 255, 0.7);
  border-radius: 2px;
  font-family: "Open Sans";
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #ffffff;
}

.profile-btn button.btn.btn-primary2 {
  background: #4da1ff;
  border: 1px solid rgba(77, 161, 255, 0.7);
  border-radius: 3px;
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 9px;
  line-height: 14px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #ffffff;
}

.profile-btn button.btn.btn-primary3 {
  background: #2d6163;
  width: 122px;
  height: 30px;
  border-radius: 2px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 14px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #ffffff;
} */

.profile-id {
  padding-top: 25px;
}

.profile-student .nav li {
  position: relative;
}

/* .profile-student .nav li:before {
    content: "";
    background: #E0E0E0;
    border-radius: 6.5px;
    height: 12px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
} */

.profile-student .nav li {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: top;
  text-align: center;
  letter-spacing: 0;
  text-transform: capitalize;
  /* Gray 2 */
  color: #4f4f4f;
  text-shadow: 0px 0px 5px #e6e6e6;
  min-height: 50px;
}

.complete-box {
  background: #f2f2f2;
  min-height: 80px;
  padding: 15px;
}

.profile-student {
  width: 100%;
}

.com-top {
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0;
  text-transform: none;
  color: #4f4f4f;
  text-shadow: 0px 0px 5px #e6e6e6;
}

p.com-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0;
  text-transform: initial;
  color: #4f4f4f;
  text-shadow: 0px 0px 5px #e6e6e6;
  padding-top: 10px;
}

.match-box.py-2 {
  font-family: "Open Sans";
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #4f4f4f;
  padding-left: 10px;
}

.tab-content .btn-group {
  width: 100%;
}

.tab-content .btn-group .btn.dropdown-toggle {
  background: rgba(244, 247, 249, 0.4);
  border: 1px solid #e9eff4;
  border-radius: 0px 4px 4px 0px;
  width: 45px;
  flex: 0 0 auto;
}

.tab-content .btn-group .btn.dropdown-toggle .fa {
  font-size: 24px;
  position: relative;
  left: 4px;
}

.tab-content .btn-group .btn.dropdown-toggle:after {
  border: none;
}

.tab-content .btn-group .btn {
  border: 1px solid #e9eff4;
  border-radius: 4px;
  min-height: 45px;
  font-family: "Open Sans";
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #323c47;
  mix-blend-mode: normal;
  background: transparent;
  outline: none;
  box-shadow: none;
}

.tab-content .btn-group button {
  background-color: transparent;
}

.quantity-box {
  width: 28px;
  height: 45px;
  background: rgba(244, 247, 249, 0.4);
  border: 1px solid #e9eff4;
  border-radius: 0px 4px 4px 0px;
}

.valigntop {
  display: inline-block;
  /*margin-top: -3.8em;*/
  text-align: -webkit-right;
}

.valigntop2 {
  margin-top: 5px;
  margin-bottom: -2px;
}

.destination-box .uni-label-header {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 31px;
  /* identical to box height */
  color: #444444;
  margin-bottom: -2em;
}

.destination-box .uni-label-pp-name {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 26px;
  /* identical to box height, or 137% */
  color: #444444;
  margin-bottom: -2em;
}

.uni-label-pp-hr {
  width: 647px;
  height: 0px;
  left: 389px;
  /*top: 273px;*/
  margin-top: 0.8em;
  border: 1px solid #444444;
}

.uni-label-pp-hr2 {
  width: 647px;
  height: 0px;
  left: 389px;
  /*top: 273px;*/
  margin-top: 1.8em;
  margin-bottom: 1em;
  margin-left: 14em;
  border: 1px solid #e0e0e0;
}

.uni-label-pp-hr3 {
  width: 647px;
  height: 0px;
  left: 389px;
  /*top: 273px;*/
  margin-top: 0.8em;
  margin-bottom: 1.2em;
  border: 1px solid #444444;
}

.destination-box .uni-label-pp-address {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  color: #444444;
}

.destination-box .uni-label-pp-header {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 22px;
  /* identical to box height */
  color: #444444;
  margin-top: -5em;
  margin-left: -4px;
  margin-bottom: -5em;
}

.destination-box .uni-label-pp-header2 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  /* identical to box height, or 183% */
  color: #333333;
  margin-top: -5em;
  margin-left: -5px;
  margin-bottom: -10em;
}

.destination-box .spanLeft {
  display: block;
  float: left;
}

.destination-box .spanRight {
  display: block;
  float: right;
  margin-top: 0px;
  margin-right: -7em;
}

.personal-box-inner2 {
  margin-left: 5.2em;
}

.destination-box .uni-label {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 136.4%;
  display: flex;
  align-items: center;
  letter-spacing: 0;
  color: #4f4f4f;
  mix-blend-mode: normal;
  margin: 10px 0px 10px;
  font-family: "Open Sans";
}

.form-group + h6 {
  font-size: 12px;
}

.score-list.score-btween .scor-1 {
  width: 100%;
}

.row.babf-column label.uni-label {
  min-height: 36px;
  margin-top: 12px;
}

.scroe-box.pte-score-box label.uni-label,
.scroe-box.oet-c1-score-box label.uni-label,
.scroe-box.ielts-toef-scror-box label.uni-label {
  font-style: normal;
  font-weight: 800;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #595959 !important;
}
/*
.scroe-box.oet-c1-score-box .useof-english input#speakingscore {
  border-top-left-radius: 0.25rem !important;
}

.scroe-box.oet-c1-score-box .useof-english input#averagescore {
  border-bottom-left-radius: 0.25rem !important;
}

.scroe-box.oet-c1-score-box .form-control.total-score,
.scroe-box.ielts-toef-scror-box .form-control.total-score {
  border-radius: 0.25rem !important;
}

.scroe-box.pte-score-box input#listeningscore,
.scroe-box.pte-score-box input#pte_grammer_score,
.scroe-box.pte-score-box input#pte_vocab_score,
input#readingscore {
  border-bottom-left-radius: 0px;
}

.scroe-box.pte-score-box input#listening_rank,
.scroe-box.pte-score-box input#pte_grammer_rank,
.scroe-box.pte-score-box input#pte_vocab_rank,
input#averagescore {
  border-top-left-radius: 0px;
}

.scroe-box.pte-score-box input#speakingscore,
.scroe-box.pte-score-box input#pte_spelling_score,
.scroe-box.pte-score-box input#pte_written_score,
input#speakingscore {
  border-bottom-right-radius: 0px;
}

.scroe-box.pte-score-box input#speaking_rank,
.scroe-box.pte-score-box input#pte_spelling_rank,
.scroe-box.pte-score-box input#pte_written_rank,
input#averagescore {
  border-top-right-radius: 0px;
}

.scroe-box.oet-c1-score-box .uni-no-mar .uni-no-padd {
  margin-bottom: 10px;
}

.scroe-box.pte-score-box input,
.oet-c1-score-box input {
  border: 1px solid #c8dadb;
}

.scroe-box.ielts-toef-scror-box input {
  border: 1px solid #c8dadb !important;
}

.scroe-box.ielts-toef-scror-box input#speakingscore {
  border-bottom-right-radius: 0.25rem !important;
  border-left: none !important;
}

.scroe-box.ielts-toef-scror-box input#readingscore {
  border-bottom-left-radius: 0.25rem !important;
}

.scroe-box.ielts-toef-scror-box input#listeningscore {
  border-left: none !important;
  border-right: none !important;
}

.scroe-box.pte-score-box .pr-header,
.oet-c1-score-box .pr-header {
  margin-bottom: 10px;
}

input#listeningscore,
input#readingscore,
input#writingscore,
input#speakingscore,
input#pte_grammer_score,
input#pte_oral_score,
input#pte_pron_score,
input#pte_spelling_score,
input#pte_vocab_score,
input#pte_written_score {
  border-bottom: none;
} */

input#satExamDate,
input#lsatExamDate,
input#mcatExamDate {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 136.4%;
  display: flex;
  align-items: center;
  letter-spacing: 0;
  mix-blend-mode: normal;
  border: 1px solid #e9eff4;
  border-radius: 4px;
  height: 34px;
  width: 100%;
  padding: 10px;
}

.personal-box-inner.lasttestscore {
  margin-bottom: 30px;
}

.pr-header-recent {
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 29px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #4f4f4f;
}

.complete-box .com-top img.uni-icon {
  max-width: 22px;
}

.english-exam-column label.uni-label {
  text-transform: uppercase;
  font-size: 8px;
  font-weight: 800;
}

h6.error {
  font-size: 12px;
}

.destination-box .form-control {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 136.4%;
  display: flex;
  align-items: center;
  letter-spacing: 0;
  color: #4f4f4f;
  mix-blend-mode: normal;
  border: 1px solid #e9eff4;
  border-radius: 4px;
  height: 34px;
  padding-left: 15px;
}

.fieldset2 {
  font-size: 1em;
  padding: 0.5em;
  border-radius: 1em;
  font-family: sans-serif;
  font-size: inherit;
  padding: 0.2em;
  margin: 0.1em 0.2em;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.delete-request {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  display: flex;
  align-items: flex-end;
  text-align: center;
  letter-spacing: 0;
  color: #333333;
  opacity: 0.6;
}

#error-align1 {
  margin-top: 3em;
  margin-left: 2em;
}

.error-align1 + h6 {
  margin-top: 10px;
  margin-left: 13.5em;
  display: inline-block;
  vertical-align: top;
}

#error-align2 {
  margin-top: 3em;
  margin-left: 2em;
}

.error-align2 + h6 {
  margin-top: -2em;
  margin-left: 13.5em;
}

h6.error.error-align1 {
  flex: 1;
  margin-top: -5px;
}

h6.error.error-align2 {
  flex: 1;
  margin-top: -7.5px;
  margin-left: 9.6em;
}

h6.error.error-align3 {
  flex: 1;
  margin-top: -5px;
  margin-left: -1.8em;
}

h6.error.error-align4 {
  flex: 1;
  margin-top: -12.5px;
  margin-left: 7.2em;
}

.time-sub-text {
  margin-top: 0.5em;
  margin-left: 6px;
}

.right-inner-addon {
  position: relative;
}

.right-inner-addon input {
  padding-right: 30px;
}

.right-inner-addon i {
  position: absolute;
  /*text-indent: -15px;
  bottom: -8px;
  font-size: 1.3em;*/
  right: 0px;
  padding: 10px 12px;
}

.calendar-icon {
  padding-right: calc(1.5em + 0.75rem);
  /*background-image: url('https://use.fontawesome.com/releases/v5.8.2/svgs/regular/calendar-alt.svg');*/
  background-image: url(../src/assets/img/calendar-grey.svg) !important;
  background-repeat: no-repeat;
  background-position: center right calc(0.755em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  /*cursor: pointer;*/
}

.calendar-icon2 {
  padding-right: calc(1.5em + 0.75rem);
  /*background-image: url('https://use.fontawesome.com/releases/v5.8.2/svgs/regular/calendar-alt.svg');*/
  background-image: url(../src/assets/img/calendar3.png) !important;
  background-repeat: no-repeat;
  /*background-position: center right calc(.2000em + .1000em);*/
  background-position: center right calc(-0.25em + 0.1875rem);
  background-size: calc(0.75em + 1.5rem) calc(0.75em + 1.5rem);
  /*cursor: pointer;*/
}

.pr-header h3 {
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 29px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #4f4f4f;
}

.pr-header span {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #4f4f4f;
  padding-bottom: 5px;
}

.personal-box-inner2 {
  font-style: normal;
  font-weight: 800;
  font-size: 8px !important;
  line-height: 9px;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #bdbdbd;
  mix-blend-mode: normal;
  margin-bottom: 5px;
}

.personal-box-inner .uni-label {
  font-style: normal;
  font-weight: 800;
  font-size: 10px !important;
  line-height: 14px;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #bdbdbd;
  mix-blend-mode: normal;
  margin-bottom: 5px;
}

.personal-box-inner .uni-label2 {
  font-style: normal;
  font-weight: 800;
  font-size: 10px !important;
  line-height: 14px;
  letter-spacing: 0;
  text-transform: uppercase;
  mix-blend-mode: normal;
  margin-bottom: 1px;
  color: #333333;
}

.personal-box-inner .uni-label3 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  /* identical to box height, or 183% */
  color: #333333;
}

.personal-box-inner .form-control {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 136.4%;
  display: flex;
  align-items: center;
  letter-spacing: 0;
  mix-blend-mode: normal;
  border: 1px solid #e9eff4;
  border-radius: 4px;
  height: 34px;
  padding-left: 15px;
}

.form-group + h6 {
  margin: 0;
}

.select-wrapper select {
  color: #4f4f4f;
}

.personal-box-inner .form-control.type-bbo {
  width: 100%;
  height: 100%;
  background: transparent;
  min-width: 40px;
  max-width: 50px;
  border: none;
}

.personal-box-inner .input-group-text {
  padding: 0;
}

.profile-student .tab-content {
  border: none;
}

.profile-student .nav-tabs .nav-link {
  padding: 0.5rem 1.31562rem;
}

.profile-student .nav-tabs .nav-link.active,
.profile-student .nav-tabs .nav-link:hover,
.profile-student .nav-tabs .nav-link:focus {
  border-color: transparent !important;
}

.profile-student .nav-tabs {
  border: none;
}

.uni-no-padd {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.uni-no-mar {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.no-br .form-control {
  border-right: 0px !important;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.no-br.no-br-l .form-control {
  border-radius: 0px;
}

.no-bl .form-control {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.pp-textbox {
  width: 306px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0;
  color: #333333;
}

#dateofbirth2 {
  width: 306px;
}

.pp-selectbox {
  width: 306px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0;
  color: #333333;
}

.pp-textbox input.form-control {
  color: #333333;
}

.pp-textbox-1:after {
  font-family: FontAwesome;
  content: "\f107";
  font-size: 15px;
  position: absolute;
  top: 2px;
  right: 21px;
  color: #bdbdbd;
  pointer-events: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.scroe-box .uni-label {
  font-style: normal;
  font-weight: 800;
  font-size: 8px !important;
  line-height: 9px;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #bdbdbd !important;
  mix-blend-mode: normal;
}

.scroe-box .form-control {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0;
  color: #bdbdbd !important;
  mix-blend-mode: normal;
}

.scroe-box .pr-header h3 {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  text-transform: capitalize;
  color: #4f4f4f;
}

label.form-check-label {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  /* line-height: 136.4%; */
  display: flex;
  align-items: center;
  letter-spacing: 0;
  color: #4f4f4f;
  mix-blend-mode: normal;
  padding-top: 3px;
}

.pr-header h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  text-transform: capitalize;
  color: #4f4f4f;
  padding: 7px 0px;
}

.score-name {
  display: flex;
  align-items: center;
  padding: 9px;
}

.score-name .form-control {
  width: 50px !important;
  margin-left: 10px;
  border: none;
  height: 24px;
  padding: 5px;
}

.score-list {
  border: 1px solid #e9eff4;
  border-radius: 4px;
  min-height: 75px;
  display: flex;
}

.score-name.border-gray {
  border-bottom: 1px solid #f4f7f9;
}

/* .scor-1 {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  width: 45px;
  line-height: 136.4%;
  display: flex;
  align-items: center;
  letter-spacing: 0;
  color: #bdbdbd;
  mix-blend-mode: normal;
} */

.backgroundinfo textarea.form-control {
  border-radius: 4px;
  min-height: 75px;
}

button.score-back.btn {
  background: #ffffff;
  border: 1px solid #2d6163;
  box-sizing: border-box;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  /* transform: rotate(-180deg); */
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #2d6163;
  min-height: 40px;
  min-width: 95px;
  justify-content: center;
  margin-right: 10px;
}

button.score-save.btn {
  background: #444444 !important;
  /* box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06); */
  border-radius: 2px;
  border: 1px solid #444444;
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  min-height: 40px;
  min-width: 95px;
  justify-content: center;
}

.foe-student-box.foe-institute {
  background: url(../src/assets/img/institute-bg.jpg);
  min-height: 488px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* login */

.app.login-bg {
  background-color: #ffffff;
  justify-content: center;
}

.foe-login {
  display: flex;
  width: 100%;
  background: radial-gradient(farthest-corner at 100% 0%, #d1d1d1, #8f8f8f);
  flex-direction: column;
}

.foe-login-inner h1 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  color: #ffffff;
}

.foe-login-inner .card {
  max-width: 370px;
  margin: auto;
  padding: 0px !important;
  background: transparent;
  border: none;
}

.foe-login-inner input.form-control {
  background: #d8d8d8;
  border: none;
  height: 40px;
  mix-blend-mode: normal;
  border-radius: 4px !important;
}

label.foe-login-label {
  width: 100%;
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  text-transform: capitalize;
  padding-bottom: 5px;
}

img.login-logo {
  width: 289px;
}

.login-button {
  text-align: center;
}

.login-button button,
.student-left-login button {
  background: #ffffff;
  box-shadow: 1px 1px 12px rgba(144, 168, 190, 0.33);
  border-radius: 10px;
  border: none;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0;
  color: #000000;
  min-width: 135px;
  height: 35px;
}

.student-left-login button:hover {
  background: #2b6a6c;
  color: #ffffff;
}

.foe-login-inner {
  padding-top: 2em;
  padding-bottom: 2em;
}

.login-bos {
  padding-bottom: 1.5em;
}

p.forgot-ext {
  text-align: center;
  margin-bottom: 0px;
}

/* login end*/

/*register start*/

.foe-register .register-btn.btn {
  background: #f2f2f2;
  border-radius: 2px;
  width: 115px;
  min-height: 43px;
  border: none;
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  /* display: flex; */
  /* align-items: center; */
  text-align: center;
  color: #444444;
}

.foe-register .card-body {
  padding-top: 0px;
}

.register-popup .react-tabs__tab-list {
  padding: 0px;
  min-height: 45px;
  display: flex;
  justify-content: center;
}

.register-popup .modal-body form {
  width: 80%;
  margin: auto;
}

.register-popup .react-tabs__tab-list li {
  width: 33.33%;
  float: left;
  text-align: center;
  font-family: "Open Sans";
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #bdbdbd;
  outline: none;
  position: relative;
  cursor: pointer;
}

.register-popup .react-tabs__tab-list li:before {
  content: "";
  background: #e0e0e0;
  border-radius: 6.5px;
  height: 12px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.register-popup .react-tabs__tab-list li:nth-child(1):before {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.register-popup .react-tabs__tab-list li:nth-child(2):before {
  border-radius: 0px;
}

.register-popup .react-tabs__tab-list li:nth-child(3):before {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.register-popup .react-tabs__tab-list li:nth-child(4):before {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.register-popup
  .react-tabs__tab-list
  li.step1.react-tabs__tab--selected:before {
  background: #2b6a6c;
  border-top-right-radius: 6.5px;
  border-bottom-right-radius: 6.5px;
}

.register-popup
  .react-tabs__tab-list
  .step1
  ~ .react-tabs__tab--selected:before {
  background: #2b6a6c;
  border-radius: 6.5px;
}

.register-popup .step1.react-tabs__tab-panel--selected {
  width: 100%;
  margin: auto;
  max-width: 330px;
}

.register-popup .react-tabs label {
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  color: #333333;
}

.register-popup .react-tabs .form-control {
  background: #ffffff;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 3px !important;
  height: 40px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: #333333;
  font-family: "Open Sans";
}

.register-popup .react-tabs .form-control:focus {
  outline: none;
  box-shadow: none;
}

.register-popup .react-tabs select.form-control {
  font-family: "Montserrat";
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #cbcfd4;
  position: relative;
}

.register-popup .react-tabs .modal-footer {
  border-top: none;
  justify-content: center;
}

.register-popup .react-tabs .modal-footer .btn {
  background: #2b6a6c;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  height: 40px;
  min-width: 90px;
  border: none;
}

.register-popup .modal-body .react-tabs .tab-subtitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 36px;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #333333;
}

.register-popup .modal-body .react-tabs {
  padding: 3em 0px;
}

.register-popup .modal-content {
  background-color: #f2f2f2;
}

.register-popup .react-tabs textarea {
  background: #ffffff;
  border: 1px solid #2b6a6c;
  box-sizing: border-box;
  border-radius: 3px;
  height: 168px;
  font-family: "Montserrat";
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #cbcfd4;
  padding: 10px;
}

.foe-phone span.input-group-text {
  border: 1px solid #bdbdbd;
  background: transparent;
  font-family: "Montserrat";
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #cbcfd4;
}

.register-popup .react-tabs .step2 .modal-footer,
.register-popup .react-tabs .step3 .modal-footer {
  justify-content: flex-end;
}

.register-popup .react-tabs .modal-footer .back-btn.btn {
  border: 1px solid #2b6a6c;
  box-sizing: border-box;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  background: #ffffff;
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #2b6a6c;
}

.tell-us {
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  color: #333333;
  padding-bottom: 10px;
}

.modal_header {
  display: flex;
  justify-content: flex-end;
  width: 100%;

}
.modal_header a{
  padding: 10px 10px 0px 0px;

}

.modal_header span {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #333333;
  text-shadow: 0px 0px 5px #e6e6e6;
}

.profile-success-pop {
  top: 20%;
  left: 0%;
  width: 60%;
}

.register-popup .react-tabs .modal-body {
  padding-top: 3em;
}

.register-popup
  .student-popup
  .react-tabs__tab-list
  li.step1.react-tabs__tab--selected:before {
  background: #f2994a;
  border-top-right-radius: 6.5px;
  border-bottom-right-radius: 6.5px;
}

.register-popup
  .student-popup
  .react-tabs__tab-list
  li.step1.react-tabs__tab--selected,
.register-popup .student-popup .react-tabs__tab-list li.step1.activestep1 {
  color: #333333;
}

.register-popup
  .student-popup
  .react-tabs__tab-list
  li.step2.react-tabs__tab--selected,
.register-popup .student-popup .react-tabs__tab-list li.step2.activestep2 {
  color: #333333;
}

.register-popup
  .student-popup
  .react-tabs__tab-list
  li.step3.react-tabs__tab--selected,
.register-popup .student-popup .react-tabs__tab-list li.step3.activestep3 {
  color: #333333;
}

.register-popup
  .student-popup
  .react-tabs__tab-list
  li.step4.react-tabs__tab--selected,
.register-popup .student-popup .react-tabs__tab-list li.step4.activestep4 {
  color: #333333;
}

.register-popup
  .student-popup
  .react-tabs__tab-list
  .step1
  ~ .react-tabs__tab--selected:before {
  background: #f2994a;
  border-radius: 6.5px;
}

.register-popup .student-popup .react-tabs .modal-footer .btn {
  background: #f2994a;
  font-family: "Open Sans";
  letter-spacing: 0;
}

.register-popup .student-popup .react-tabs .step4 .modal-footer {
  justify-content: flex-end;
}

/*.register-popup .student-popup .custom-control-input:checked ~ .custom-control-label::before {
        color: #fff;
        border-color: #f2994a;
        background-color: #f2994a;
    } */

.custom-control-input {
  position: absolute;
  z-index: 1 !important;
  opacity: 0;
  left: 2px;
  top: 5px;
}

.login-forgot .btn-link:hover,
.login-forgot .btn-link {
  color: #ffffff;
  text-decoration: none;
}

.register-popup.student-popup-box .modal-content {
  padding: 1rem;
}

.student-check-block
  .custom-control-input:checked
  ~ .custom-control-label::before {
  color: #fff;
  border-color: #f2994a;
  background-color: #f2994a;
}

.student-check-block .custom-radio .custom-control-label::before {
  border-radius: 4px;
  min-width: 120px;
  min-height: 45px;
  left: 0px;
  z-index: 0;
}

.student-check-block {
  width: 100%;
}

.student-check-block .custom-radio {
  width: 25%;
  margin-right: 0px;
  padding: 0px 10px;
}

.student-check-block .custom-radio .custom-control-label {
  width: 100%;
}

span.check-1 {
  position: relative;
  text-align: center;
  width: 100%;
  display: block;
  z-index: 0;
  min-height: 45px;
  padding-top: 13px;
}

.student-check-block
  .custom-control-input:checked
  ~ .custom-control-label::before {
  color: #fff;
  border-color: #f2994a;
  background-color: #f2994a;
}

.student-check-block .custom-control-input {
  width: 149px;
  height: 45px;
}

.student-check-block .custom-control-input:checked ~ .custom-control-label {
  color: #f2994a;
}

a.px-0.register-link-a {
  background: #f2994a;
  color: #fff;
  min-width: 150px;
  min-height: 40px;
  display: flex;
  max-width: 150px;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.student-popup .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #f2994a !important;
}

.custom-control-label::after {
  background-color: #e0e0e0;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  position: absolute;
  top: 0.15625rem;
  left: -1.5rem;
  display: block;
  width: 8px;
  height: 8px;
  content: "";
  background: no-repeat 50% / 50% 50%;
  background-color: #f2994a;
  border-radius: 50%;
  top: 0.45625rem;
  left: -1.25rem;
  background-image: none !important;
}

.custom-radio .custom-control-label::after {
  position: absolute;
  top: 0.15625rem;
  left: -1.5rem;
  display: block;
  width: 8px;
  height: 8px;
  content: "";
  background: no-repeat 50% / 50% 50%;
  background-color: #e0e0e0;
  border-radius: 50%;
  top: 0.45625rem;
  left: -1.25rem;
}

.react-tabs .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #f2994a;
  background-color: transparent;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none !important;
}

.student-popup
  .custom-control-input:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #e0e0e0;
}

.sucess-text {
  font-size: 20px;
}

.sucess-text-profile {
  font-size: 15px;
}

.register-popup .student-popup .react-tabs__tab-list .step1.activestep1:before {
  background: #f2994a;
  border-radius: 6.5px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.register-popup
  .student-popup
  .react-tabs__tab-list
  .step1.activestep1
  ~ .step2.react-tabs__tab--selected:before {
  background: #f2994a;
  border-radius: 6.5px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.register-popup .student-popup .react-tabs__tab-list .step2.activestep2:before {
  background: #f2994a;
  border-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.register-popup
  .student-popup
  .react-tabs__tab-list
  .step2.activestep2
  ~ .step3.react-tabs__tab--selected:before {
  background: #f2994a;
  border-radius: 6.5px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.register-popup .student-popup .react-tabs__tab-list .step3.activestep3:before {
  background: #f2994a;
  border-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.register-popup
  .student-popup
  .react-tabs__tab-list
  .step3.activestep3
  ~ .step4.react-tabs__tab--selected:before {
  background: #f2994a;
  border-radius: 6.5px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.student-check-block .custom-radio .custom-control-label::after {
  background-color: transparent;
}

.student-check-block
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::after {
  background-color: transparent;
}

/* register end*/

.modal-heading {
  font-size: 30px;
  color: #676767;
  padding-bottom: 8px;
}

.no-icon.btn.btn-link:before {
  content: " ";
}

/****************************/

.filter-btn a.nav-link {
  background: #b80d48;
  font-family: inherit;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff !important;

  /* text-shadow: 0px 0px 5px #e6e6e6; */
  margin-bottom: -35px;
  padding: 10px 30px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
}
.filter-btn a.nav-link .fa {
  font-size: 12px;
  font-weight: normal !important;
  line-height: 12px;
  margin-right: 10px;
}
.student-check-block ~ h6 {
  float: left;
  width: 100%;
  margin-top: 10px;
  margin-left: 10px;
}

.modal_header img.uni-icon {
  cursor: pointer;
}

/*23-6-2020*/

.btn.btn-link {
  font-family: "Open Sans";
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  /* display: flex; */
  /* align-items: center; */
  text-align: center;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #333333;
  text-shadow: 0px 0px 5px #e6e6e6;
}

button.signup-btn.btn {
  background: #f2f2f2;
  border-radius: 2px;
  min-width: 115px;
  min-height: 43px;
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #444444;
  border: none;
}

.register-popup .react-tabs select.form-control.activedrop {
  color: #333333;
}

.register-link-a.continue-btn {
  max-width: 180px !important;
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  letter-spacing: 1px;
}

.login-button button:hover,
.login-button button:active,
.login-button button:focus {
  background: #2b6a6c;
  box-shadow: none;
  outline: none;
  border: none;
}

#pp-progress1 {
  width: 80%;
}

#pp-progress1 > div {
  background-color: #f7d852;
}

.progress-box-22 .progress {
  background-color: #e6e6e6;
  height: 7.03px;
}

.progress-box-22 .progress-bar {
  background: #2b6a6c;
  /*height: 7.03px;*/
  border-radius: 6.5px;
}

.progress-box-22 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.uni-icon.pr-2 {
  cursor: pointer;
}

ul.left-list.list-group a {
  color: #bdbdbd;
}

ul.left-list.list-group li.active {
  background: transparent;
  color: #2d6163;
  cursor: pointer;
  font-weight: bold;
}

ul.left-list.list-group li {
  cursor: pointer;
}

.score-back.btn.btn-primary {
  border: 1px solid #2d6163;
  box-sizing: border-box;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  background: #fff;
  min-width: 100px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #2d6163;
  justify-content: center;
}

.score-back.btn.btn-primary2 {
  background: #ffffff;
  border: 1px solid #2b6a6c;
  box-sizing: border-box;
  /* box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06); */
  border-radius: 2px;
  /*transform: rotate(-180deg);*/
  width: 122px;
  height: 40px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #2b6a6c;
  /*justify-content: center;*/
}

.score-back.btn.btn-primary:active,
.score-back.btn.btn-primary:focus {
  border: 1px solid #2d6163;
  box-sizing: border-box;
  /* box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06); */
  border-radius: 2px;
  background: #fff;
  min-width: 100px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #2d6163;
  outline: none;
  box-shadow: none;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.form-group #dateofbirth {
  color: #333333;
}

.stud-init-list .content-block {
  padding: 15px 10px;
  background: #ffffff;
}

.prog-list .content-block {
  min-height: 244px;
}

.institute-list .nav-tabs {
  border-bottom: 0px;
}

.content-block .heart-icon {
  float: right;
}

.cont-rgt-grap,
.cont-rgt-contry {
  text-transform: uppercase;
}

.cont-rgt-grap img {
  vertical-align: text-top;
}

img {
  max-width: 100%;
}

/* .init-list-block .stud-det-block {
  margin-bottom: 30px;
  padding-bottom: 20px;
  box-shadow: 0px 5px 15px rgba(68, 68, 68, 0.24);
} */

.init-list-block .table th,
.init-list-block .table td {
  border-top: none;
}

.menu-section .btn-outline-dark {
  border-color: #2f353a !important;
  background: #fff;
}

.nav-tabs .nav-link.btn-outline-dark.active:focus {
  background: #fff;
  color: #2f353a !important;
}

.vertical-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sendMessage .btn-outline-dark {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  margin-top: 18px;
  margin-left: 0em;
  /* identical to box height */
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #333333;
  width: 134.63px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #333333;
  box-sizing: border-box;
  /* Drop Shadow */
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  /*transform: rotate(-180deg);*/
}

/*.inti-btn {
    background: #2f353a !important;
    border-color: #2f353a !important;
}*/

.list-btn-outline button,
.list-btn-outline button:hover,
.list-btn-outline button:focus {
  display: block;
  background: #fff !important;
  color: #2b6a6c !important;
  border-color: #2b6a6c !important;
  margin: 0px 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  min-height: 36px;
  margin-top: 10px;
  min-width: 145px;
  float: left;
  margin-left: 0;
  justify-content: center;
}

.list-btn button,
.list-btn button:hover,
.list-btn button:focus,
.list-btn button:active {
  display: block;
  background: #2b6a6c !important;
  color: #ffffff !important;
  border-color: #2b6a6c !important;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  min-height: 36px;
  margin-top: -35px;
  min-width: 146px;
  justify-content: center;
  /*float: left;*/
}

.list-btn .apply-btn,
.list-btn .apply-btn:hover,
.list-btn .apply-btn:focus,
.list-btn .apply-btn:active {
  background: #4f4f4f !important;
  border-color: #4f4f4f !important;
}

.pgro-search .foe-top-1.foe-subject {
  max-width: 22%;
}

.search-icon {
  background: transparent !important;
  border: none !important;
  padding: 8px 20px;
}

.more-btn button {
  background: #2b6a6c;
  border: none;
  color: #fff;
  padding: 5px 10px;
}

.tab-btn {
  display: inline-block;
  /* vertical-align: middle; */
  /* justify-content: center; */
  min-width: 145px;
  /* float: left; */
}

.det-list:nth-child(even) {
  background: #f2f2f2;
}

.stud-about-page.top-section .content-block {
  padding: 20px 10px;
  background: #ffffff;
  position: absolute !important;
  bottom: -50px;
}

.stud-about-page.top-section {
  position: relative;
  margin-bottom: 100px;
}

.about-section {
  background: #ffffff;
  /*margin-top: 10px;*/
}

.profile-viewus .nav.nav-tabs {
  background: transparent;
  border: 2px solid #d8d8d8;
  box-sizing: border-box;
  min-height: 40px;
  align-items: center;
  border-left: none;
  border-right: none;
  border-top: none;
  float: right;
}

.profile-viewus .nav.nav-tabs.filter-btn {
  float: left;
}

.profile-viewus .tab-content {
  background-color: transparent;
  margin-left: 0px;
  box-shadow: none;
  border: 0;
  margin-top: 0;
}

.profile-viewus table.table th {
  background-color: #f2f2f2;
  border: 1px solid #f2f2f2;
}

.profile-viewus table.table td {
  background-color: #ffffff;
  border: 1px solid #f2f2f2;
}

.profile-viewus table.table {
  border-spacing: 0 7px;
  border-collapse: separate;
}

.profile-viewus .nav-tabs .nav-link.active {
  color: #2f353a;
  background: transparent !important;
  border-color: transparent !important;
  border-bottom: 5px solid #2b6a6c !important;
  border-radius: 0 !important;
  margin-bottom: -5px;
}

.left-cont-heg {
  height: 366px;
  overflow-y: scroll;
}

.fullwidth-banner {
  margin-top: 80px;
}

.about-facility {
  background: #b80d48;
  color: #ffffff;
  padding-top: 20px;
  padding-bottom: 20px;
}

.about-facility h2 {
  font-size: 30px;
  text-transform: uppercase;
  font-family: "Open Sans";
  font-weight: 600;
}
/* .about-facility ul,
.about-facility ol,
.about-facility li {
  list-style: unset !important;
} */

.stud-hub-right {
  background: #e0e0e0;
  padding: 70px !important;
}

.about-stud-left h2,
.about-uni-left h2,
.stud-hub-right h2 {
  font-style: italic;
}

.about-uni-left {
  border-bottom: 1px solid #e9e9e9;
}

/********************************/

.about-facility.stud-contact {
  background: #ffffff;
  color: #212121;
}

.cont-title {
  background: #2b6a6c;
  color: #fff;
  padding: 10px;
}

.contact-details {
  margin: 20px 0;
  border-bottom: 1px solid #dedede;
}

.contact-details .table th {
  padding-bottom: 0px;
}

/*contact us */

.contact-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 42px;
  text-transform: uppercase;
  color: #444444;
}

p.contact-letter {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  color: #444444;
}

.contact-header-title {
  background: #2d6163;
  padding: 5px 10px 2px;
}

.contact-header-title h3 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #ffffff;
  padding: 5px 10px 2px;
}

.campus-name {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 26px;
  color: #444444;
}

.contact-card .card-title {
  font-family: "Open Sans";
  font-size: 12px;
  line-height: 14px;
  text-transform: capitalize;
  color: #444444;
  font-style: normal;
  font-weight: 800;
}

.contact-card.card {
  border: none;
  background: transparent;
}

.contact-card.card .card-body {
  padding: 0px;
}

.contact-card .card-text {
  font-family: "Open Sans";
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
  color: #444444;
}

button.contact-btn-1.btn.btn-primary {
  border: 1px solid #333333;
  box-sizing: border-box;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  min-width: 150px;
  min-height: 40px;
  font-family: "sans-serif";
  font-weight: bold;
  font-size: 10px !important;
  line-height: 12px;
  display: flex;
  font-weight: 800;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #333333;
  justify-content: center;
  background: transparent;
}

/*conact us end */

.hiderow {
  display: none;
}

.seemore_btn {
  margin: auto;
}

/*7-7-2020*/

.stud-init-list .content-block {
  padding: 5px 10px;
  background: #ffffff;
  background: #ffffff;
  min-height: 160px;
}

.foe-ins-container .uni-logo-list .col-md-8 img {
  width: 189px;
  height: 81px;
}

.uni-logo-list .cont-rgt-grap {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 22px;
  color: #444444;
  text-transform: capitalize;
}

.uni-logo-list .cont-rgt-grap1 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 22px;
  color: #444444;
  text-transform: capitalize;
}

.uni-logo-list .cont-rgt-contry {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #444444;
}

/* span#page-numbers button {
  border: none;
  background: transparent;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #4f4f4f;
  outline: none;
  margin-top: -30px;
  margin-bottom: 40px;
} */

.cont-rgt-head {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 22px;
  color: #444444;
  text-transform: capitalize;
}

.cont-rgt-contry {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #444444;
}

.cont-rgt-contry2 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 22px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: capitalize;
  color: #444444;
}

.cont-rgt-contry3 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 150%;
  letter-spacing: 0;
  text-transform: capitalize;
  /*text-transform: uppercase;*/
  color: #4f4f4f;
  margin-top: -25px;
  margin-bottom: 20px;
}

.cont-rgt-contry4 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 150%;
  /* or 15px */
  letter-spacing: 0;
  text-transform: uppercase;
  color: #4f4f4f;
  margin-top: -2em;
}

.cont-rgt-contry2-pos {
  margin-top: 10px;
  margin-bottom: 20px;
}

.cont-rgt-contry2-pos {
  margin-top: 10px;
  margin-bottom: 20px;
}

#pp-comp-name {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 22px;
  color: #444444;
}

#pp-comp-name2 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */
  letter-spacing: 0;
  text-transform: uppercase;
  color: #444444;
}

.cont-rgt-grap-pos {
  margin-bottom: 3em;
}

.cont-rgt-grap {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #444444;
  margin-bottom: 3em;
}

.cont-rgt-grap2 {
  position: absolute;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 22.31px;
  letter-spacing: 0;
  /*text-transform: uppercase;*/
  margin-left: -20px;
  margin-top: 10px;
  padding-left: -20px;
  color: #444444;
  justify-content: left;
  text-align: left;
}

.cont-rgt-grap3 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #444444;
}

.pp-th-title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #444444;
  /*vertical-align: middle;*/
  margin-top: 0em;
}

.pp-th-title2 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */
  letter-spacing: 0;
  color: #2d3032;
}

.icon-align {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 150%;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #4f4f4f;
  width: 40%;
}

.icon-align2 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 150%;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #4f4f4f;
  width: 50%;
  margin-left: 0px;
}

.icon-align3 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 150%;
  /* or 15px */
  width: 40%;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #4f4f4f;
}

.icon-align-pos {
  margin-top: -5em;
}

.pp-logo-pos {
  margin-top: 8px;
}

.pp-descrition {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 11.3px;
  line-height: 16.43px;
  /* or 16px */
  letter-spacing: 0;
  color: #2d3032;
}

.pp-bottom {
  margin-bottom: -15px;
}

.pp-staff-position {
  border-top: 0px;
  margin-left: -25px;
  margin-right: -20px;
  /* margin-right: -10px; */
}

.pp-cert-positon {
  margin-top: -5px;
  margin-bottom: 30px;
}

.pp-detail-star {
  margin-top: 15px;
  margin-left: -15px;
}

.pp-detail-star {
  margin-top: 15px;
  margin-left: -5px;
}

.pp-detail-star2 {
  margin-top: 0px;
  margin-left: 2.2em;
}

.pp-detail-header-box {
  margin-top: -30px;
  margin-left: -25px;
  width: 300px;
}

.pp-detail-top-box {
  margin-top: -30px;
  margin-left: -25px;
  margin-bottom: -65px;
}

.pp-detail-align-flag {
  margin-top: -10px;
  margin-left: -3px;
  margin-right: 2px;
  margin-bottom: 0px;
}

.pp-profile-img-align {
  margin-top: -7px;
  margin-left: -10px;
  margin-right: 0px;
  margin-bottom: 0px;
}

.pp-detail-reviewRate {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 150%;
  /* identical to box height, or 15px */
  letter-spacing: 0;
  text-transform: uppercase;
  color: #4f4f4f;
  margin-top: 1px;
  margin-left: 2.3em;
}

.see-ins-btn {
  min-height: 170px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.see-ins-btn button {
  background: #2d6163;
  border-radius: 2px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #ffffff;
  min-height: 43px;
  min-width: 165px;
  justify-content: center;
  border: none;
  outline: none;
  box-shadow: none !important;
}

.foe-inst-list .top-section {
  width: 95%;
  margin: auto;
}

.uni-logo-list .content-block {
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
}

.border-list {
  padding-bottom: 25px;
  border-bottom: 1px solid #e0e0e0;
}

.init-list-block .table th {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #444444;
}

.init-list-block .table td span {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #000000;
}

.init-list-block .table td {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #000000;
  width: 130px;
}

.foe-about-boxx h6 {
  color: #fd8d00 !important;
  padding-top: 10px;
}

.border-list .content-block .col-md-4 img {
  width: 130px;
  height: 60px;
  max-width: 130px;
}

.top-section.stud-about-page .content-block {
  padding: 20px 10px;
  background: #ffffff;
  position: absolute !important;
  bottom: -50px;
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  min-height: 200px;
  max-width: 550px;
  min-height: 200px;
}

.border-bottom-line {
  border-bottom: 1px solid rgba(68, 68, 68, 0.17);
  width: 100%;
  float: left;
  padding-bottom: 0px;
  margin-bottom: 4em;
}

.profile-viewus .border-bottom-line .nav.nav-tabs {
  border: none;
  height: 34px;
}

.profile-viewus .border-bottom-line .nav-tabs .nav-link.active {
  /*margin-bottom: -9px;*/
  font-family: "Open Sans";
}

/* .border-bottom-line .filter-btn.nav-tabs a.nav-link {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0;
  text-transform: capitalize;
  text-shadow: none;
  color: #ffffff;
  margin-bottom: -35px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 2px;
} */

/* .border-bottom-line .nav-tabs a.nav-link {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #444444;
  text-shadow: none;
  position: relative;
} */

.profile-viewus .border-bottom-line.uni-about .nav-tabs li.nav-link.active {
  color: #2f353a;
  background: transparent !important;
  border-color: transparent !important;
  border-bottom: none !important;
  border-radius: 0 !important;
  margin-bottom: 0px;
}

.border-bottom-line .nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: transparent !important;
}

.profile-viewus .nav-tabs a.nav-link.active:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 5px;
  background: #b80d48;
  bottom: -5px;
  left: 0;
}

.backarrow {
  font-family: "Open Sans";
  font-weight: 500;
  font-size: 12px;
  line-height: 10px;
  display: flex;
  align-items: center;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #444444;
}

.backarrow2 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 136.4%;
  /* or 16px */
  display: flex;
  align-items: center;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #323c47;
}

.about-section h1.about-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  text-transform: uppercase;
  color: #444444;
}

h4.uni-pro-h4 {
  font-family: "Open Sans";
  font-weight: 800 !important;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #323c47;
}

.filter-radio-btn button.btn.drop-left,
.filter-radio-btn button.btn.drop-left:hover,
.filter-radio-btn button.btn.drop-left:active,
.filter-radio-btn button.btn.drop-left:focus {
  font-family: "Open Sans";
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #6fcf97;
  background: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  margin-top: 10px;
  position: relative;
  width: 100%;
  text-align: left;
  box-shadow: none !important;
  outline: none !important;
}

.filter-radio-btn {
  border-left: 4px solid #6fcf97;
  padding-bottom: 20px;
}

.filter-radio-btn {
  background: #ffffff;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 3px;
}

.filter-radio-btn button.drop-left:before {
  position: absolute;
  top: 10px;
  transition: 0.5s ease-in-out;
  position: absolute;
  right: 12px;
  content: "\f0d7";
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 18px;
}

.filter-radio-btn ul li {
  padding: 10px;
  border-radius: 2px;
  margin-bottom: 10px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 3px;
}

.filter-radio-btn ul {
  padding-left: 0;
  margin: 10px;
}

.collapse .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #6fcf97;
  background-color: #6fcf97;
}

.filter-radio-btn .custom-radio .custom-control-label::after {
  background-color: transparent;
}

.filter-radio-btn
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::after {
  position: absolute;
  top: 0.15625rem;
  left: -1.5rem;
  display: block;
  width: 8px;
  height: 8px;
  content: "";
  background: no-repeat 50% / 50% 50%;
  background-color: #ffffff;
  border-radius: 50%;
  top: 7px;
  left: -20px;
  background-image: none !important;
}

.purple-box button.btn {
  color: #cf6f8a !important;
}

.filter-radio-btn.purple-box {
  border-left: 4px solid #cf6f8a;
}

label.card-label {
  font-family: "Open Sans";
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #323c47;
  width: 100%;
}

.filter-radio-btn select.form-control {
  border: 1px solid #e9eff4;
  border-radius: 4px !important;
  background: transparent;
  min-height: 43px;
  outline: none;
  box-shadow: none;
}

.unimenu a.active {
  border-bottom: 6px solid #2b6a6c;
  padding-bottom: 11px;
  font-weight: bold;
}

.unimenu a {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #4f4f4f;
  padding: 10px 15px;
  margin: 25px;
  margin-left: 0;
  color: #2b6a6c;
  cursor: pointer;
}

.unimenu-1 {
  border-bottom: 1px solid #dedede;
  text-align: left;
  padding-bottom: 10px;
}

.profile-viewus .about-right-block table.table td {
  background-color: transparent;
  border: none;
  text-align: left;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #000000;
}

.profile-viewus .about-right-block table.table th {
  background-color: transparent;
  border: none;
  text-align: left;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #444444;
}

.profile-viewus .about-right-block .table td span {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0;
  text-transform: uppercase;
  font-family: "Open Sans";
  color: #000000;
}

a.btn.viewm-btn {
  background: #ffffff;
  border: 1px solid #2b6a6c;
  box-sizing: border-box;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #2b6a6c;
  min-height: 40px;
  min-width: 90px;
  max-width: 105px;
  justify-content: center;
  float: left;
  outline: none;
  margin-top: -10px;
  margin-right: 0px;
  padding: 0px;
}

a.btn.like_btn.tbl-btn {
  outline: none;
  margin: 0;
  margin-top: -15px;
  padding: 0px;
  position: relative;
  left: 8px;
  float: left;
}

.profile-viewus .about-right-block table.table td table td {
  max-width: 60px;
}

.profile-viewus .about-right-block table.table tr.allrow:nth-child(even) {
  background: #f2f2f2;
}

button.px-4.float-md.save-btn.btn.btn-primary.seemore_btn {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #444444;
  text-align: center;
  display: flex;
  background: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.col-md-12.about-section.mx-0.px-0.row {
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
}

.top-section.stud-about-page .img-block img {
  width: 100%;
  min-height: 455px;
  max-height: 455px;
  object-fit: cover;
}

.foe-breabcrumb .breadcrumb {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #4f4f4f;
  background: transparent;
  border: none;
  margin-bottom: 10px;
}

.foe-breabcrumb .breadcrumb a {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #4f4f4f;
  background: transparent;
  border: none;
  margin-bottom: 10px;
}

.breadcrumb img.uni-icon {
  width: 20px;
  margin-top: -10px;
}

.about-align {
  margin-top: 5.5em;
}

ul.nav.nav-tabs.float-right.tab-btn-new .btn,
ul.nav.nav-tabs.float-right.tab-btn-new .btn:active,
ul.nav.nav-tabs.float-right.tab-btn-new .btn:focus,
ul.nav.nav-tabs.float-right.tab-btn-new .btn:hover {
  background: #fff;
  color: #2f353a;
  outline: none;
  box-shadow: none;
  font-family: "Open Sans" !important;
  border: 1px solid #2f353a !important;
  font-size: 12px;
  width: auto !important;
}

ul.nav.nav-tabs.float-right.tab-btn-new .btn.active {
  background: #2f353a !important;
  color: #fff !important;
  background-color: #fff;
  color: #2f353a;
  outline: none;
  border: 1px solid #2f353a;
}

.campus-boxx {
  background: #2b6a6c;
  padding: 10px;
  color: #fff;
  text-align: center;
  min-height: 350px;
  /* display: flex; */
  /* flex-direction: row; */
}

.campus-boxx p {
  width: 65%;
  margin: auto;
  font-weight: 500;
  font-size: 14px;
  line-height: 136.9%;
  text-align: center;
  letter-spacing: 0;
  color: #ffffff;
}

.campus-boxx h1.about-title {
  color: #fff;
  padding-top: 1em;
}

.campus-boxx .backarrow img {
  filter: invert(1);
}

.program-heading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #444444;
}

.prog-head-box {
  padding-top: 30px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 30px;
  margin-bottom: 20px;
}

.prog-sub-head {
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #444444;
  padding-bottom: 20px;
}

.prog-text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0;
  color: #2d3032;
}

.pro-detail-box {
  padding: 25px;
  background: #f2f2f2f2;
  min-height: 250px;
  margin-bottom: 30px;
  border-radius: 6px;
}

span.pro-left-text {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 150%;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #000000;
  width: 30%;
  float: left;
}

.pro-option {
  width: 100%;
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

span.pro-detail-num {
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 150%;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #000000;
}

.left-cont-box .uni-left-card.card {
  background: #ffffff;
  border-radius: 3px;
  border: none;
  min-height: 310px;
  border-top: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 3px;
  border-right: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  margin-top: -22px;
}

button.btn-check.btn.btn-outline-primary,
button.btn-check.btn.btn-outline-primary:active,
button.btn-check.btn.btn-outline-primary:focus {
  background: #2b6a6c;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  min-width: 140px;
  height: 40px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  justify-content: center;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

button.btn-advice.btn.btn-outline-primary,
button.btn-advice.btn.btn-outline-primary:active,
button.btn-advice.btn.btn-outline-primary:focus {
  background: #ffffff;
  border: 1px solid #2b6a6c;
  box-sizing: border-box;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  min-width: 140px;
  height: 40px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #2b6a6c;
  justify-content: center;
  outline: none !important;
  box-shadow: none !important;
}

.col-md-3.prog-jus-end {
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
}

ul.searchdropdown li {
  padding-bottom: 10px;
  background: linear-gradient(45deg, #f29724, #f29724);
  color: #fff;
}

ul.searchdropdown {
  text-align: center;
  padding: 0px;
  margin-top: 10px;
  /* border-radius: 20px; */
}

.foe-top-1.foe-subject span img {
  position: absolute;
  top: 18px;
  right: 10px;
  width: 12px;
}

.foe-program-box .foe-top-1.foe-subject span img {
  right: 25px;
}

.bg-fa {
  background-color: #e4e5e6;
}

.menu-section.bor-menu-box:before {
  content: "";
  border-bottom: 1px solid rgba(68, 68, 68, 0.17);
  position: absolute;
  width: 200%;
  top: 28px;
  z-index: -1;
  left: -50%;
}

.menu-section.bor-menu-box {
  float: left;
  width: 100%;
  position: relative;
  z-index: 2;
}

.top-section.stud-init-list .list-number {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #4f4f4f;
  padding-bottom: 3em;
}

.campus-tabs .about-stud-hub:nth-child(odd) {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}

#Entry1 .about-stud-hub:nth-child(even) {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}

.about-left-block select.form-control {
  background: url(../src/assets/img/picker.png) !important;
  background-repeat: no-repeat !important;
  background-position: 101% -2px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
}

option.boldclass {
  font-weight: bold;
  color: #f2994a;
  background: #f2f2f2;
}

.profile-viewus .about-right-block img,
.profile-viewus .about-left-block img,
.campus-tabs .about-stud-hub img,
.profile-viewus .about-stud-hub img {
  object-fit: cover;
}

.profile-viewus .about-section .fullwidth-banner img {
  width: 100% !important;
  height: 550px !important;
  object-fit: cover;
}

/**draft-application*****/

.uni-right-border {
  min-height: 100% !important;
  border-left: 1px solid #cfcfcf !important;
}

.uni-right-border .list-group li.active {
  color: #005cc8 !important;
}

.active-href {
  width: 133px;
  height: 42px;
  left: 150px;
  top: 181px;
  background: #d7e8fb;
  border-radius: 25px;
}

.active-href li span {
  font-weight: 600 !important;
}

.viewoffer {
  background: #4da1ff;
  color: #fff !important;
  padding: 10px 15px 10px 15px;
  font-size: 10px;
}

.appapproved {
  color: #2ed47a;
  font-weight: 600;
  font-size: 10px;
}

.appterminate {
  color: #d80027;
  font-weight: 600;
  font-size: 10px;
}

.app_days_left {
  color: #d80027;
  font-weight: 600;
  font-size: 10px;
}

.accept-tick-icon {
  margin-top: -5px;
}

.appapproved-btn {
  background: #eafbf2;
  color: #2ed47a;
  font-size: 10px;
  font-weight: 600;
  padding: 10px 0px 10px 0px;
  border-radius: 5px;
}

.letter-offer-icon {
  margin-top: -3px;
}

.list-group-item {
  background-color: transparent !important;
}

.inactive-href {
  color: #000 !important;
  padding-bottom: 1rem !important;
}

.app_icon {
  max-width: 20px;
  margin-top: -3px;
}

.app_icon_fonts {
  font-size: 12px;
}

.uni-right-border .list-group li {
  width: 130px;
  text-align: right;
  line-height: 23px;
  padding: 0px 20px;
  margin-top: 20px;
}

.program-width {
  width: 200px;
}

.del-icon {
  width: 135px;
}

.draft-table .card-label {
  font-size: 7px;
  letter-spacing: unset;
}

.makepayment-three .personal-box-inner {
  border: 1px solid #dedede;
  padding: 1.25rem 3rem;
}

.makepayment-three .custom-control-input {
  position: relative;
  top: 0;
  left: -9px;
  margin-left: 0;
}

.makepayment-three .custom-control-label::before {
  border: #109cf1 solid 1px;
}

.makepayment-three .custom-radio .custom-control-label::after {
  background-color: #109cf1;
}

#main #faq .card {
  margin-bottom: 0px;
  border: 0;
}

#main #faq .card .card-header {
  border: 0;
  -webkit-box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
  box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
  padding: 0;
  background-color: #fbfcfd;
}

.sendapp-inprogress #main .accordion .card .card-header .btn-header-link {
  color: #fff;
  text-align: left;
  color: #222;
  width: 100%;
  background: #fbfcfd !important;
  min-height: 57px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #323c47;
}

.view-application-one
  #main
  .accordion#faq
  .card
  .card-header
  .btn-header-link:after {
  content: "";
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAFCAYAAACXU8ZrAAAACXBIW…cPAawtqnpgJxRmTjFGfF1EdL9gaq3tH/LQUPLgF4A557G/P1UkJDcgoVJXAAAAAElFTkSuQmCC) !important;
  background-repeat: no-repeat !important;
  background-position: 100% !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  min-height: 43px !important;
  background-size: 11px !important;
  outline: none !important;
  box-shadow: none !important;
  width: 16px;
}

#main #faq .card .card-header .btn-header-link:after {
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  float: right;
}

#main #faq .card .card-header .btn-header-link.collapsed {
  background: #e4e5e6;
  color: #323c47;
  width: 100%;
}

#main #faq .card .card-header .btn-header-link.collapsed:after {
  content: "\f106";
}

#main #faq .card .collapsing {
  /*background: #ffe472;*/
  background: #ffffff;
  line-height: 30px;
}

#main #faq .card .collapse {
  border: 0;
}

#main #faq .card .collapse.show {
  background: #ffffff;
  line-height: 30px;
  color: #222;
}

.view-application-one .col-12.pt-3.Clearfix.view-prof-detail {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 250%;
  color: #333;
  text-align: left;
}

/* .uni-left-card.card.app-inprogress {
  border-left: 0 !important;
} */

.app-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  color: #444444;
  padding-top: 5px;
}

.send-app-page h3.app-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 29px;
  display: flex;
  align-items: center;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #444444;
  padding-top: 5px;
}

.send-app-page .right-btn-align.search-icon {
  padding-top: 0px;
  padding-bottom: 18px;
}

.send-app-page select.form-control {
  background: url(../src/assets/img/picker.png) !important;
  background-repeat: no-repeat !important;
  background-position: 100% 0px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff !important;
  min-height: 34px;
  background-size: 34px !important;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 136.4%;
  /* or 16px */
  display: flex;
  align-items: center;
  letter-spacing: 0;
  color: #4f4f4f;
  mix-blend-mode: normal;
}

.send-app-page select.form-control2 {
  background: url(../src/assets/img/picker.png) !important;
  background-repeat: no-repeat !important;
  background-position: 100% 0px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #f2f2f2 !important;
  min-height: 45px;
  background-size: 45px !important;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 136.4%;
  /* or 16px */
  display: flex;
  align-items: center;
  letter-spacing: 0;
  color: #4f4f4f;
  mix-blend-mode: normal;
}

.send-app-page select.form-control2 {
  background: url(../src/assets/img/picker.png) !important;
  background-repeat: no-repeat !important;
  background-position: 100% 0px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #f2f2f2 !important;
  min-height: 45px;
  background-size: 45px !important;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 136.4%;
  /* or 16px */
  display: flex;
  align-items: center;
  letter-spacing: 0;
  color: #4f4f4f;
  mix-blend-mode: normal;
}

.form-control2 option {
  background-color: #fff !important;
}

.send-app-page .uni-left-card li.list-group-item a {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 50px;
  color: #bdbdbd;
  border: none;
  text-decoration: none !important;
}

.send-app-page .uni-left-card li.list-group-item,
.profile-dash .uni-left-card li.list-group-item {
  border: none;
}

.send-app-page .uni-left-card li.list-group-item a.active,
.profile-dash .uni-left-card li.list-group-item a.active {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 50px;
  /* or 357% */
  color: #2b6a6c;
}

.app-sub-heading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  /* or 135% */
  text-transform: capitalize;
  /* Gray 2 */
  color: #4f4f4f;
}

/* .view-app-detail .view-app-left {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 250%;
  color: #4f4f4f;
  text-align: left;
  flex: 0 0 27%;
  max-width: 27%;
}
.view-app-detail .view-app-right {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 250%;
  color: #4f4f4f;
  display: flex;
  flex-direction: row;
} */

.view-app-right-font12 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 250%;
  color: #4f4f4f;
  display: flex;
  flex-direction: row;
  margin-bottom: 0px !important;
}

.view-app-right-font14 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 250%;
  color: #4f4f4f;
  display: flex;
  flex-direction: row;
  margin-bottom: 0px !important;
}

.view-app-right-font10 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 200;
  font-size: 10px;
  color: #4f4f4f;
  display: flex;
  flex-direction: row;
  margin-top: 0px !important;
}

.send-app-page
  #main
  .accordion#faq
  .card
  .card-header
  .btn-header-link.collapsed-default:after {
  content: "";
  background: url(../src/assets/img/drop-icon.png) !important;
  background-repeat: no-repeat !important;
  background-position: 100% !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  min-height: 43px !important;
  background-size: 11px !important;
  outline: none !important;
  box-shadow: none !important;
  width: 16px;
  transform: rotate(180deg);
}

.send-app-page
  #main
  .accordion#faq
  .card
  .card-header
  .btn-header-link.collapsed:after {
  content: "";
  background: url(../src/assets/img/drop-icon.png) !important;
  background-repeat: no-repeat !important;
  background-position: 100% !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  min-height: 43px !important;
  background-size: 11px !important;
  outline: none !important;
  box-shadow: none !important;
  width: 16px;
  transform: rotate(360deg);
  margin-right: 5px;
}

.send-app-page #main .accordion#faq .card .card-header .btn-header-link:after {
  content: "";
  background: url(../src/assets/img/drop-icon.png) !important;
  background-repeat: no-repeat !important;
  background-position: 100% !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  min-height: 43px !important;
  background-size: 11px !important;
  outline: none !important;
  box-shadow: none !important;
  width: 16px;
  transform: rotate(180deg);
}

.send-app-page #main .accordion .card .card-header .btn-header-link {
  color: #fff;
  text-align: left;
  color: #222;
  width: 100%;
  background: #fbfcfd !important;
  min-height: 57px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #323c47;
  border-block-color: #e0e0e0;
}

.register-popup.student-popup-box.filter-popup {
  max-width: 650px;
  margin: 0;
}

.filter-clear.d-flex {
  background: #2d6163;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  min-height: 119px;
  align-items: center;
  padding-right: 25px;
  margin-bottom: 20px;
}

.filter-clear2.d-flex {
  background: #2d6163;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  min-height: 116px;
  align-items: center;
  padding-right: 25px;
  margin-bottom: 20px;
}

.register-popup.student-popup-box.filter-popup .modal-body {
  padding: 0px;
}

.register-popup.student-popup-box.filter-popup .modal-body form {
  width: 100%;
  margin: auto;
}

.register-popup.student-popup-box.filter-popup .modal-content {
  padding: 0px;
}

.filter-clear.d-flex a {
  color: #fff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
}

.filter-group {
  width: 100%;
}

.filter-group-box {
  width: 80%;
  margin: auto;
  padding-top: 30px;
  padding-bottom: 30px;
}

.filter-group label.uni-label {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #333333;
}

h2.filter-subheading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #444444;
  margin-bottom: 0px;
  padding-top: 10px;
}

.flter-shop .filter-radio-btn {
  border-left: 4px solid #6fcf97;
  padding-bottom: 20px;
}

.flter-shop .filter-radio-btn .collapse {
  padding: 0px 15px;
}

.filter-group-box .filter-radio-btn select.form-control {
  background: url(../src/assets/img/drop-icon.png) !important;
  background-repeat: no-repeat !important;
  background-position: 97% !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  min-height: 43px !important;
  background-size: 11px !important;
  outline: none !important;
  box-shadow: none !important;
  background: #ffffff;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 3px;
}

.check-name input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 16px;
  height: 16px;
  left: 0;
  top: 0;
  z-index: 1;
}

.check-name {
  width: 20px;
  margin: auto;
  background: transparent;
  height: 20px;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  float: left;
  position: relative;
}

.check-name .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border-radius: 50%;
  right: 0;
  bottom: 0;
  margin: auto;
  /* box-shadow: 0px 10px 10px #494949; */
  /* box-shadow: 0 10px 10px 0 rgba(0,0,0,.1); */
}

.check-name input:checked ~ .checkmark:after {
  display: block;
}

.check-name .checkmark:after {
  top: 3px;
  left: 3px;
  width: 10px;
  height: 10px;
  border-radius: 4px;
  background: #4da1ff;
}

.flter-shop .check-name .checkmark:after {
  top: 0px;
  left: 3px;
  width: 8px;
  height: 12px;
  border-radius: 0px;
  background: transparent;
  border-top: 2px solid #6fcf97;
  border-left: 2px solid #6fcf97;
  transform: rotate(-141deg);
}

.check-name .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.filter-bottom h2 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #333333;
  text-shadow: 0px 0px 5px #e6e6e6;
}

.filter-bottom .float-right {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.filter-bottom button.score-save.beforesave.reset.btn {
  background: #fff !important;
  color: #444;
  border: 1px solid #444;
  margin-right: 15px;
}

.filter-bottom button.score-save.beforesave {
  margin-top: 10px;
}

.seemore-link {
  float: right;
}

.pp-review-orderby {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 326.5%;
  /* or 36px */
  color: #323c47;
}

.pp-review-orderby-link {
  margin-right: 3em;
}

.pp-review-link {
  float: right;
  margin-right: 3.2em;
}

.card-section {
  padding: 20px;
  border: 1px solid #dedede;
  border-radius: 12px;
  margin-top: 20px;
}

.send-icon img {
  border: 1px solid #20a8d8;
  margin-right: 15px;
  padding: 8px;
  border-radius: 3px;
}

.card-section.active {
  background: #48cbf9;
}

.cal-filter a {
  padding: 10px;
  margin: 0 5px;
}

.cal-filter a.active {
  color: #fff !important;
  background: #20a8d8;
  border-radius: 20px;
  padding: 6px 25px;
  cursor: pointer;
}

.i-style {
  text-align: center;
  color: #f2994a;
  font-size: 12px;
}

.i-style span {
  border: 1px solid;
  padding: 3px 10px;
  font-size: 16px;
  font-weight: bold;
}

.req-feild {
  background: #f2994a;
  transform: rotate(-90deg);
  padding: 0 10px !important;
  width: 82px !important;
  line-height: 58px;
  color: #ffffff;
  font-weight: 600;
  display: inline-block;
}

.greyfield {
  background: #adadad;
}

.app-feedback-page .table td {
  border-top: 0;
}

.app-feedback-page .table tr {
  height: 100px;
}

.sucess-pop .sucess-head {
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 23px;
  line-height: 36px;
}

.sucess-pop .sucess-text {
  font-family: "Open Sans";
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
}

.sucess-pop button.d-offer.btn.btn-primary {
  min-width: 100px !important;
  font-size: 10px !important;
  background-color: #2b6a6c !important;
  font-weight: bold !important;
  min-height: 40px !important;
  color: #ffffff !important;
}

.sucess-pop button.profile-success.btn.btn-primary {
  min-width: 100px !important;
  font-size: 10px !important;
  background-color: #2b6a6c !important;
  font-weight: bold !important;
  min-height: 40px !important;
  color: #ffffff !important;
  width: 169px;
  height: 40px;
}

button.appdeclined.btn,
button.apprejected.btn {
  background: #ff4f4f !important;
  border-radius: 2px;
  min-width: 76px;
  min-height: 34px;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #ffffff;
  justify-content: center;
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
}

p.appdeclined {
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #ff4f4f;
}

button.newapppaid.btn.btn-secondary {
  border: 1px solid #2b6a6c;
  border-radius: 4px;
  background: #fff;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  min-width: 100px;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #2b6a6c;
  mix-blend-mode: normal;
  box-shadow: none !important;
  outline: none !important;
}

button.newappdeclined.btn.btn-secondary {
  border: 1px solid #ff4f4f;
  border-radius: 4px;
  background: #fff;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  min-width: 100px;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #ff4f4f;
  mix-blend-mode: normal;
  box-shadow: none !important;
  outline: none !important;
}

.uni-label-table {
  font-style: normal;
  font-weight: 800;
  font-size: 10px;
  line-height: 9px;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #bdbdbd;
  mix-blend-mode: normal;
}

.tab-pane .list-card.uni-no-padd.add-more-box li.list-group-item {
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 12px;
}

.apppersonalinfo .list-card.uni-no-padd.add-more-box {
  border: none;
}

.application-form select.form-control.multiple-select {
  background: transparent !important;
}

.card-flex {
  flex: 1 1 auto;
  padding: 10px 0px 10px 0px;
}

.svg-table-icon {
  width: 20px;
  height: 20px;
}

.pr-10 {
  padding-right: 10px;
}

.color-orange {
  color: orange;
}

.tab-col-width {
  width: 150px;
}

.filter-btn a.nav-link.filter.active {
  color: #ffffff;
  background: #2d6163;
}

.destination-box
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
  margin-left: -29.5em;
}

.inputContainer div {
  overflow: hidden;
}

.inputContainer input {
  width: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}

.inputContainer {
  width: 300px;
  border: 1px dashed #f0f;
}

.inputContainer label {
  float: left;
  margin-right: 5px;
  background: #ccc;
}

.input-w label,
.input-w input {
  float: none;
  /* if you had floats before? otherwise inline-block will behave differently */
  display: inline-block;
  vertical-align: middle;
}

.select-app-profile .react-datepicker__header {
  background: #ffffff;
}

.select-app-profile button {
  font-size: 12px;
  min-width: 18px !important;
}

.select-app-profile .react-datepicker-wrapper input {
  width: 100px !important;
  text-align: center;
  border-radius: 3px !important;
}

.total-app select {
  font-size: 11px;
  padding: 0;
}

.profile-dashboard .right-dash-align h5.app-sub-heading {
  justify-content: left !important;
}

.profile-dashboard .right-dash-align select {
  cursor: pointer;
}

.send-app-page.send-app-2 .uni-left-card li.list-group-item {
  font-size: 14px !important;
  text-align: left !important;
  /*padding: 0.75rem 1.25rem !important; */
  padding: 0.75rem 1rem !important;
  max-width: none !important;
  font-weight: normal !important;
  line-height: unset !important;
}

/* .send-app-page.send-app-2 .uni-left-card li.list-group-item.active {
  font-weight: bold !important;
} */

.stud-left.uni-right-border {
  border-left: 0px solid #cfcfcf !important;
}

.stud-left.uni-right-border .list-group li.active {
  padding-right: 17px;
}

.message-box .table td {
  vertical-align: middle;
  border-top: 1px solid #c8ced3;
  font-size: 12px;
}

.message-box .table td:first-child {
  border-left: 1px solid #c8ced3;
}

.message-box .table td:last-child {
  border-right: 1px solid #c8ced3;
}

.message-box .table td {
  border-bottom: 1px solid #c8ced3;
}

.font-10 {
  font-size: 10px !important;
}

.font-12 {
  font-size: 12px !important;
}

.unread-text {
  font-weight: bold;
  font-style: italic;
}

.inbox-new-higlit {
  background-color: #fd2a2a !important;
  border-color: #fd2a2a !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  min-height: 33px;
  max-height: 33px;
  padding: 0 !important;
  font-size: 12px !important;
  font-weight: bold !important;
  text-transform: uppercase;
  width: 33px;
}

.width-100px {
  width: 100px;
}

.width-150px {
  width: 150px;
}

.request_fld_inbox {
  font-size: 10px !important;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
}

.chat_bot {
  position: absolute;
  bottom: 0;
}

.starred-mail button.btn.btn-primary,
.composemail-head button.btn.btn-primary {
  background: #4da1ff;
  border: 1px solid rgba(77, 161, 255, 0.7);
  border-radius: 2px;
  font-family: "Open Sans";
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #ffffff;
}

.outbox-mail .progress {
  margin-top: 10px;
  height: 8px;
}

.input-group-prepend {
  background-color: transparent !important;
  border: none !important;
}

.composemail-body .input-group-text,
.composemail-body .form-control,
.composemail-body .card {
  background: transparent !important;
  border: none !important;
}

.composemail-body .input-group-text {
  font-family: "Open Sans";
  font-size: 10px;
  line-height: 14px;
}

.input-group .to-address {
  font-weight: 800;
  font-size: 13px;
}

.composemail-body .form-control:focus {
  box-shadow: none;
}

.composemail-body #studentmailid {
  font-weight: 800;
  font-size: 12px;
  color: #b9b9b9;
}

.composemail-body {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.09);
}

.composemail-body .align-middle {
  font-size: 13px;
  font-family: "Open Sans";
  color: #b9b9b9;
}

.composemail-body .align-middle.black {
  color: #000000;
}

.composemail-body #messageText {
  font-family: "Open Sans";
  font-size: 13px;
  min-height: 150px;
}

.view-message {
  background: #f0f3f5;
}

.reply-message.table-responsive.composemail-body {
  background: #ffffff;
}

.view-message .text-right.card-header,
.view-message .card-footer {
  background: transparent;
  border: 0;
}

.inbox-page .table td:first-child {
  border-left: 1px solid #c8ced3;
}

.inbox-page .table td {
  vertical-align: middle;
  border-top: 1px solid #c8ced3;
  font-size: 12px;
}

.inbox-page .table td {
  border-bottom: 1px solid #c8ced3;
}

.inbox-page .table td:last-child {
  border-right: 1px solid #c8ced3;
}

.uni-drag-drop .remove-link {
  word-break: break-word;
}

.upload-box .form-drop-option {
  align-self: flex-end !important;
}

.upload-box label {
  text-transform: none;
}

/* custom file */

.customfile-file-input {
  position: relative;
  display: inline-block;
  border-radius: 8px;
  border: #ebebeb solid 0px;
  width: auto;
  padding: 4px 6px 4px 8px;
  font: normal 14px Myriad Pro, Verdana, Geneva, sans-serif;
  color: #7f7f7f;
  margin-top: 2px;
  background: white;
}

.customfile-file-input input[type="file"] {
  -webkit-appearance: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  opacity: 0;
}

.s-application-list li.list-group-item {
  line-height: 135% !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-top: 20px !important;
}

.s-application-list.send-app-2 .uni-left-card li.list-group-item {
  line-height: 135% !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  /*margin-top: 20px !important;*/
  margin-top: 12px !important;
}

.react-datepicker__day--in-range,
.react-datepicker__day--selected {
  background-color: rgba(77, 161, 255, 0.1) !important;
  color: #000000 !important;
}

*:focus {
  outline: none !important;
}

.payment-table-sec table td {
  padding: 20px 10px !important;
  font-size: 11px !important;
  vertical-align: middle !important;
  text-align: center;
  min-width: 92px;
  line-height: 18px !important;
}

.weight-600 {
  font-weight: 600 !important;
}

.payment-table-sec .add-staff-bn,
.payment-table-sec .add-staff-bn:hover {
  background: #2b6a6c;
}

.react-confirm-alert-button-group > button:nth-child(1) {
  background: #5dc2f1 !important;
  color: #fff;
}

.react-confirm-alert-button-group > button:nth-child(2) {
  background: #c1c1c1 !important;
  color: #fff;
}

.font-10 {
  font-size: 10px !important;
  font-family: "Open Sans";
}

.font-14px {
  font-size: 14px !important;
  font-family: "Open Sans";
}

.font-26 {
  font-size: 26px !important;
  font-family: "Open Sans";
}

/* .uni-left-card.card {
  border-left: 3px solid #2d6163 !important;
} */

.profile-dashboard .right-dash-align h5.picker-box {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 14px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #323c47;
}

.picker-api-box .edit-btn {
  border-radius: 20px;
}

.profile-dashboard .react-datepicker .react-datepicker__day-name {
  color: #bdbdbd;
}

.react-datepicker__day,
.react-datepicker__day--in-range,
.react-datepicker__day--selected {
  color: #868686;
}

.css-2b097c-container {
  width: 90%;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 3px;
}

.send-app-page.send-app-2 .uni-left-card .custome-files li {
  font-size: 11px !important;
  float: left !important;
}

.send-app-page.send-app-2 .uni-left-card .custome-files li .file-name {
  width: 138px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

ul.list-group.custome-files {
  display: block;
}

.custome-files .custome-btn-del {
  border: none;
}

.css-yk16xz-control {
  border-width: 0px !important;
  margin-left: 15px;
}

.mail-re-farword {
  overflow-y: scroll;
  height: 500px;
}

.mailPagination button {
  margin: 30px 20px 0px 20px;
  font-size: 12px;
  background: #a9a9a9;
  color: #fff;
  border: none;
  padding: 3px 8px;
  border-radius: 3px;
}

.mailPagination button:hover,
.mailPagination button:active {
  background: #469bf5;
}

.mailPagination {
  width: 300px;
}

a.custumelisting-files {
  padding: 10px;
  background: rgb(235, 235, 235);
  width: 2%;
  color: rgb(58, 58, 58);
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 5px;
}

.prefered-list-items-right img {
  height: 15px;
}

.langflag img {
  width: 40px !important;
  height: auto !important;
  margin-bottom: 5px;
  border-radius: 50%;
}

.grn-outline-btn {
  border-color: #2d6163 !important;
  color: #2d6163 !important;
  background-color: #ffffff !important;
}

.prefered-list-items-right .grn-btn {
  background-color: #2d6163 !important;
  color: #ffffff !important;
  border: none;
}

.list-up-detail {
  border-bottom: 1px solid #f2f2f2;
}

.pref-list-rating img {
  height: 10px !important;
}

.prefered-list-items-right .country img {
  height: 25px !important;
}

.prefered-list-items-right .heart-icon img {
  height: 25px !important;
}

.partner-short-info {
  background-color: #ffffff;
  position: relative;
  width: 502px;
  height: 190px;
  top: -100px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
}

.img-block img {
  width: 100%;
}

.right-no-border {
  border-radius: 25px 0px 0px 25px !important;
}

.left-no-border {
  border-radius: 0px 25px 25px 0px !important;
}

.foe-student-box2 {
  background: url(../src/assets/img/preffered_partner_banner.jpg) !important;
  min-height: 488px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  align-items: center;
  justify-content: center;
  filter: grayscale(60%);
  -webkit-filter: grayscale(60%);
}

.foe-student-box.foe-prefpartner {
  background: url(../src/assets/img/preffered_partner_banner.jpg) !important;
  min-height: 488px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: grayscale(60%);
  -webkit-filter: grayscale(60%);
}

/**agent css**/

.agent-list ul.university-list.list-group {
  margin-left: -15px;
  max-width: 170px;
}

.agent-list ul.university-list.list-group li {
  max-width: 170px;
  display: flex;
  justify-content: flex-end;
}

.agent-list ul.university-list.list-group li a {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: flex-end;
  text-align: right;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #323c47;
  padding-right: 15px;
}

.agent-list .card-list-header.card-header {
  display: flex;
  justify-content: flex-end;
}

.agent-list .card-list-header.card-header .card-list-head {
  padding-right: 15px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.agent-list .card-list-header.card-header .card-list-head .add {
  margin-right: 0px;
}

.agent-list a.btn.btn-primary.campus-btn.mt-3 {
  background: #4da1ff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
  min-height: 46px;
  margin-left: -15px;
  max-width: 170px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border: none;
  border-radius: 0px;
}

.btn-primary2 {
  background: #ffffff;
  border: 1px solid #333333;
  box-sizing: border-box;
  max-width: 149px;
  max-height: 40px;
  /* Drop Shadow */
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  /*transform: rotate(-180deg);*/
  /*font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #333333;*/
}

.btn-primary3 {
  background: #ffffff;
  border: 1px solid #333333;
  box-sizing: border-box;
  width: 134.63px;
  height: 40px;
  /* Drop Shadow */
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  align-items: center;
  letter-spacing: 0;
  /*transform: rotate(-180deg);*/
}

.btn-primary3-font {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  display: grid;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #333333;
}

.btn-primary2-align {
  vertical-align: top;
}

.ag-bold {
  font-family: "Open Sans" !important;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #444444;
  text-shadow: 0px 0px 5px #e6e6e6;
}

.agent-edit-box:before {
  content: "";
  position: absolute;
  width: 101%;
  height: 100%;
  background: #fafafa;
  transform: rotate(-2deg) translate(-2px, -24px);
  z-index: 0;
}

.agent-edit-box {
  background: transparent;
  width: 100%;
  min-height: 88px;
  position: absolute;
  top: 0;
}

.agent-edit-box a {
  position: relative;
  z-index: 1;
  display: block;
  padding-top: 20px;
  padding-left: 20px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #4da1ff;
  text-shadow: 0px 0px 5px #e6e6e6;
  max-width: 200px;
}

.pref-part-list1 {
  border-top: 0px;
  border-bottom: 0px;
}

.pref-part-list img.parter-img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

#aaa {
  border-top: 0px;
  border-bottom: 0px;
  margin-bottom: 0px;
}

.scharts {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  line-height: 20px;
  color: #444444;
  margin-top: 20px;
  margin-left: 0px;
}

.pp-office-header {
  margin-top: -20px;
}

.pref-part-list .list-group-horizontal .list-group-item {
  text-align: center;
  padding: 0.5rem;
  border: none;
  border-top: 0px;
  border-bottom: 0px;
  margin-bottom: 0px;
}

.pref-part-list2 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 165%;
  color: #4f4f4f;
  /*padding-top: 15px !important;
  padding-bottom: 15px !important;*/
}

.pref-part-list3 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19.8px;
  color: #4f4f4f;
  background-color: #f2f2f2;
  margin-bottom: 0;
  padding: 20px 10px;
}

.pref-part-list4 {
  color: #4f4f4f;
  padding-top: -0px;
  margin-top: 20px;
  margin-bottom: 70px;
}

.pref-part-list5 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19.8px;
  color: #4f4f4f;
  /*padding-bottom: -40px;
    margin-bottom: -40px;*/
}

.pref-part-list5:first-child ul {
  padding-top: 25px !important;
}

.partner-name {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 9px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0;
  text-transform: capitalize;
  margin-top: 10px;
  color: #444444;
}

.pref-part h3 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #444444;
}

.pref-part2 h3 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #444444;
}

.pref-part3 h3 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */
  text-transform: capitalize;
  color: #444444;
}

.pref-part4 h3 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #444444;
  margin: 0;
  /*margin-bottom: 3em;*/
  /* margin-left: -4em; */
}

.review-bottom {
  margin-bottom: 0;
}

.review-bottom2 {
  margin-top: -0.5em;
}

.contact-detail-pos {
  margin-top: -1.2em;
}

.student-statistic-pos {
  margin-top: 3em;
}

.left-cont-heg.agent-content {
  min-height: 50px;
  max-height: 350px;
  height: max-content;
  overflow: hidden;
  overflow-y: auto;
  display: block;
  float: left;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}

.pref-part.service-part .list-group li.list-group-item {
  border: none;
  background: transparent;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: initial;
  color: #4f4f4f;
  position: relative;
  text-transform: none;
}

.pref-part.service-part .list-group {
  background: #f2f2f2;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  padding: 25px;
}

.pref-part.service-part h3 {
  /*  font-family: "Open Sans";*/
  font-style: normal;
  font-weight: bold;
  font-weight: 800;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0;
  color: #4f4f4f;
  text-transform: uppercase;
}

.pref-part.service-part .list-group li.list-group-item:before {
  content: "";
  width: 6px;
  height: 6px;
  background: #4f4f4f;
  position: absolute;
  left: 5px;
  top: 19px;
  border-radius: 100%;
}

.agent-static {
  min-height: 250px;
}

.agent-static h3 {
  font-size: 12px;
  line-height: 14px;
  /* text-align: center; */
  letter-spacing: 0;
  text-transform: uppercase;
  color: #444444;
  padding-bottom: 2em;
  font-family: "Open Sans";
}

.agent-cer-box .btn {
  background: #2b6a6c;
  box-shadow: 1px 1px 12px rgba(144, 168, 190, 0.33);
  border-radius: 50px !important;
  margin-bottom: 15px;
  min-width: 278px;
  min-height: 40px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
}

.pref-part-box select.form-control {
  background: url(../src/assets/img/picker.png) !important;
  background-repeat: no-repeat !important;
  background-position: 101% -2px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  min-height: 43px !important;
  padding-right: 45px;
}

.pref-part-box select.form-control {
  background: url(../src/assets/img/picker.png) !important;
  background-repeat: no-repeat !important;
  background-position: 101% -2px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff !important;
  min-height: 43px !important;
  padding-right: 45px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: 0px;
}

.pref-part-box input.form-control {
  background: url(../src/assets/img/navi-icon.svg) !important;
  background-repeat: no-repeat !important;
  background-position: 2% !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff !important;
  min-height: 43px !important;
  padding-left: 45px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

h4.ref-h4.col-12 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 29px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #4f4f4f;
  padding-bottom: 15px;
}

.pref-part-box-2 input.form-control {
  min-height: 43px !important;
}

.agent-campus {
  background: rgba(242, 242, 242, 0.5);
  padding-top: 2em;
  padding-bottom: 3em !important;
}

.agent-contact {
  background: #ffffff;
  /*background: rgba(242, 242, 242, 0.5);*/
  /*border-top: 1px solid #bdbdbd;*/
  /*border-bottom: 1px solid rgba(68, 68, 68, 0.3);*/
}

.agent-contact2 {
  background: #e5e5e5;
  /*background: rgba(242, 242, 242, 0.5);*/
  /*border-top: 1px solid #bdbdbd;*/
  /*border-bottom: 1px solid rgba(68, 68, 68, 0.3);*/
}

.agent-pr-list h4.media-heading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  /* or 145% */
  text-transform: capitalize;
  color: #4f4f4f;
}

.pp-staff-box .media-heading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  /* or 145% */
  text-transform: capitalize;
  color: #4f4f4f;
}

.agent-pr-list h4.media-heading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  /* or 145% */
  text-transform: capitalize;
  color: #4f4f4f;
}

.agent-pr-list h4.media-heading2 {
  font-family: "Open Sans";
  font-style: bold;
  font-weight: 500;
  font-size: 12.5333px;
  line-height: 21px;
  /* or 145% */
  text-transform: capitalize;
  color: #4f4f4f;
}

.agent-pr-list p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  /* or 145% */
  text-transform: capitalize;
  color: #828282;
}

.agent-pr-list ul.list-group.list-group-horizontal a {
  background: transparent;
  border: none;
  padding: 5px 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height */
  text-transform: capitalize;
  color: #828282;
  padding-top: 0px;
}

img.ag-icon {
  width: 12px !important;
  vertical-align: middle;
  margin-top: -5px;
}

.rating {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}

.rating > input {
  display: none;
}

.rating > label {
  position: relative;
  width: 22px;
  font-size: 22px;
  color: #e3e9ef;
  cursor: pointer;
}

.rating > label::before {
  content: "\2605";
  position: absolute;
  opacity: 0;
}

.rating > label:hover:before,
.rating > label:hover ~ label:before {
  opacity: 1 !important;
}

.rating > input:checked ~ label:before {
  opacity: 1;
  color: #f7d852;
}

.rating:hover > input:checked ~ label:before {
  opacity: 0.4;
}

.review-inner {
  display: flex;
}

.review-inner-2 .progress-box {
  min-width: 170px;
  padding-top: 11px;
}

.progress-box .progress {
  background: #e3e9ef;
  border-radius: 6.5px;
  height: 14px;
}

.progress-box .progress .progress-bar {
  border-radius: 6.5px;
  background: #f7d852;
}

.review-inner-2 {
  float: left;
  width: 100%;
}

.review-inner-2 label.review-number {
  min-width: 90px;
  font-family: "Arial";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  /* Formee Smokey Grey */
  color: #90a8be;
}

.review-inner-2 label.review-avg {
  font-family: "Arial";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */
  display: flex;
  align-items: center;
  /* Formee Smokey Grey */
  color: #90a8be;
}

.review-inner label.review-number {
  font-family: "Arial";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #363b40;
}

.review-inner label.review-avg {
  font-family: "Arial";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */
  display: flex;
  align-items: center;
  /* Formee Dark Grey */
  color: #363b40;
}

.bgColorGrey {
  background-color: #f2f2f2 !important;
}

.star-group.form-group select.form-control {
  background: url(../src/assets/img/drop-icon.png) !important;
  background-repeat: no-repeat !important;
  background-position: 97% !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  min-height: 43px !important;
  background-size: 11px !important;
  outline: none !important;
  box-shadow: none !important;
  width: 95%;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0;
  text-transform: capitalize;
  background: #ffffff;
  /* Drop Shadow Soft */
  box-shadow: 1px 1px 12px rgba(144, 168, 190, 0.33);
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  display: flex;
  align-items: center;
  /* Formee Smokey Grey */
  color: #90a8be;
}

.row.comment-row {
  border-top: 1px solid #90a8be;
}

.review-comments h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;
  color: #90a8be;
  font-family: "Arial";
}

.review-content h4.media-heading {
  font-family: Arial;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 21px;
  color: #192a3e;
}

.review-content .media-body {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #90a8be;
}

.register-link-a.agent-link-1 {
  background-color: #b80d48 !important;
  color: #fff !important;
}

/* .score-grt label,
.score-grt input,
.score-grt .scor-1,
.score-mark-box label,
.score-mark-box input,
.score-mark-box .scor-1,
.student-testscore label,
.student-testscore input {
  color: #4f4f4f !important;
} */

.student-testscore label.uni-label {
  color: #bdbdbd !important;
}

.font-weight-italics {
  font-style: italic;
}

/* Author: Paul Siew (Student PP Review Comment box background) */
.comment-bgcolor {
  background-color: #e2e2e2 !important;
}
/* --- */

.border-transparent {
  border: transparent !important;
}

.login-error-msg {
  position: absolute;
  bottom: -15px;
}

.static-plus::before {
  content: "+";
  position: absolute;
  left: 6px;
  top: 8px;
  font-size: 14px;
  height: 40px;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  color: #333333;
}

.contact-us-session {
  background: rgba(242, 242, 242, 0.5);
  /*background: rgba(242, 242, 242, 0.5);*/
  /*border-top: 1px solid #bdbdbd;*/
  /*border-bottom: 1px solid rgba(68, 68, 68, 0.3);*/
}

.review-section-pp .list-group-item {
  color: #828282 !important;
  font-weight: 700;
}

.span-same-row {
  /*display: inline-block;*/
  display: block;
}

.pp-review-name {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  display: block;
  color: #333333;
}

.pp-review-name2 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /*display: flex;
  align-items: flex-end;
  display:block;*/
  color: #333333;
}

.ver-text-bottom {
  vertical-align: text-bottom;
}

.review-rating-rate {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  /* identical to box height, or 72% */
  display: flex;
  align-items: center;
  display: inline;
  /* Gray 1 */
  color: #333333;
  margin-top: 5em;
}

.review-rating-rate2 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12.5px;
  line-height: 18px;
  /* identical to box height, or 129% */
  display: flex;
  align-items: center;
  display: inline;
  color: #333333;
  margin-top: 10em;
}

.review-rating-rate3 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #333333;
  /*margin-top: 10em;*/
}

.review-rating-rate4 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height, or 129% */
  display: flex;
  align-items: center;
  color: #333333;
  /*margin-top: 10em;*/
}

.black-outline {
  border-color: #000000 !important;
  background-color: #ffffff !important;
  color: #000000 !important;
}

/* .leave-review {
  background-color: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
} */

.review-subactor {
  padding: 14px;
}

.pref-part.service-part .list-group li.list-group-item:before {
  left: -4px;
  top: 9px;
}

.pref-part.service-part .list-group {
  padding: 0 !important;
  padding-left: 25px !important;
}

.pref-part.service-part .list-group li.list-group-item {
  padding: 5px 10px 5px 10px;
}

.pref-part-list:first-child ul {
  padding-top: 15px !important;
}

.agent-static h3 {
  font-size: 12px;
  line-height: 14px;
  /* text-align: center; */
  letter-spacing: 0;
  text-transform: uppercase;
  color: #444444;
  padding-bottom: 2em;
  font-family: "Open Sans";
  font-weight: 700;
}

.pref-part .card {
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border: transparent;
  max-width: 31.36%;
}

#Contactus1 {
  border: 0px;
  margin-left: 0px;
}

.pref-part-list .list-group-horizontal .list-group-item img {
  text-align: center;
  margin: 0 auto;
  /*margin-right: 0px;*/
}

.pref-part-list .list-group-horizontal .list-group-item p.partner-name {
  display: block;
}

.pref-part-list .list-group-horizontal .list-group-item {
  width: 92px;
  padding: 0.2rem;
}

.pref-part-list.service-list-space:first-child ul {
  padding-top: 15px !important;
}

.pref-part-list.service-list-space:last-child ul {
  padding-bottom: 15px !important;
}

.service-list-space-position {
  margin-top: 25px;
}

.uni-right-card.letter-offer h3.app-title {
  text-align: right;
  float: right;
}

.address p {
  margin-bottom: 2px;
}

.two-block-sec {
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  padding-top: 20px;
  padding-bottom: 20px;
}

.uni-right-card.letter-offer {
  font-size: 12px;
}

.list-items label {
  font-weight: 600;
}

.text-left h6,
.text-left h4 {
  font-weight: 600;
}

.chart-inner .list-group-item {
  border: 0;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  /* or 146% */
  text-transform: capitalize;
  margin-left: -18px;
  color: #444444;
}

.student-chart {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 136.4%;
  /* or 14px */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #444444;
  margin-top: -10px;
  margin-left: 75px;
}

.agent-cer-box .btn {
  box-shadow: 1px 1px 12px rgba(144, 168, 190, 0.33);
  border-radius: 50px !important;
  margin-bottom: 15px;
  min-width: 278px;
  min-height: 40px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  border-color: #2b6a6c;
  /*margin-bottom: 0px;*/
}

.tab-content .btn-group .btn {
  border: 1px solid #2b6a6c;
  text-align: left !important;
  display: unset;
}

.btn-light.green-border {
  border: 1px solid #2b6a6c !important;
  background: transparent !important;
  font-size: 12px !important;
  text-transform: uppercase;
  color: #2b6a6c !important;
  font-weight: 600;
}

.tab-content .agent-cer-box .btn-group {
  display: block;
  text-align: center;
  /*margin-bottom: 20px;*/
}

.pref-part .pref-part-list .list-group-horizontal .list-group-item {
  padding-left: 35px;
  font-size: 12px;
  line-height: normal;
}

.pref-part-list img.parter-img {
  width: auto;
  height: auto;
  border-radius: unset;
}

/* mail editor css */

/* don't remove this code */

.textalleditable {
  font-family: sans-serif;
  width: auto;
  min-height: 100px;
  border: 1px dashed #aaa;
  padding: 15px;
  resize: none;
}

.textalleditable p {
  margin: auto;
}

/* end mail editor css */

/* .draft-app-page .uni-left-card.card {
  border-left: 1px solid #cfcfcf !important;
} */

.dest-cntry select.form-control {
  padding-bottom: 9px;
  padding-right: 30px;
}

.login-error-msg {
  position: relative !important;
  bottom: 8px !important;
  font-size: 13px;
}

.hand-cursor {
  cursor: pointer;
}

.right-dash-align .react-datepicker__month-container {
  margin-left: 5px;
}

.right-dash-align .react-datepicker__navigation--next {
  margin-right: 66px;
  right: -70px !important;
}

.chat_bot {
  display: none;
}

.right-dash-align .react-datepicker__navigation--previous {
  margin-left: -20px;
  left: 15px !important;
}

.foe-subject .form-control:focus::placeholder {
  color: transparent;
}

.cursor-issue {
  left: 20px !important;
}

.css-14jk2my-container {
  width: 90%;
}

/* .right-dash-align .react-datepicker__day,
.profile-dashboard .react-datepicker .react-datepicker__day-name {
  width: 2.77rem !important;
} */

.right-dash-align .react-datepicker__header {
  background-color: transparent !important;
}

*:focus {
  box-shadow: none !important;
}

.upload-prof-btn {
  background: #4da1ff;
  border: 1px solid rgba(77, 161, 255, 0.7);
  border-radius: 2px;
  font-family: "Open Sans";
  font-weight: 500;
  font-size: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  line-height: 14px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #ffffff;
}

.media.active {
  border: 1px solid #20a8d8 !important;
}

.addviewer select.form-control {
  background: url(../src/assets/img/picker.png);
  background-repeat: no-repeat !important;
  background-position: 101% -2px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-repeat: no-repeat !important;
  background-position: 101% -2px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  min-height: 43px !important;
  padding-right: 45px;
}

.addviewer label.card-label {
  font-family: "Open Sans";
  letter-spacing: normal;
  align-items: normal;
  width: 100%;
}

/* .custom-selecting {
  position: relative;
  font-family: Arial;
}

.custom-selecting select {
  display: none; /*hide original SELECT element:
}

.select-selected {
  background-color: DodgerBlue;
}

/*style the arrow inside the select element:
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/*style the items (options), including the selected item:
.select-items div,.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
}

/*style items (options):
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/*hide the items when the select box is closed:
.select-hide {
  display: none;
}

.select-items div:hover, .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
} */

.draft-padding-align {
  width: 135px !important;
}

.navbar-brand-full {
  min-width: 249px;
}

.w-30 {
  width: 30% !important;
}

.send-app-page.send-app-2 .uni-left-card li.list-group-item {
  line-height: 135% !important;
}

.padding-right-35px {
  padding-right: 35px !important;
}

.start-fix {
  padding-left: 2.12rem !important;
}

.d-md-down-none:nth-child(1) {
  border-right: 1px solid rgba(68, 68, 68, 0.17);
}

.d-md-down-none:nth-child(6) {
  border-left: 1px solid rgba(68, 68, 68, 0.17);
}

.margin-top-10 {
  margin-top: 10px !important;
}

.select-wrapper .form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.select-wrapper {
  position: relative;
}

.select-wrapper5 .form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.select-wrapper5 {
  position: relative;
}

.select-wrapper5 select {
  background: url(../src/assets/img/picker.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 100% 0%;
}

.dropdown-toggle::after {
  content: none;
}

.form-group select,
select,
.select-wrapper select {
  background: url(../src/assets/img/picker.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 100% 0%;
}

/*.select-wrapper:after {
  font-family: FontAwesome;
  content: "\f107";
  font-size: 27px;
  position: absolute;
  top: 0px;
  right: 10px;
  color: #9d9d9e;
  pointer-events: none;
}*/

.profile-student .disablepro::placeholder {
  color: #bdbdbd;
}

.profile-student input::placeholder {
  color: #bdbdbd;
}

.pp-textbox input::placeholder {
  color: #bdbdbd;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 136.4%;
}

.pp-textbox select::placeholder {
  color: #bdbdbd;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 136.4%;
}

.alignLeft {
  margin-left: -1.1em;
}

.alignLeft2 {
  margin-left: -0.2em;
}

.alignRight {
  margin-left: 15px;
}

.link-custom {
  width: 48px;
  height: 14px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  display: flex;
  /*display: flex;
  align-items: flex-end;*/
  /*text-align: center;*/
  color: #2a8bf2;
  cursor: pointer;
}

.profile-student .pr-header {
  margin-top: 15px;
}

.blue-addmore {
  background: #4da1ff !important;
  border: 1px solid rgba(77, 161, 255, 0.7) !important;
  border-radius: 2px;
  font-family: "Open Sans" !important;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  color: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  border: none;
  display: flex;
  align-items: center;
  text-align: center;
  min-height: 40px;
  min-width: 95px;
  justify-content: center;
}

.w-20 {
  width: 20% !important;
}

.profile-student .nav-tabs .nav-link {
  margin-left: 0px;
  width: 100%;
  justify-content: center;
  white-space: nowrap;
  margin-right: 0px;
}

.number-arrow-disable::-webkit-outer-spin-button,
.number-arrow-disable::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.upload-doc-title {
  font-style: normal !important;
  font-weight: 800 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  display: flex !important;
  align-items: flex-end !important;
  letter-spacing: 0 !important;
  text-transform: uppercase !important;
  color: #323c47 !important;
  padding-bottom: 10px !important;
  font-family: "Open Sans";
}

.react-datepicker__day--selected:before {
  content: ".";
  position: relative;
  top: -9px;
  right: -23px;
  font-size: 28px;
  line-height: 0px;
  color: blue;
}

.addviewer-btn a.px-0.register-link-a {
  background: #f2994a;
  color: #fff;
  min-width: 120px;
  max-width: 120px;
}

.addviewer-btn a.register-link-a:first-child,
.addviewer-btn a.register-link-a:first-child:hover {
  margin-right: 0;
  border: 1px solid #2b6a6c;
  color: #2b6a6c;
  font-weight: 600;
  text-transform: uppercase;
}

.addviewer-btn a.register-link-a:last-child,
.addviewer-btn a.register-link-a:last-child:hover,
.remove-viewer {
  margin-left: 15px;
  border: 1px solid #f7706f;
  color: #f7706f;
  font-weight: 600;
  text-transform: uppercase;
}

.sucess-pop .sucess-text span {
  font-size: 20px;
  font-weight: 600;
}

.sucess-pop .sucess-text p {
  padding-top: 20px;
}

.drop-field .app-re-btn {
  max-width: 150px;
}

.border-transparent {
  border: transparent !important;
}

.view-modal-border-left {
  border-left: 1px solid #d8d8d8;
}

.cmnt-head {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 250%;
  color: #4f4f4f;
  text-align: left;
}

.cmt-drop:focus {
  outline: none;
}

.cmt-drop {
  min-width: 30px;
  border: transparent !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #bdbdbd;
  font-size: 12px;
  line-height: 9px;
  font-weight: 800;
}

.chat-body {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 250%;
  color: #545454;
}

.pop-headicon {
  width: 20px !important;
  height: 100%;
  margin-right: 15px;
}

.view-application-tab .view-applcn-icon.min-chat-icon.small-charticon {
  height: auto !important;
}

.createpop .commant-post.btn,
.viewpopup .commant-reply.btn {
  background: #4da1ff !important;
  border: 1px solid rgba(77, 161, 255, 0.7) !important;
  border-radius: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #ffffff;
  justify-content: center;
  min-height: 30px;
  max-width: 75px;
}

.min-height-50px {
  min-height: 50px !important;
}

.createpop .commant-cancel.btn,
.viewpopup .commant-cancel.btn {
  background: #e0e0e0 !important;
  border-radius: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #a19e9e;
  justify-content: center;
  min-height: 30px;
  max-width: 75px;
}

.createpop .modal-header,
.viewpopup .modal-header {
  display: none !important;
}

.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}

.react-confirm-alert-button-group {
  margin-left: 34%;
}

.react-confirm-alert-body {
  text-align: center !important;
}

.react-confirm-alert-button-group {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.react-confirm-alert-button-group > button:nth-child(1) {
  background: #5dc2f1 !important;
  color: #fff;
}

.react-confirm-alert-button-group > button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

.react-confirm-alert-button-group > button:nth-child(2) {
  background: #c1c1c1 !important;
  color: #fff;
}

.flex-row-dir {
  flex-direction: row !important;
}

.schedule-section .uni-right-card.addviewer {
  background: #ffffff;
}

.chat-date {
  font-weight: 800;
  font-size: 12px;
  color: #b9b9b9;
}

.viewpopup .card-title,
.viewpopup .card-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px !important;
  line-height: 14px;
  letter-spacing: 0;
  color: #444444;
}

.select-wrapper-1:after {
  font-family: FontAwesome;
  content: "\f107";
  font-size: 15px;
  position: absolute;
  top: 2px;
  right: 21px;
  color: #bdbdbd;
  pointer-events: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.drop-field .app-re-btn {
  max-width: 150px;
}

.border-transparent {
  border: transparent !important;
}

.view-modal-border-left {
  border-left: 1px solid #d8d8d8;
}

.cmnt-head {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 250%;
  color: #4f4f4f;
  text-align: left;
}

.cmt-drop:focus {
  outline: none;
}

.cmt-drop {
  min-width: 30px;
  border: transparent !important;
  -webkit-appearance: none;
  appearance: none;
  color: #bdbdbd;
  font-size: 12px;
  line-height: 20px;
  font-weight: 800;
  height: 20px;
  background-color: transparent;
}
/*
.select-wrapper:after {
  font-family: FontAwesome;
  content: "\f107";
  font-size: 27px;
  position: absolute;
  top: 0px;
  font-weight: bold;
  right: 10px;
  color: #bdbdbd;
  pointer-events: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}*/

.chat-body {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 250%;
  color: #545454;
}

.pop-headicon {
  width: 26px !important;
  height: 100%;
  margin-right: 34px;
  padding-top: 5px;
}

ul.chatcommantlist {
  height: 600px;
  overflow-y: scroll;
}

.view-application-tab .view-applcn-icon.min-chat-icon.small-charticon {
  height: auto !important;
}

.createpop .commant-post.btn,
.viewpopup .commant-reply.btn {
  background: #4da1ff !important;
  border: 1px solid rgba(77, 161, 255, 0.7) !important;
  border-radius: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #ffffff;
  justify-content: center;
  min-height: 30px;
  max-width: 75px;
}

.min-height-50px {
  min-height: 50px !important;
}

.createpop .commant-cancel.btn,
.viewpopup .commant-cancel.btn {
  background: #e0e0e0 !important;
  border-radius: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #a19e9e;
  justify-content: center;
  min-height: 30px;
  max-width: 75px;
}

.createpop .modal-header,
.viewpopup .modal-header {
  display: none !important;
}

.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}

.react-confirm-alert-button-group {
  margin-left: 34%;
}

.react-confirm-alert-body {
  text-align: center !important;
}

.react-confirm-alert-button-group {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.react-confirm-alert-button-group > button:nth-child(1) {
  background: #5dc2f1 !important;
  color: #fff;
}

.react-confirm-alert-button-group > button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

.react-confirm-alert-button-group > button:nth-child(2) {
  background: #c1c1c1 !important;
  color: #fff;
}

.flex-row-dir {
  flex-direction: row !important;
}

.schedule-section .uni-right-card.addviewer {
  background: #ffffff;
}

.chat-date {
  font-weight: 800;
  font-size: 12px;
  color: #b9b9b9;
}

.viewpop .modal_header {
  border-bottom: 1px solid #dedede;
}

.viewpop .modal-body {
  background: #ffffff;
  border-radius: 5px;
}

.viewpop .modal-header {
  border-bottom: 0;
  background: transparent !important;
}

.viewpop .modal-content {
  background: transparent;
  border: 0;
}

.chat-box:hover img.chatgrayhead {
  display: none;
}

.chat-box:hover img.chathead {
  display: block;
}

img.chathead {
  display: none;
}

.viewpop button.close span {
  background: #ffffff !important;
  padding: 14px 21px;
  border-radius: 100%;
}

.viewpop button.close {
  opacity: 1;
  margin-bottom: 0px;
}

.feedback-acc .card-header {
  border-top: 1px solid #dedede !important;
}

.view-application-one.view-application-page {
  padding-left: 70px;
}

.viewpopup .card-title,
.viewpopup .card-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px !important;
  line-height: 14px;
  letter-spacing: 0;
  color: #444444;
}

.select-wrapper-1:after {
  font-family: FontAwesome;
  content: "\f107";
  font-size: 15px;
  position: absolute;
  top: 2px;
  right: 21px;
  color: #bdbdbd;
  pointer-events: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.view-application-one img.view-applcn-icon.min-chat-icon {
  width: 26px;
  padding-top: 5px;
}

.view-app-detail .btn-header-link {
  text-align: left !important;
}

#main #faq .card .card-header .btn-header-link.collapsed {
  background: transparent !important;
  color: #323c47;
  text-align: left;
}

.view-application-one #main .accordion .card .card-header .btn-header-link {
  color: #fff;
  text-align: left;
  color: #222;
  width: 100%;
  background: #fbfcfd !important;
  min-height: 57px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #323c47;
}

.view-application-one
  #main
  .accordion#faq
  .card
  .card-header
  .btn-header-link:after {
  content: "";
  background: url(../src/assets/img/drop-icon.png) !important;
  background-repeat: no-repeat !important;
  background-position: 100% !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  min-height: 43px !important;
  background-size: 11px !important;
  outline: none !important;
  box-shadow: none !important;
  width: 16px;
}

.app-re-btn {
  border: 1px solid #e9eff4;
  border-radius: 4px;
  min-height: 43px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 136.4%;
  display: flex;
  align-items: center;
  letter-spacing: 0;
  color: #4f4f4f;
  mix-blend-mode: normal;
  padding: 0px 10px;
}

.complete-box button.score-save.btn {
  background: transparent !important;
  border-radius: 2px;
  border: 1px solid #f29724;
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #f29724;
  min-height: 40px;
  min-width: 95px;
  justify-content: center;
}

.pay-detail #main #faq .card .card-header .btn-header-link {
  background-color: #ffffff !important;
}

.pay-detail #main #faq .card .card-header {
  box-shadow: none;
}

.pay-detail
  .send-app-page
  #main
  .accordion
  .card
  .card-header
  .btn-header-link {
  background-color: #ffffff !important;
}

.pay-detail label {
  font-size: 10px;
  line-height: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0;
  font-family: "Open Sans";
}

.checkbox-pay {
  text-transform: none !important;
  font-size: 12px !important;
  line-height: 136% !important;
  letter-spacing: 0;
  color: #454545;
  vertical-align: middle;
}

.w-85 {
  width: 85% !important;
}

.mail-student tr:hover {
  background: linear-gradient(
    180deg,
    rgba(205, 205, 205, 0.26) 0%,
    rgba(255, 255, 255, 0) 16.15%,
    #f4f4f4 100%
  );
}

.mail-student tr td:nth-child(2),
.mail-student tr td:nth-child(4) {
  border-right: 1px solid #efefef;
}

.mail-student tr td:nth-child(1) img {
  margin-left: 11px;
}

.mail-student tr td:nth-child(2) {
  font-size: 10pt !important;
  font-weight: bold;
  font-style: italics;
}

.viewpop button.close span {
  background: #ffffff !important;
  padding: 14px 21px;
  border-radius: 100%;
}

.viewpop .modal-header {
  border-bottom: 0;
  background: transparent !important;
}

button.add-staff-bn.btn.btn-primary,
button.add-staff-bn.btn.btn-outline-primary {
  background: #4da1ff !important;
  border: 1px solid rgba(77, 161, 255, 0.7) !important;
  border-radius: 2px;
  min-width: 130px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #ffffff;
  justify-content: center;
  min-height: 30px;
}
button.add-staff-bn.btn.btn-outline-primary {
  background: #fff !important;
  border: 1px solid #4da1ff !important;
  color: #4da1ff !important;
  margin-bottom: 8px !important;
}
button.add-staff-bn.btn.btn-primary:hover,
button.add-staff-bn.btn.btn-primary:focus,
button.add-staff-bn.btn.btn-outline-primary:hover,
button.add-staff-bn.btn.btn-outline-primary:focus {
  background-color: #444 !important;
  border-color: #444 !important;
  color: #fff !important;
}

button.make-payment-bn.btn.btn-primary {
  background: #fff !important;
  border: 1px solid #4da1ff !important;
  border-radius: 2px;
  min-width: 76px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #4da1ff;
  justify-content: center;
  min-height: 34px;
  padding-bottom: 9px;
}

.chat-box:hover {
  background-color: #e0e0e0;
}

.box-border {
  border-radius: 3px !important;
}

.blue-outline {
  border-color: #4da1ff !important;
  background-color: #ffffff !important;
  color: #4da1ff !important;
}

.blue-bg {
  background-color: #4da1ff !important;
}

.font-8pt {
  font-size: 8pt !important;
}

.font-10pt {
  font-size: 10pt !important;
  font-family: "Montserrat" !important;
  font-weight: bold !important;
}

.font-10-pt {
  font-size: 10pt !important;
}

.font-24pt {
  font-size: 24pt !;
}

.pad-2rem {
  padding: 0.5rem 2rem !important;
}

.header-pref ol {
  font-size: 10pt !important;
  padding-left: 0px !important;
}

.header-pref2 ol {
  font-size: 10pt !important;
  padding-left: 0px !important;
  margin-bottom: -20px;
}

.header-pref-position {
  /*margin-top: -20px;*/
  margin-bottom: 0px;
}

.pp-img-position {
  /*margin-top: -20px;*/
  margin-top: 30px;
}

.white-bg .btn-danger {
  background-color: #ff6d4a !important;
}

.dash-square {
  width: 35px !important;
  height: 35px !important;
}

.show-by-drop {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #f2f2f2 !important;
  font-size: 12px;
  line-height: 14px;
  color: #444444;
}

.select-wrapper2:after {
  font-family: FontAwesome;
  content: "\f107";
  font-size: 27px;
  position: absolute;
  top: -3px;
  right: 10px;
  color: #444444;
  pointer-events: none;
}

.left-dash-align .accordion#faq .btn-header-link {
  position: relative;
}

.left-dash-align .accordion#faq .btn-header-link:after {
  content: "";
  background: url(../src/assets/img/drop-icon.png) !important;
  background-repeat: no-repeat !important;
  background-position: 100% !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  min-height: 43px !important;
  background-size: 11px !important;
  outline: none !important;
  box-shadow: none !important;
  width: 16px;
  position: absolute;
  top: 0px;
  right: 0px;
}

.left-dash-align .accordion#faq .btn-header-link.collapsed:after {
  content: "";
  background: url(../src/assets/img/drop-icon.png) !important;
  background-repeat: no-repeat !important;
  background-position: 100% !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  min-height: 43px !important;
  background-size: 11px !important;
  outline: none !important;
  box-shadow: none !important;
  width: 16px;
  transform: rotate(180deg);
  position: absolute;
  top: 0px;
  right: 0px;
}

.head-account {
  font-size: 11px;
  color: #4da1ff !important;
  text-decoration: none;
}

.two_charslength {
  font-family: monospace;
  width: 2ch;
  overflow: hidden;
  white-space: nowrap;
  text-transform: uppercase;
}

.largepro {
  font-size: 1.2em;
}

img {
  max-width: 100%;
}

.mailer-table {
  align-items: center;
  border-bottom: 1px solid #dedede;
  padding-bottom: 15px;
  padding-top: 15px;
}

.logo-block img {
  width: 100px;
}

.btn-mailer .save-btn.btn.btn-outline-info {
  min-width: 104px !important;
}

a.see-more-link u {
  color: #3399ff;
  font-size: 12px;
}

.table-section {
  background: #ffffff;
  padding: 20px 0px;
}

.btn-mailer .save-btn.btn.btn-outline-info:hover {
  color: #ffffff;
}

.addviewer .partner-info img,
.addviewer .addvier-info img {
  height: 50px;
  width: 145px;
  object-fit: contain;
}

.addviewer .partner-info .card-label {
  font-size: 8px;
  font-weight: bold;
  letter-spacing: 0;
  color: #bdbdbd;
}

.blue-btn-aadd-view {
  background: #4da1ff !important;
  border: 1px solid rgba(77, 161, 255, 0.7) !important;
  border-radius: 2px !important;
  font-family: "Open Sans";
  font-weight: 500 !important;
  font-size: 10px !important;
  line-height: 14px !important;
  align-items: flex-end;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #ffffff;
}

.addviewer .blue-outline {
  border-color: #4da1ff !important;
  background-color: #ffffff !important;
  color: #4da1ff !important;
  border-radius: 2px;
  font-family: "Open Sans";
  font-weight: 500 !important;
  font-size: 10px !important;
  line-height: 14px !important;
  align-items: flex-end;
  letter-spacing: 0;
  text-transform: capitalize;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.addviewer .addvier-info .content-block {
  font-size: 12px;
}

.date-picker-grey {
  background: url(../src/assets/img/calendar.svg) !important;
  background-repeat: no-repeat !important;
  background-position: 96% 8px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #e7e7ea !important;
  height: 45px;
}

.btn-blue-clr {
  background-color: #4da1ff !important;
  color: #ffffff !important;
}

.student-popup .schedule-section .uni-right-card {
  max-width: 100%;
  box-shadow: none;
}

.view-applcn-icon.min-chat-icon.small-charticon {
  display: none;
}

.countcommant .badge-secondary {
  position: absolute;
  z-index: 99;
  right: 25px;
}

.common-header-chat .countcommant {
  right: 7px;
}

.view-app-right:hover .view-applcn-icon.min-chat-icon.small-charticon {
  display: block;
}

.card-body.doc-app:hover .view-applcn-icon.min-chat-icon.small-charticon {
  display: block;
}

.border-line-design {
  background: #b80d48;
  padding: 5px;
  padding-left: 7px;
  color: #fff;
  width: 30px;
  height: 30px;
  z-index: 99;
  border-radius: 30px;
}

.cmnt-head .badge-secondary {
  position: absolute;
  z-index: 99;
  right: 16px;
  top: -4px;
}

.doc-app a.custumelisting-files {
  background-color: #ffffff;
}

.doc-app .view-app-file img {
  min-height: 145px;
}

.doc-app .view-app-file {
  border: 2px solid #cfcfcf !important;
  border-radius: 3px;
}

.width-150 {
  width: 150px !important;
}

.font-weight-500-light {
  font-weight: 500 !important;
  font-size: 36px !important;
  font-family: "Open Sans light" !important;
}

.profile-student .nav-item .selected,
.profile-student .nav-item .active {
  font-weight: bolder !important;
  font-family: "Open Sans" !important;
  color: #000000;
  font-size: 12px !important;
}

.payment-table-sec table th {
  padding: 10px 10px !important;
  font-size: 11px !important;
  vertical-align: middle !important;
  text-align: center;
  min-width: 92px;
  line-height: 18px !important;
}

/*calendar-alignment*/

.calendar-col .DayPicker-Day {
  font-size: 11px;
  font-weight: 900;
  padding: 9px;
  border: 15px solid #f2f2f2;
  border-bottom: 10px solid #f2f2f2;
  color: #999999 !important;
}

.DayPicker-Day--selected {
  background-image: url(../src/assets/img/bluetag.svg) !important;
  background-color: rgba(77, 161, 255, 0.1) !important;
  background-repeat: no-repeat !important;
  background-position: 89% 3px !important;
  border-radius: 10px !important;
}

.DayPicker-Caption {
  margin-bottom: 18px !important;
  font-weight: bold !important;
  text-transform: uppercase;
  text-align: center !important;
}

.DayPicker-Caption > div {
  font-weight: bold !important;
}

.DayPicker-NavBar {
  padding-top: 0px;
}

.DayPicker-Months {
  margin: -5px !important;
}

.DayPicker-Month {
  margin: 0px !important;
}

.shadowbox {
  box-shadow: 0px 0px 10px rgba(189, 189, 189, 0.3);
}

.btn-clr-blue {
  color: #ffffff !important;
  background-color: #4da1ff !important;
  border-radius: 2px !important;
  border: 1px solid #4da1ff !important;
}

.btn-otln-blue {
  color: #4da1ff !important;
  background-color: #ffffff !important;
  border-radius: 2px !important;
  border: 1px solid #4da1ff !important;
  font-size: 10px;
}

.btn-orange {
  font-family: "quicksand";
  background-color: #ff6d4a !important;
  border: 1px solid #ff6d4a !important;
  font-size: 9px !important;
  font-weight: 500 !important;
  padding: 8px 11px !important;
}

.inst-drop select {
  font-weight: 10px !important;
}

.head-country {
  font-size: 11px;
  color: #000000 !important;
  text-decoration: none;
}

.checkbox-pay {
  font-family: "Open Sans" !important;
  font-weight: 900 !important;
}

.font-OpenSans {
  font-family: "Open Sans" !important;
}

.error-msg-fix {
  position: absolute;
}

.favrite-header {
  font-size: 14px;
  text-transform: uppercase;
  font-family: "Open Sans";
}

.notification-box h3.app-title {
  font-family: "Open Sans";
}

.background-white {
  background-color: #ffffff !important;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
}

.review-title {
  font-family: "Open Sans" !important;
}

.align-to-center {
  text-align: center;
}

.box-shadow {
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06) !important;
}

.score-name .form-control:-webkit-outer-spin-button,
.score-name .form-control:-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  opacity: 1;
}

.width-50px {
  width: 50px;
}

.profile-student .form-control:disabled {
  color: #bdbdbd !important;
}

.profile-student input:disabled {
  color: #bdbdbd !important;
}

.profile-dashboard .right-dash-align select {
  color: #a5a5a5;
  text-align-last: center;
}

.font-16px {
  font-size: 16px !important;
}

.pp-contact-us {
  /*position: absolute;*/
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  /* or 21px */
  /* Gray 1 */
  color: #333333;
  margin-top: -35px;
  margin-left: 18px;
  margin-bottom: 35px;
}

.pp-contact-us-loc {
  background: #2d6163;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height */
  letter-spacing: 0;
  text-transform: uppercase;
  color: #ffffff;
  padding-top: 8px;
  padding-left: 25px;
  padding-bottom: 7px;
  margin-top: -10px;
}

.pp-contact-us-title {
  /*position: absolute;*/
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 26px;
  /* or 137% */
  color: #444444;
  margin-top: 10px;
  margin-left: 15px;
  margin-bottom: -13px;
}

.sendmessage-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  /*display: flex;*/
  align-items: center;
  /*margin-top: -2em;*/
  color: #333333;
}

.sendmessage-row {
  margin-top: 20px;
}

.contact-us-hr {
  margin-bottom: -30px;
  width: 963px;
}

.pp-contact-us-title2 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-transform: capitalize;
  color: #444444;
}

#pp-header-sub {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-transform: capitalize;
  color: #444444;
}

.pp-contact-us-title3 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 163.4%;
  /* identical to box height, or 20px */
  color: rgba(68, 68, 68, 0.5);
  mix-blend-mode: normal;
  margin-top: -0.9em;
}

#pp-contact-us-title4 {
  /*position: absolute;*/
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* or 133% */
  color: #444444;
  margin-top: -0.7em;
}

#pp-contact-us-title5 {
  /*position: absolute;*/
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 163.4%;
  /* identical to box height, or 20px */
  color: rgba(68, 68, 68, 0.5);
  mix-blend-mode: normal;
  margin-top: -0.6em;
  margin-bottom: 2.5em;
}

.review-section {
  /*border-bottom: 1px solid rgba(68, 68, 68, 0.3);*/
  box-shadow: 0px 3px 18px rgba(0, 0, 0, 0.1) !important;
  /*padding-bottom: 10px;*/
}

.review-section-pp .progress {
  height: 0.75rem;
  border-radius: 16px;
  margin-top: 5px;
}

.prog-search {
  border-top: transparent !important;
  border-left: transparent !important;
  border-right: transparent !important;
  border-radius: 0px !important;
  width: 150px !important;
}

.prog-srch-icon {
  width: 20px;
  margin-bottom: 20px;
}

.prof-header {
  min-width: 55px;
  max-width: 55px;
  min-height: 76px;
  line-height: 10px;
}

.prof-header-flag {
  min-width: 55px;
  max-width: 55px;
  line-height: 10px;
}

.collapse .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #6fcf97;
  background-color: #6fcf97;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-position: center 6px;
  background-position-x: 6px;
}

.custom-checkbox .custom-control-input ~ .custom-control-label::after {
  background-image: url(../src/assets/img/tick-check.svg);
  background-position: center 6px;
  background-position-x: 6px;
}

.custom-checkbox .custom-control-label::before {
  background-color: #cdcdcd;
}

.custom-control-label::before {
  position: absolute;
  top: 0.15625rem;
  left: -1.5rem;
  display: block;
  border-radius: 25px !important;
  width: 1.25rem !important;
  height: 1.25rem !important;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: transparent !important;
}

.max-600px {
  max-width: 600px !important;
}

.cncl-btn-snd.addviewer-btn a.register-link-a:last-child,
.cncl-btn-snd.addviewer-btn a.register-link-a:last-child:hover,
.remove-viewer {
  border: transparent !important;
}

.cncl-btn-snd.addviewer-btn a.px-0.register-link-a {
  min-width: 90px;
  border-radius: 2px;
}

.contact-btn-1 {
  width: 155px;
}

.box-shadow-high {
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.1) !important;
}

.about-right-block a.btn.viewm-btn {
  font-family: "sans-serif";
}

.btn-blu-clr {
  background-color: #4da1ff !important;
  color: #ffffff;
  font-size: 12px !important;
  border: #4da1ff !important;
}

.password-change .uni-label {
  color: #4f4f4f;
}

.password-change .form-control::placeholder {
  color: #bdbdbd;
}

/* .saveSearchFont-header {
  font-size: 15px !important;
  font-family: "Open Sans";
  color: #4f4f4f;
  font-weight: 700;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
} */

/* .saveSearchFont {
  font-size: 12px !important;
  font-family: "Open Sans";
  color: #333333;
  font-weight: 500;
} */

.flex2 {
  display: flex;
  justify-content: center;
}

.modal-saveSearch {
  height: auto;
  max-width: 620px;
  margin: auto;
  background-color: #f2f2f2;
}

.searchContainer {
  position: absolute;
  bottom: 0;
  margin: 5px 5px 5px 5px;
  width: 100%;
  height: 30px;
}

.searchContainer {
  position: absolute;
  bottom: 0;
  margin: 5px 5px 5px 5px;
  width: 100%;
  height: 30px;
  padding-top: 15px;
  text-align: left;
  margin-left: 5px;
}

.pp-sub-heading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #444444;
}

.insBottom {
  padding-top: 0px;
  padding-bottom: 20px;
}

.insBottom2 {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.insBottom3 {
  padding-top: 12px;
  padding-bottom: 8px;
}

.insBottom4 {
  padding-top: -25px;
  padding-bottom: -10px;
}

.spanLeft {
  display: block;
  float: left;
  width: 150px;
  margin-left: -8px;
}

.spanLeft2 {
  display: block;
  float: left;
  width: 150px;
  text-align: left;
  justify-content: left;
  margin-left: -40px;
  margin-top: -5px;
}

.spanLeft3 {
  display: block;
  float: left;
  width: 150px;
  text-align: left;
  justify-content: left;
  margin-left: -38px;
  margin-top: -45px;
}

.spanLeft4 {
  display: block;
  float: left;
  /*width: 150px;*/
  text-align: left;
  justify-content: left;
  /*margin-left: 0px;*/
  margin-left: 30px;
  margin-top: -45px;
}

.spanLeft5 {
  display: block;
  float: left;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 9.03px;
  line-height: 12px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #444444;
  margin-top: 7px;
  margin-right: 30px;
  margin-left: 0px;
  margin-bottom: 20px;
}

.spanLeft6 {
  display: block;
  float: left;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 9.03px;
  line-height: 12px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #444444;
  margin-top: 7px;
  margin-right: 31px;
  margin-left: 5px;
  margin-bottom: 20px;
}

.donut-inner {
  margin-top: -100px;
  margin-bottom: 100px;
}

.donut-inner h5 {
  margin-bottom: 5px;
  margin-top: 0;
}

.donut-inner span {
  font-size: 12px;
}

.pp-icon2 {
  /*position: absolute;*/
  max-width: 38px;
  max-height: 38px;
  margin-left: 0px;
  margin-right: 30px;
}

.spanRight {
  display: block;
  float: right;
  width: 150px;
  margin-right: -12px;
}

.spanRight2 {
  display: block;
  align-self: start;
  margin-right: 0px;
  justify-content: right;
  text-align: right;
  margin-top: 16px;
  margin-right: 0px;
}

.spanRight3 {
  display: block;
  align-self: start;
  margin-right: 0px;
  justify-content: right;
  text-align: right;
  margin-top: 10px;
  margin-right: 0px;
}

.spanRight4 {
  display: block;
  align-self: end;
  margin-right: 0px;
  justify-content: right;
  text-align: right;
  margin-top: 10px;
  margin-right: 0px;
}

.spanRight5 {
  display: block;
  align-self: end;
  margin-right: 0px;
  justify-content: right;
  text-align: right;
  margin-top: -55px;
  margin-right: 0px;
}

.spanRight6 {
  display: block;
  align-self: start;
  margin-right: 0px;
  justify-content: right;
  text-align: right;
  margin-top: 16px;
  margin-right: 0px;
}

.spanRight7 {
  display: block;
  align-self: start;
  margin-right: 0px;
  justify-content: right;
  text-align: left;
  margin-top: 16px;
  margin-right: -20px;
}

.no-border {
  border: 0;
  box-shadow: none;
  border-radius: 0px 0px 0px 0px !important;
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
}

.review-star-top {
  margin-left: 21px;
  margin-top: -15px;
}

.review-star-top2 {
  margin-left: 2px;
  margin-top: -15px;
}

.review-star-top3 {
  margin-right: -39px;
  margin-top: -15px;
}

.review-star-top4 {
  margin-right: -39px;
  margin-top: 30px;
}

.review-star-top5 {
  margin-right: -60px;
  margin-top: -55px;
}

.review-date {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  text-align: right;
  color: #333333;
}

.pref-img2 {
  position: absolute;
  width: 60px;
  height: 60px;
}

.saveSearchFont2 {
  align-items: top;
  justify-content: left;
}

.pagination-bold {
  font-weight: bold;
}

.hr.pp-card {
  position: absolute;
  /*border-top: 1px solid red;*/
  border: 1px solid #e0e0e0;
}

.ppcard-margin-top {
  margin-top: -10px;
}

.show-cursor-pointer {
  cursor: pointer;
  margin-top: 5px;
}

.pp-branch-loc {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */
  letter-spacing: 0;
  text-transform: uppercase;
  /*margin-top: -5px;*/
  color: #444444;
}

/* #pagination-back {
  font-size: 18px !important;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  color:#4f4f4f;
  line-height: 21px;
  margin-right: 100px;
  display: flex;
  align-items: center;
}

#pagination-next {
  font-size: 18px !important;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  color: #4f4f4f;
  line-height: 21px;
  margin-left: 100px;
  display: flex;
  align-items: center;
} */

.sucess-text-saveSearch {
  font-size: 15px !important;
  font-family: "Open Sans";
  color: #4f4f4f;
  font-weight: 400;
  line-height: 25px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.saveSearch-mgTop {
  margin-top: 25px;
}

.pp-softing {
  font-size: 12px !important;
  font-family: "Open Sans";
  color: #000000;
  font-weight: 500;
  line-height: 14.09px;
  font-style: normal;
  vertical-align: middle;
  margin-right: -12px;
}

.SearchFilterForm {
  font-size: 14px !important;
  /* font-family: "Montserrat"; */
  color: #747d88;
  font-weight: 400;
  line-height: 22px;
}

.document-head {
  font-size: 12px !important;
  font-family: "Open Sans";
}

.show-by-drop .css-yk16xz-control {
  border-color: transparent !important;
  background-color: transparent !important;
}

.show-by-drop.css-2b097c-container {
  width: 150px;
}

.show-by-drop .css-tlfecz-indicatorContainer {
  color: #000000;
}

.show-by-drop .css-1okebmr-indicatorSeparator {
  background-color: transparent;
}

.pay-detail select.form-control {
  min-height: 42px;
  background-size: 42px !important;
}

.pay-detail .form-control {
  min-height: 42px;
}

.subdesc {
  overflow-wrap: break-word;
  margin-left: 10px;
  margin-right: 10px;
  line-height: 24px;
}

.DayPicker-NavButton--prev {
  position: relative !important;
  left: 5px !important;
}

.DayPicker-NavButton--next {
  position: relative !important;
  right: -245px !important;
}

.dash-drop select.form-control {
  background: url(../src/assets/img/caret-down.svg) !important;
  background-repeat: no-repeat !important;
  background-position: 100% 15px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff !important;
  min-height: 34px;
  background-size: 12px !important;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 136.4%;
  letter-spacing: 0;
  color: #4f4f4f;
  mix-blend-mode: normal;
}

.stud-init-list.mb-34 .container {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.pp-fix-margin {
  margin-left: -6px !important;
}

.student-left-login {
  background-color: #f29724;
  color: #ffffff;
}

.student-left-login .head-1 {
  font-family: "Open Sans";
  letter-spacing: 0;
  font-size: 10px;
  line-height: 12px;
}

.student-left-login .head-2 h3 {
  font-family: "Open Sans";
  line-height: 36px;
}

.student-left-login .head-3 {
  font-size: 14px;
}

.student-left-login .head-4 {
  font-size: 10px;
  line-height: 15px;
  font-family: arial;
}

.pswd-field {
  position: relative;
}

.eye-icon {
  position: absolute;
  bottom: 7px;
  right: 16px;
}

.pp-icon {
  /*position: absolute;*/
  max-width: 38px;
  max-height: 38px;
}

/*.pp-icon2{
    max-width: 38px;
    max-height: 38px;
    margin-right: 25px;
  }*/

.pp-staff-box {
  /*font-family: "Open Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 12.5333px;
    line-height: 21px;

    color: #4F4F4F;*/
  margin-top: 0px;
  margin-bottom: 5px;
}

#pp-staff-box2 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 12.5333px;
  line-height: 21px;
  /* or 164% */
  color: #4f4f4f;
}

#pp-staff-box3 {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 570;
  font-size: 11px;
  line-height: 14px;
  /* or 164% */
  margin-top: -5px;
  color: #4f4f4f;
}

.pp-staff-progress1 {
  margin-top: 5px;
  margin-bottom: -20px;
}

.pp-staff-progress2 {
  position: absolute;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 136.4%;
  /* or 14px */
  display: flex;
  align-items: center;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #444444;
  margin-top: -20px;
  /*margin-bottom: 20px;*/
}

.pp-staff-worked {
  position: relative;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 11.3939px;
  line-height: 16px;
  /* identical to box height */
  text-transform: capitalize;
  color: #828282;
  margin-top: -2.2rem;
  margin-left: 73px;
  margin-bottom: 2rem;
}

.word-break2 {
  /*word-break: break-all;*/
  max-width: 25px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 136.4%;
  /* or 12px */
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: #444444;
  margin-top: -7px;
  /*margin-bottom: -7px;*/
}

.word-break3 {
  /*word-break: break-all;*/
  max-width: 25px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 136.4%;
  /* or 12px */
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: #444444;
  margin-top: 7px;
  margin-bottom: 9px;
}

.txt-details2 .form-control .form-control2 {
  height: 241px;
  min-height: 200px;
}

.personal-box-inner .form-control2 {
  width: 647px;
  height: 141px;
  border: 1px solid #333333;
  border-radius: 4px;
}

.personal-box-inner .form-control3 {
  width: 647px;
  height: 90px;
  border: 1px solid #e9eff4;
  border-radius: 4px;
}

.destination-box .form-control4 {
  width: 100%;
  height: 105px;
  border: 1px solid #e9eff4;
  border-radius: 4px;
}

.pp-lang-text {
  max-width: 25px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 136.4%;
  /* or 12px */
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: #444444;
  margin-top: -7px;
  margin-left: 3px;
}

.pp-lang-text2 {
  max-width: 25px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 9.03px;
  line-height: 12px;
  /* or 12px */
  display: flex;
  align-items: flex-end;
  text-align: center;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #444444;
}

.flg-img.rounded {
	width: 24px;
	height: 16px;
	border-radius: 0 !important;
	object-fit: cover;
}

.flg-img-pp.rounded {
	width: 24px;
	height: 16px;
	border-radius: 0 !important;
	object-fit: cover;
  display: none;
}

.pp-img.rounded {
  width: 50px;
  height: 50px;
  border-radius: 25px !important;
  object-fit: cover;
}

.pp-img2.rounded {
  width: 38px;
  height: 38px;
  border-radius: 25px !important;
  object-fit: cover;
}

#pp-img3 {
  width: 60px;
  height: 60px;
  border-radius: 60px !important;
  object-fit: cover;
  /* margin-top: -0.9em; */
  /* margin-bottom: 2.5em; */
}

.review-list-group-item-align {
  margin-top: -0.5em;
  margin-left: -0.3em;
}

.pp-thumbup1-align {
  margin-top: 0.4em;
  margin-right: 0.1em;
}

.pp-thumbdown1-align {
  margin-top: -0.4em;
  margin-right: 0.1em;
}

.pp-report-review {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 136.4%;
  /* or 16px */
  display: flex;
  align-items: center;
  text-align: right;
  color: #109cf1;
  mix-blend-mode: normal;
  /* Inside Auto Layout */
  flex: none;
  order: 4;
  flex-grow: 0;
  /*margin: 0px 9px;*/
  margin-top: 0em;
  margin-left: -2em;
}

button.stud-bluebtn,
button.stud-bluebtn:hover {
  background: #109cf1 !important;
  border-color: #109cf1 !important;
  font-size: 10px !important;
  margin-right: 14px;
}

button.stud-dangerbtn,
button.stud-dangerbtn:hover {
  background: #ff7878 !important;
  border-color: #ff7878 !important;
  font-size: 10px !important;
}

.agent-studtable .table th,
.agent-studtable .table td {
  padding: 15px 5px !important;
}

.payment-box
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::after {
  background-color: transparent;
  background-image: url(../src/assets/img/tick-check.svg) !important;
  background-size: 10px;
  background-position: center;
  left: -1.4765rem !important;
  top: 0.09425rem !important;
  width: 20px !important;
  height: 20px !important;
}

.makepayment-three .custom-radio .custom-control-label::after,
.makepayment-three .custom-control-label::before {
  background-color: #dfdfdf;
  background-size: 10px;
  width: 20px;
  height: 20px;
  border: 4px solid #4da1ff;
  left: -1.4765rem !important;
  top: 0.09425rem !important;
}

.filter-btn a.nav-link.filter-btn-fix.active {
  background: #2d6163 !important;
}

.filter-btn a.nav-link.filter-btn-fix {
  margin-top: 13px;
}

.pswrd-err-msg-fix {
  width: 300px;
}

.w-155px {
  width: 155px !important;
}

.resp-blue-list .btn.appapproved {
  background: #2d6163 !important;
  border-radius: 2px;
  min-width: 76px;
  min-height: 34px;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #ffffff;
  justify-content: center;
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
}

.resp-blue-list .btn.appinprocess {
  background: #f2994a !important;
  border-radius: 2px;
  min-width: 76px;
  min-height: 34px;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #ffffff;
  justify-content: center;
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
}

.resp-blue-list .btn.apppayment {
  background: #2ca7f1 !important;
  border-radius: 2px;
  min-width: 76px;
  min-height: 34px;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #ffffff;
  justify-content: center;
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
}

.review-ellipsis {
  max-width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.make-paymrnt-btn {
  height: 35px;
  min-width: 85px;
  border: transparent !important;
  font-size: 10px !important;
  border-radius: 2px;
  background-color: #4da1ff !important;
  padding: 8px !important;
}

.make-payment-btn-left {
  height: 35px;
  min-width: 118px;
  letter-spacing: 0;
  border: transparent !important;
  font-size: 10px !important;
  border-radius: 2px;
  background-color: #4da1ff !important;
  padding: 8px !important;
}

.highlight-tab.bg-info {
  -webkit-animation: fadeinout 6s ease-in-out forwards;
  animation: fadeinout 6s ease-in-out forwards;
}

@-webkit-keyframes fadeinout {
  0%,
  100% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
}

@keyframes fadeinout {
  0%,
  100% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
}

/**responsive-css**/

@media only screen and (max-width: 767px) {
  .app-mob-order-2 {
    order: 2;
  }
  .app-mob-order-1 {
    order: 1;
  }
  .app-order-list .university-list li {
    text-align: center !important;
  }
}

@media only screen and (max-width: 576px) {
  .resp-blue-list .uni-right-border .list-group {
    width: 100%;
  }
  .resp-blue-list .uni-right-border .list-group li {
    width: 100% !important;
  }
  /* .view-app-detail .view-app-left {
    max-width: 100% !important;
    flex: 0 0 100% !important;
    background: #fafafa;
  } */
  .app-menu-tab .parent-tabs li {
    width: 50%;
  }
  .app-menu-tab .parent-tabs li a {
    font-size: 10px !important;
    white-space: pre !important;
  }
}

@media only screen and (max-width: 992px) and (min-width: 577px) {
  .app-menu-tab .parent-tabs li {
    width: 50%;
  }
}

@media only screen and (max-width: 1024px) {
  .app-menu-tab .profile-student .nav li {
    display: contents;
  }
}

.green-btn {
  background-color: #2b6a6c !important;
}

.payment-box
  .custom-radio
  .custom-control-input:checked
  ~ .pay-radio.custom-control-label::after {
  background-color: #4da1ff !important;
}

.payment-box
  .custom-radio
  .custom-control-input
  ~ .pay-radio.custom-control-label::after,
.payment-box
  .custom-radio
  .custom-control-input
  ~ .pay-radio.custom-control-label::before {
  background-color: #ffffff;
}

.payment-box
  .custom-radio
  .custom-control-input:checked
  ~ .makepayment-three
  .custom-radio
  .custom-control-label::after {
  background-color: #4da1ff !important;
}

.search-ins .select-styled,
.search-ins .select-options {
  margin-left: 15px !important;
}

.search-ins .select-styled {
  color: #73818f !important;
  font-weight: 900 !important;
  font-size: 10px !important;
}

.foe-program-box .foe-top-1.foe-subject .select-styled,
.foe-program-box .search-box-orange .select-styled {
  display: none;
}

.add-view-btn .btn {
  font-size: 12px !important;
  margin-right: 3px;
}

.add-view-btn2 .btn {
  font-size: 10px !important;
  margin-right: -12px;
}

.height-40 {
  min-height: 40px !important;
}

.btn-view-feed {
  border-radius: 0px;
  font-weight: bold;
}

.min-width-16px {
  min-width: 16px !important;
}

.select-bbox .select-options {
  max-height: 210px !important;
  margin-top: 0.5rem !important;
  cursor: pointer;
  padding-top: 40px !important;
}

.select-bbox .select-options2 {
  max-height: 170px !important;
}

.select-bbox1 .select-options {
  max-height: 210px !important;
  margin-top: 0.5rem !important;
  cursor: pointer;
  padding-top: 40px !important;
}

.filter-same-btn {
  min-height: 40px !important;
  border-radius: 0px !important;
  display: flex !important;
  align-items: center;
}

.select-options select,
.select-options select {
  width: 92% !important;
  margin-top: 10px !important;
  margin-left: 15px !important;
}

.show-by-width {
  width: 108px !important;
}

.heart-size {
  min-width: 20px !important;
}

.white-text {
  color: #ffffff !important;
}

.check-btn {
  min-width: 130px !important;
}

.min-width-85px {
  min-width: 85px;
}

.lib-box .media h5 {
  white-space: pre-wrap !important;
}

.select-bbox .select-styled {
  background: url(../src/assets/img/caret-down.svg) !important;
  background-repeat: no-repeat !important;
  background-position: 89% 23px !important;
}

.select-bbox1 .select-styled {
  background: url(../src/assets/img/caret-down.svg) !important;
  background-repeat: no-repeat !important;
  background-position: 89% 23px !important;
}

@media only screen and (max-width: 1024px) {
  .mobile-response .icon-align {
    width: 100%;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 992px) {
  .navbar-brand-full {
    min-width: 185px;
    padding-top: 10px;
  }
}

/* Smartphones (portrait and landscape) ----------- */

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .pgro-search .foe-top-1.foe-subject {
    max-width: 100%;
    height: 80px;
  }
  .navbar-brand-full {
    min-width: 148px;
    padding-top: 10px;
  }
  .payment-container .nav-tabs .nav-link {
    font-size: 10px !important;
    white-space: normal;
    padding: 9px 0px;
  }
  .stud-about-page.top-section .content-block {
    padding: 20px 10px;
    background: #ffffff;
    position: absolute !important;
    top: 80px;
    left: 4%;
    width: 75%;
  }
  .fav-rate-box .icon-align.row {
    width: 50%;
  }
}

.university-about ul {
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: "Open Sans";
  font-size: 10px;
}

.university-about .nav li {
  border-bottom: 1px solid #eee;
  width: 100%;
}

.university-about .nav li a {
  font-size: 14px;
  color: #4f4f4f !important;
  text-align: center;
}

.university-about .nav li a:hover {
  background-color: #2b6a6c;
  color: #ffffff !important;
}

.university-about #accordionMenu {
  max-width: 300px;
}

.fix-relative {
  position: relative;
  z-index: 1;
}

.university-about .panel-body {
  padding: 0;
}

.university-about.panel-group .panel + .panel {
  margin-top: 0;
  border-top: 0;
}

.university-about.panel-group .panel {
  border-radius: 0;
  background: #ffffff !important;
}

.university-about .panel-default > .panel-heading {
  color: #333;
  background-color: #fff;
  border-color: #e4e5e7;
  padding: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.university-about .panel-default > .panel-heading a {
  display: block;
  padding: 10px 15px;
  text-decoration: none;
}

.university-about .panel-default > .panel-heading a:after {
  content: "";
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: "Glyphicons Halflings";
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* float: right; */
  transition: transform 0.25s linear;
  -webkit-transition: -webkit-transform 0.25s linear;
}

.university-about .panel-default > .panel-heading a[aria-expanded="true"] {
  background-color: #eee;
}

.university-about
  .panel-default
  > .panel-heading
  a[aria-expanded="true"]:after {
  content: "\2212";
}

.university-about
  .panel-default
  > .panel-heading
  a[aria-expanded="false"]:after {
  content: "\2b";
}

.university-about {
  position: absolute;
  right: 4%;
  width: 150px;
  margin-top: 13px;
  box-shadow: 0px 5px 15px rgba(68, 68, 68, 0.24);
}

.mobile-menu-view {
  display: none;
}

@media only screen and (max-width: 992px) {
  .desktop-menu-view {
    display: none !important;
  }
  .app-header {
    height: 75px;
  }
  .foe-py-2.container {
    padding-top: 0px;
  }
  .makepayment-three .personal-box-inner {
    padding: 30px;
  }
  .payment-option {
    width: 100% !important;
  }
  .mobile-menu-view {
    display: block;
  }
  .list-inline .unimenu-1 .stype {
    display: block;
    margin: 0;
  }
  .univ-programs .allrow td {
    width: 100% !important;
    display: block;
    max-width: 100% !important;
  }
}

.toggle-univ-menu {
  font-family: "Open Sans";
  font-size: 14px;
  height: 40px;
  padding-top: 12px !important;
  text-align: center;
  color: #fff !important;
}

.pgro-search.program-search .foe-top-1.foe-subject:first-child {
  margin-left: 13%;
}

.styles_wrapper__2J-67 {
  border-radius: 32px !important;
}

.profile-id span {
  color: #4f4f4f;
  font-size: 12px;
}

.profile-id2 {
  color: #4f4f4f;
  font-weight: 350;
  font-size: 11px;
}

.profile-student ul#ProfileTab li a {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #4f4f4f;
  text-shadow: 0px 0px 5px #e6e6e6;
}

.b-t-l-r {
  border-top-left-radius: 0px;
}

.b-t-r-r {
  border-top-right-radius: 0px;
}

.b-b-l-r {
  border-bottom-left-radius: 0px;
}

.b-b-r-r {
  border-bottom-right-radius: 0px;
}

.b-t-0 {
  border-top: none;
}

.b-b-0 {
  border-bottom: none;
}

.b-l-0 {
  border-left: none;
}

.b-r-0 {
  border-right: none;
}

.p-r-0 {
  padding-right: 0px !important;
}

.p-l-0 {
  padding-left: 0px !important;
}

.p-r-5 {
  padding-right: 5px !important;
}

.p-l-5 {
  padding-left: 5px !important;
}

.bb-btn {
  margin-top: 20px;
}

.profile-student ul#ProfileTab li a.active {
  font-weight: bold;
}

select.form-control:focus {
  border: 1px solid #e9eff4 !important;
}

select#slike {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC8AAAAvCAYAAABzJ5OsAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHYSURBVHgB7do9S8NAGMDx59JU8Q0NqFBpBR3USQrioFBQBAVRRJyc1N3N1a/g7uDg4CBCcVAqFsRgB1ERdBCtQoUWKlJKq7Eptk3OPGjRoYt48Rq4H5S+3PK/I00CF/KU0UbAhBFwAhNUT1uTWv4qY7jLReYlSYpCFaOU1puULj9nchEdCktdipKVcQDD69zyPlS/nfdiaarBcO9an0clcJhaXGQCHXi4Oy4eSYQkiEn8jowHQnQKtMWZ8V9EPC8inhcRz4uI50XE8yLieRHxvDCNv4nGfH8Z/y1m8ZkXrT50fLG6vrW3Umk8rJ4P43gwdDIFjDCLV5qb9O7Ojj3tTe/ZDB4u/hy7fYh7r+8eF2rccnqwv+8UGGF62MxNBvZxAqlUdqg8AQw/UM9WMHx2IrDW6W1PAyMyMIYTCIYiEIsnpze2Q3WvWq7HJUl51uHIlrMNTsDraT3KZDW/XeGI+cqXzc+M7YQjl4m+Lt+9HeHItng0Hhhg9uesRFykeBHxvIh4XkQ8LyKeFxHPi4j/d9aGMr45Mt4wqd8qV229n7eDXihOW3uZEY/S+BlvmmZvvliCaobPHlgvH1BI6lCcxd9kXH7DoGAYBlQzIpGstXF85VG+n/r4ABBZpU27zhNcAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-position: 100% 0%;
}

.personal-box-inner.address .pr-header {
  margin-top: 0px;
}

.complete-box p.com-text {
  text-transform: none;
}

.beforesave.blue-addmore {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #ffffff;
}

/* input#toefl_reading_score,
input#toefl_listening_score,
input#toefl_writing_score {
  border-right: none !important;
} */

/* .scroe-box.ielts-toef-scror-box label.uni-label,
.scroe-box.pte-score-box label.uni-label {
  font-style: normal;
  font-weight: 800;
  font-size: 8px;
  line-height: 9px;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #bdbdbd !important;
} */
/*
.scroe-box.pte-score-box input#pte_listening_score,
.scroe-box.pte-score-box input#pte_reading_score,
.scroe-box.pte-score-box input#pte_speaking_score {
  border-bottom-left-radius: 0px;
  border-bottom: none;
}

.scroe-box.pte-score-box input#pte_writing_score {
  border-bottom-right-radius: 0px;
  border-bottom: none;
}

.scroe-box.pte-score-box input#pte_listening_rank {
  border-top-left-radius: 0px;
}

.scroe-box.pte-score-box input#pte_writing_rank {
  border-top-right-radius: 0px;
}

.scroe-box.oet-c1-score-box input#c_reading_score {
  border-bottom-left-radius: 0px;
} */

.personal-box-inner.score-mark-box.aQ-box .score-list .score-in input,
.personal-box-inner.address.score-grt .score-list .score-in input {
  width: 65px !important;
}

label.uni-label.label-tb {
  min-height: 35px;
  color: #595959 !important;
}

.score-name.border-gray.ets-box .scor-1.tts {
  width: 100%;
  color: #bdbdbd !important;
}

.score-name.border-gray.ets-box .score-in input {
  color: #bdbdbd !important;
}

input#satAverageScore {
  background: transparent;
}

.profile-student ul#ProfileTab li a.testscore {
  padding-left: 0px;
}

.profile-student ul#ProfileTab li a.educationtab {
  padding-right: 10px;
}

.profile-student ul#ProfileTab li a.bgtab {
  position: relative;
  left: -12px;
}

h6[style="color: red;"] {
  font-size: 12px;
  margin-top: 2px;
  margin-bottom: -0.5em;
}

.error-align2 h6[style="color: red;"] {
  font-size: 12px;
  margin-top: -4em;
  margin-bottom: -1.2em;
}

.score-list.flex-column.ents-box {
  margin-bottom: 13px;
}

.personal-box-inner.address.score-grt
  .row.bb-ts-box
  .score-list
  .score-name
  .score-in
  input.form-control {
  width: 60px !important;
  max-width: 60px !important;
}

.personal-box-inner.reacent-school-attend {
  margin-top: 55px;
}

.pr-header.schatnd h3#demo span {
  font-weight: normal;
  padding-bottom: 5px;
  color: #4f4f4f;
}

input.form-control.disablepro.instphone.form-control::-webkit-outer-spin-button,
input.form-control.disablepro.instphone.form-control::-webkit-inner-spin-button,
input#gmattotalscore::-webkit-outer-spin-button,
input#gmattotalscore::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.form-control.disablepro.instphone.form-control[type="number"],
input#gmattotalscore[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.schatnd.school-addmore-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin-bottom: 5px;
}

.schatnd.school-addmore-btn .blue-addmore {
  margin-top: 30px;
  position: relative;
  left: 15px;
}

.schatnd.school-addmore-btn .removebtn.blue-addmore {
  margin-left: 10px !important;
}

.scroe-box .form-group input {
  color: #4f4f4f !important;
}

input:placeholder {
  color: grey;
}

select.placeholder {
  color: #999;
}

.placeholder2 {
  color: #aaa;
}

.same-line {
  height: 20px;
  /*childrens it's all absolute position, so must set height*/
  position: relative;
}

.same-line div {
  display: inline-block;
  position: absolute;
}

.on-the-left {
  left: 0px;
}

.on-the-center {
  left: 0%;
  right: 0%;
  text-align: center;
}

.on-the-right {
  right: 20px;
}

.font-light-grey {
  background-color: transparent;
  border: none;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  color: grey;
  mix-blend-mode: normal;
  opacity: 0.55;
  padding: 0px 0px;
  margin-top: 0px !important;
}

.align-bottom {
  /*height: 80px;
    border: solid black 1px;*/
  display: table-cell;
  vertical-align: bottom;
  margin: 0 0px 0px 0;
}

.search-education .search-box-orange {
  position: absolute;
  width: auto !important;
  margin-left: 75%;
}

.collapse_down {
  transform: rotate(180deg);
}

.school-filter {
  padding: 10px;
  padding-bottom: 0px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  color: #2b6a6c;
}

/* .program-filter {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  color: #b80d48;
  padding: 10px;
  padding-bottom: 0px;
} */

.SearchFilterForm {
  margin-bottom: 40px;
  padding: 10px;
}

.filter-static-label {
  padding: 0px 15px;
}

.range-bar-main {
  background: #ffffff;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 25px;
  margin-bottom: 15px;
}

.elegible-img {
  margin-bottom: 30px;
}

.sent-header-status {
  font-size: 9px;
  font-weight: 500;
  align-items: center;
  text-align: center;
}

.response-count {
  position: relative;
  display: flex;
  bottom: 26px;
  left: 37px;
  background: #4da1ff;
  border-radius: 50%;
  justify-content: center;
  width: 16px;
  height: 16px;
  color: #fff;
  align-items: center;
  font-size: 10px;
  line-height: 1;
}

.university-heading {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #4f4f4f;
}

.download-div {
  background: rgb(242, 242, 242);
  padding-left: 16px !important;
  padding: 25px;
}

.offer-letter-last {
  font-size: 15px;
  line-height: 25px;
  margin-top: 15px;
}

.btn-grp-offer {
  margin-top: 70px;
}
.btn-grp-offer {
  margin-top: 50px;
  padding-top: 40px;
  border-top: 1px solid #e9eff4;
}

.btn-decline {
  margin-left: 15px !important;
  background-color: rgb(255, 255, 255) !important;
  color: rgb(77, 161, 255) !important;
  font-size: 11px !important;
  line-height: 13px !important;
  padding: 14px 35px !important;
}

.fav-prog-list .table th,
.fav-prog-list .table td {
  border-top: 0;
}

.threedots:after {
  content: "\2807";
  font-size: 30px;
}

.addviewer-bg {
  background-color: #4da1ff;
}

.unread-count {
  position: relative;
  bottom: 18px;
  right: 12px;
  background: #4da1ff;
  border-radius: 50%;
  text-align: center;
  padding: 3px 7px;
  width: 16px;
  color: #fff;
}

.radio-margin {
  margin-right: 34px;
}

@media only screen and (max-width: 991px) {
  /* .uni-left-card.card{min-height: auto;border: none !important;} */
  .send-app-page .uni-left-card .card-body {
    padding-top: 0;
    padding-bottom: 0;
  }
  .send-app-page .uni-left-card li.list-group-item {
    padding: 15px 20px;
    line-height: 1;
  }
  .app-body .send-app-page .uni-left-card li.list-group-item a {
    line-height: 1 !important;
  }
  .send-app-page .uni-left-card li.list-group-item a.active {
    border-bottom: 3px solid #2d6163;
    border-radius: 0px 0px 3px 3px;
  }
  .send-app-page .uni-left-card .card-body .list-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: inherit;
    align-items: flex-end;
  }
}

@media (max-width: 767px) {
  .pr-header h3 {
    flex-wrap: wrap;
    font-size: 20px;
    line-height: 22px;
  }
}
