@import "../scss/style.scss";

#SendMessageHeader.sendMessageSec {
  .btn-primary3-font {
    font-family: $primaryFonts;
    box-shadow: none !important;
    &:hover {
      box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.1) !important;
    }
  }
}
.react-confirm-alert-button-group > button:first-child {
  background: $portalMainColor !important;
}
.pp-detail-top-box .partner-short-info span#pp-comp-name {
  margin-bottom: 15px;
  display: block;
}
.profile-viewus .PPtabSec .nav-tabs .nav-link.active {
  border-bottom: 5px solid transparent !important;
}
.about-section .agent-about .ppAboutLeft {
  padding-right: 100px;
}
.about-section {
  .agent-about {
    padding-bottom: 65px;
  }
  @media (max-width: 767px) {
    .agent-about {
      padding-bottom: 0;
    }
  }
}

body.header-fixed {
  background-color: #f7f7f7 !important;
  header.foe-header-container {
    .navbar-brand {
      margin-top: 2px;
      height: auto !important;
    }
  }
}
.foe-studen-container {
  // padding-top: 50px;
  background-color: #f7f7f7;
}
.select-app-profile .react-datepicker__day {
  font-weight: 400;
}
.select-app-profile .react-datepicker-popper {
  box-shadow: 0px 0px 10px rgba(189, 189, 189, 0.3);
  border: 1px solid #ddd;
}

.foe-inbox-container {
  .LeftInboxMenu {
    .inboxMenuList {
      li.chatLink {
        padding-right: 6px;
        > a {
          font-weight: bold;
          text-transform: uppercase;
          letter-spacing: 0;
        }
      }
      li a .svgIconStroke path {
        stroke: #444444;
      }
    }
    .btn.btn-meetings {
      padding-top: 0;
      height: 52px;
    }
  }
}

// Preffered Partners Page css start
// .SearchFilterForm,
// .student-popup .Education {
//   // font-family: "Raleway", sans-serif !important;
//   .form-group {
//     margin-bottom: 24px !important;
//     label.uni-label {
//       font-weight: normal;
//       font-size: 12px;
//       line-height: 22px;
//       color: #747d88;
//       // font-family: "Raleway", sans-serif !important;
//     }
//   }
// }
.foe-studen-container.foe-institute-box {
  .docth2.pp-softing {
    display: inline-flex;
    align-items: center;
    width: auto;
    padding-top: 7px;
    background-color: transparent;
    .select-wrapper4 {
      margin-right: 0;
      margin-bottom: 0;
      background-color: transparent;
      width: auto;
      select {
        text-transform: none;
        padding-left: 0;
        margin-left: 0;
        width: 116px;
        cursor: pointer;
        background-image: url(../assets/img/arrow_down_gray_icon.svg);
        &:hover {
          text-transform: none;
        }
        &:focus {
          border: 0 !important;
          box-shadow: none !important;
        }
      }
    }
  }
}
.foe-student-box {
  &.foe-prefpartner {
    filter: unset;
    -webkit-filter: unset;
    overflow: hidden;
    position: relative;
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      display: block;
      background-color: rgba(51, 51, 51, 0.6);
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .pgro-search {
    .foe-service-body {
      font-family: $primaryFonts;
      font-weight: 300 !important;
      line-height: 41px;
      padding-bottom: 57px;
      margin: 0;
      padding-top: 100px;
    }
    // .foe-subject .form-control {
    //   min-width: 354px;
    // }
    .foe-top-1.foe-subject {
      max-width: 100%;
    }
  }
}
.foe-ins-container {
  .foe-inst-list {
    .uni-logo-list .col-md-8 img {
      width: auto;
      max-width: 100%;
    }
    .uni-logo-list {
      .content-block {
        padding: 15px 10px;
        margin: 0;
        height: calc(100% - 24px);
        &.pp_list_sec {
          padding: 40px;
        }
        .insBottom {
          padding-bottom: 25px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .cont-rgt-contry {
          margin-bottom: 18px !important;
          width: 100%;
          .contry-list-wrap {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            @media only screen and (max-width: 991px) {
              justify-content: inherit;
              flex-direction: column;
            }
            span {
              width: 40%;
              @media only screen and (max-width: 991px) {
                width: 100%;
                margin-bottom: 15px;
              }
              &.icon-left {
                width: 60%;
                padding-left: 20px;
                position: relative;
                line-height: 1.4;
                @media only screen and (max-width: 991px) {
                  width: 100%;
                }
                img {
                  position: absolute;
                  left: 0;
                  top: -4px;
                }
              }
            }
          }
        }
        .hand-cursor {
          @media only screen and (max-width: 991px) {
            position: absolute;
            right: 0;
            top: 20px;
          }
          img {
            @media only screen and (max-width: 991px) {
              max-width: 22px;
            }
          }
        }
      }
    }
    // .stud-init-list #pagination {
    //   #page-numbers {
    //     button#pagination-back {
    //       margin-right: 0;
    //     }
    //     button#pagination-next {
    //       margin-left: 0px;
    //     }
    //   }
    // }
  }
  .pp_list_sec {
    padding: 38px 50px;
    .pp_list_wrap {
      .cont-rgt-grap2 {
        margin: 0;
        letter-spacing: 0;
      }
      .cont-rgt-contry {
        margin: 10px 0 22px;
      }
      .fav_view {
        margin: 0;
        display: flex;
        span {
          padding-right: 30px;
          .review-star-top,
          p {
            margin-top: 0;
            margin-bottom: 0;
            text-transform: none;
            font-weight: normal;
            font-size: 10px;
            color: #4f4f4f;
          }
        }
      }
      .list_fav {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .cont-rgt-contry {
          margin-top: 0;
        }
        .profile-btn {
          margin-bottom: 15px;
        }
      }
    }
    .partner_list_top {
      margin-bottom: 34px;
    }
    .partner_list {
      ul {
        padding: 0;
        display: flex;
        li {
          width: 38px;
          margin-right: 24px;
          text-align: center;
          .part_img {
            width: 38px;
            height: 38px;
            border-radius: 50%;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          span {
            font-weight: 500;
            font-size: 9px;
            text-transform: capitalize;
            color: #444444;
          }
        }
      }
      .cont-rgt-grap {
        margin: 0;
        .pp-bottom p:not(:first-child) span {
          margin: 0 30px 0 0;
        }
      }
      .score-back.btn.btn-primary2 {
        font-size: 12px;
        font-family: $primaryFonts;
      }
      .button-right {
        text-align: right;
        button {
          display: inline-block;
          margin: 0 !important;
        }
      }
    }
    hr {
      margin-top: 30px;
    }
  }
}
.section-wrap {
  padding: 0 20px;
  margin: 50px 0;
  @media only screen and (max-width: 991px) {
    padding: 0;
  }
}
#StaffMember1 {
  margin: 50px 0 0;
}
.review-section-pp {
  padding: 20px 0 50px;
}
.my_review_sec {
  padding: 50px 70px;
}
#StaffMember1 h2 {
  margin-bottom: 30px !important;
}
.agent-pr-list .office_wrap {
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid #e0e0e0;
  h3 {
    font-weight: bold;
    font-size: 19px;
    line-height: 26px;
    color: #444444;
    margin-bottom: 23px;
  }
  .office_blocks {
    // display: flex;
    // justify-content: space-between;
    // flex-wrap: wrap;
    .office_block {
      margin-bottom: 20px;
      p {
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        text-transform: capitalize;
        color: #444444;
        margin-bottom: 7px;
      }
      span {
        display: block;
        font-weight: 500;
        font-size: 12px;
        line-height: 17px;
        color: #444444;
      }
      span.grayColor {
        color: rgba(68, 68, 68, 0.5);
        margin-top: 10px;
      }
      .nav-link.btn {
        max-width: 150px;
        width: 100%;
        border: 1px solid #777;
        border-radius: 2px;
        color: #333;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        text-transform: uppercase;
        padding: 14px 10px;
        margin: 0 0 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: none !important;
        background-color: #fff;
        transition: all 0.2s ease-in-out;
        margin-top: -10px;
        img,
        svg {
          display: inline-block;
          width: 18px;
          height: 18px;
          margin-right: 5px;
        }
        &:hover,
        &:focus {
          box-shadow: 0px 10px 18px rgba(0, 0, 0, 0.35) !important;
          transform: translateY(-3px);
        }
      }
      // .calMeetingBtn.btn {
      //   padding: 7px 10px;
      //   display: flex;
      //   align-items: center;
      //   svg {
      //     margin-right: 5px;
      //   }
      // }
    }
  }
  &:last-child {
    border-bottom: 0;
  }
}
.agent-static {
  &.certificateSec {
    padding: 50px 0 40px;
    h3 {
      margin-top: 0;
      padding-bottom: 0;
    }
  }
  // .tab-content .agent-cer-box .btn-group{}
  .agent-st-right .agent-cer-box button {
    position: relative;
    padding: 5px 25px 5px 50px;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0;
    text-decoration-line: underline;
    color: #b80d48;
    min-height: 51px;
    width: calc(33.33% - 20px) !important;
    max-width: inherit;
    min-width: auto;
    margin: 10px !important;
    display: inline-block !important;
    word-break: break-all;
    border-color: #b80d48;
    img {
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
    }
    svg.cer-icon {
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
      path {
        fill: #b80d48;
      }
    }
    @media only screen and (max-width: 991px) {
      width: calc(100% - 20px) !important;
    }
  }
}
.my_review_sec {
  .comment-bgcolor {
    background-color: #e2e2e2 !important;
    .media-body span img {
      margin: 0 6px;
    }
  }
}
// Preffered Partners Page css end

ul.left-list.list-group a:hover {
  color: $portalMainColor;
}

.pgro-search {
  .search-education {
    margin: 0;
    display: flex;
    justify-content: center;
    .foe-subject {
      flex: 0 0 23% !important;
      max-width: 23% !important;
      padding: 0 5px;
      .form-control {
        width: 100% !important;
        min-width: 1px;
        margin: 0 !important;
      }
    }
    .search-box-orange {
      position: static;
      width: auto !important;
      margin-left: 0;
      button {
        font-size: 0;
      }
    }
  }
}

// Filter Css
.ProgramSearchFilter {
  width: 100%;
  padding: 30px 0;
  position: relative;
  &::before {
    content: "";
    width: 100%;
    height: 1px;
    background-color: rgba(68, 68, 68, 0.17);
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 0;
    transform: translate(0, -50%);
  }
  .btn {
    background-color: $portalMainColor;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 2px;
    border: none;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    padding: 12px 25px;
    position: relative;
    z-index: 5;
    width: 115px;
    svg {
      transform: rotate(90deg);
      width: auto;
      height: 6px;
      margin-right: 8px;
      path {
        fill: #fff;
      }
    }
    &:hover,
    &:focus,
    &:focus-within {
      outline: none;
      border: none;
      background-color: #444444;
    }
  }
}
.SearchFilterForm h4 img {
  transform: rotate(180deg);
}

.filterSelect {
  width: 100%;
  .filterSelect__control {
    background: #ffffff;
    border: 1px solid #bdbdbd !important;
    box-sizing: border-box;
    border-radius: 3px;
    margin: 0;
    min-height: 40px;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
    color: #9c9c9c;
    text-transform: capitalize;
    .filterSelect__value-container {
      padding-left: 18px;
    }
  }
  .filterSelect__menu {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
    text-transform: capitalize;
  }
}

.filter-popup {
  .student-popup {
    .filter-clear {
      background-color: $portalMainColor;
      box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #ffffff;
      padding: 40px 20px;
      min-height: 116px;
      a {
        font-family: $primaryFonts;
      }
    }
    .filter-group {
      background-color: #fff;
      padding: 31px 8px;
    }
    .filter-bottom .float-right {
      justify-content: flex-end;
      button {
        margin: 0;
        margin-left: 16px !important;
        margin-top: 56px !important;
        &.reset.btn {
          margin-right: 0;
        }
      }
    }
  }
}
.filter-popup {
  .modal-content {
    min-height: 100vh;
  }
  .filter-clear2 {
    background-color: $portalMainColor;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
    padding: 40px 20px;
  }

  .filter-group-box {
    .mainFields {
      padding: 50px 0 0px;
      label {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: #333333;
      }
      input {
        background-color: #ffffff;
        border-radius: 3px;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: #333333;
        margin-bottom: 30px;
        padding: 10px 20px;
        height: auto;
        border: none;
      }
    }
    h4 {
      font-weight: bold;
      font-size: 22px;
      line-height: 26px;
      letter-spacing: 0;
      color: #444444;
    }

    .filter-bottom {
      .saveSearchFont {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: $primaryFonts;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0;
        color: #444444;
        text-shadow: 0px 0px 5px #e6e6e6;
      }
      .btn {
        font-weight: bold;
        font-family: $primaryFonts;
        font-size: 10px;
        line-height: 12px;
        display: flex;
        align-items: center;
        text-align: center;
        text-transform: uppercase;
        &.reset {
          color: $portalMainColor;
        }
      }
    }
  }
}

.header-fixed .app-body.foe-body {
  overflow: unset !important;
}
@media only screen and (max-width: 991px) {
  .app-body {
    margin-top: 58px !important;
  }
  body {
    .app-header {
      background-color: #f2f2f2;
      box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
      border: 0;
      padding: 9px 0;
      height: auto;
      top: 0;
      &::before {
        display: none;
      }
      .navbar-brand {
        position: static;
        top: auto;
        left: auto;
        margin-left: 0;
        max-width: 150px;
        .navbar-brand-full {
          padding-top: 0;
          min-width: 1px;
        }
      }
      .navbar-nav {
        margin-top: 0;
        .nav-link {
          padding: 0;
          min-height: 1px;
          min-width: 1px;
          margin-left: 15px;
          img {
            margin: 0 !important;
          }
          span {
            display: none;
          }
          // &.prof-header {
          //   display: none;
          // }
        }
        &.foe-nav-right li {
          margin: 0;
        }
      }
      .navbar-toggler {
        min-width: 1px;
        padding: 0;
        margin-left: auto;
        margin-right: 0;
        .navbar-toggler-icon {
          background-image: url(../assets/img/icons/toggle-icon.svg);
          background-size: 18px;
        }
        &:hover {
          .navbar-toggler-icon {
            background-image: url(../assets/img/icons/toggle-icon.svg);
          }
        }
      }
    }
    .sidebar {
      top: 0;
      z-index: 9999;
      background-color: #fff;
      color: #444444;
      width: 85%;
      height: 100vh;
      .sidebar_open_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 13px 15px;
        background-color: #f2f2f2;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
        .navbar-brand {
          max-width: 150px;
          padding: 0;
          img {
            padding: 0;
            min-width: 1px;
          }
        }
        .sidebar_close_btn {
          background-image: url(../assets/img/icons/toggle-icon-close.svg);
          background-repeat: no-repeat;
          background-position: center;
          padding: 0;
        }
      }
      .nav-item {
        border-bottom: 1px solid #dadada;
        .nav-link {
          color: #444444;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 0;
          padding: 19px 25px;
          text-transform: capitalize;
          font-family: $primaryFonts;
          &:hover {
            background: transparent;
            .nav-icon {
              color: #444444;
            }
          }
          .nav-icon {
            color: #444444;
            width: auto;
          }
          &.nav-dropdown-toggle:before {
            width: 10px;
            height: 20px;
            margin-top: -10px;
            background-image: url(../assets/img/icons/chevron-right.svg);
            transition: all 0.5s ease;
          }
          &.active {
            color: $portalMainColor;
            font-weight: 800;
            background: transparent;
          }
        }
        &.nav-dropdown.open {
          background: transparent;
          padding-bottom: 15px;
          .nav-link {
            color: #444444;
          }
          .nav-dropdown-toggle:before {
            transform: rotate(90deg);
          }
        }
        .nav-dropdown-items {
          padding-left: 20px;
          // padding-bottom: 15px;
          li {
            border-bottom: 0;
            .nav-link {
              padding: 8px 25px;
              &.active {
                color: $portalMainColor;
                font-weight: 800;
                background: transparent;
              }
            }
            &.active {
              color: $portalMainColor;
              font-weight: 800;
              background: transparent;
            }
          }
        }
        &.mobileLogout .nav-link {
          color: #fff;
          font-weight: 600;
          line-height: 18px;
          background: #363b40;
        }
        &.active {
          color: $portalMainColor;
          font-weight: 800;
          background: transparent;
        }
      }
      & > .sidebar-nav {
        width: 100%;
      }
      .nav {
        width: 100%;
      }
    }
    // &.sidebar-show .sidebar {
    //   margin-left: 0;
    // }
    &.sidebar-show {
      overflow: hidden;
      &:before {
        content: "";
        width: 100%;
        height: 100%;
        display: inline-block;
        background-color: rgba(0, 0, 0, 0.6);
        position: absolute;
        left: 0;
        top: 0;
        z-index: 9999;
        transition: all 0.5s ease;
      }
      .main:before {
        display: none;
      }
    }
  }
  html:not([dir="rtl"]) .sidebar {
    margin-left: -90% !important;
    z-index: 999999;
  }
  html:not([dir="rtl"]) .sidebar-show .sidebar {
    margin-left: 0 !important;
  }
  .pgro-search {
    .search-education {
      .foe-top-1 {
        flex: 0 0 100% !important;
        max-width: 100% !important;
        height: 60px;
      }
      .foe-top-1.search-ins {
        flex: 0 0 50% !important;
        max-width: 50% !important;
      }
      .foe-subject .form-control {
        height: 38px;
      }
      .search-box-orange {
        width: 100% !important;
        text-align: center;
        button.search-icon {
          width: 100%;
          font-weight: normal;
          font-size: 10px;
          line-height: 11px;
          letter-spacing: 0;
          text-transform: uppercase;
          color: #ffffff;
          background-color: #f29724 !important;
          box-shadow: 0.888889px 0.888889px 10.6667px rgba(144, 168, 190, 0.33);
          border-radius: 44px;
          padding: 13px 10px;
          img {
            display: none;
          }
        }
      }
    }
  }
  .filter-popup {
    .student-popup .filter-clear {
      padding: 20px 20px;
      min-height: 78px;
    }
    .filter-clear2 {
      padding: 20px 20px;
      min-height: 78px;
    }
    .filter-group-box {
      width: 100%;
      padding: 0 20px 30px;
      > h4 {
        margin-top: 0 !important;
        margin-bottom: 26px !important;
        font-size: 18px;
        line-height: 21px;
      }
      .SearchFilterForm {
        margin: 0 0 25px;
        padding: 15px 24px;
      }
      .filter-bottom {
        br {
          display: none;
        }
        .saveSearchFont {
          justify-content: flex-start;
          padding: 0;
        }
        .float-right {
          justify-content: center;
          button.score-save {
            margin: 30px 10px 0 !important;
            min-width: 128px;
            min-height: 38px;
          }
        }
      }
      .SearchFilterForm.program-filter .range-bar-main {
        padding: 4px 20px 0;
        .form-group {
          margin-bottom: 16px !important;
          #livingCostSelected {
            text-align: right;
          }
        }
      }
    }
  }
}

.preferred_about {
  .ppAboutMain {
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    .about-section {
      box-shadow: none !important;
      border-radius: 0 !important;
      .ppAboutLeft {
        tr {
          th {
            border: 1px solid rgb(242, 242, 242);
            background-color: rgb(255, 255, 255);
            width: 30%;
            padding: 7px 10px;
            .pp-th-title {
              font-size: 10px;
              line-height: 14px;
              letter-spacing: 0px;
              text-transform: uppercase;
              font-weight: 700;
              color: rgb(68, 68, 68);
            }
          }
          td {
            width: 70%;
            padding: 7px 10px;
            border: 1px solid rgb(242, 242, 242);
            .pp-th-title2 {
              font-size: 12px;
              line-height: 14px;
              letter-spacing: 0px;
            }
          }
        }
      }
    }
  }
  .review-section {
    box-shadow: none !important;
    border-radius: 0;
    .agent-pr-list .pref-part {
      .media-left {
        padding-top: 6px;
      }
    }
    .review-section-pp .ratingImg img {
      height: 23px;
    }
  }
}

// Favourite Pages css
.profile-wishlist {
  min-height: calc(100vh - 100px);
  background-color: #fff !important;
  padding-top: 0;
  .prof-wish-menu {
    padding: 0;
    padding-top: 80px;
    border-right: 1px solid rgba(68, 68, 68, 0.17) !important;
    .uni-left-card {
      border: 0 !important;
    }
    .card-body {
      padding: 0;
      .left-list {
        a {
          padding: 10px 0;
        }
        li.list-group-item {
          width: auto !important;
          text-align: right !important;
          padding: 7px 20px 7px 0 !important;
          border-right: 5px solid transparent;
          margin-right: -3px;
          &.active {
            border-right: 5px solid #4da1ff;
            font-weight: 500 !important;
          }
        }
      }
    }
  }
  .ProfileWishListSec {
    // padding-top: 80px;
    .uni-left-card {
      border: 0 !important;
      .favouriteHeading {
        margin-bottom: 24px;
        align-items: center;
        .favrite-header {
          font-weight: normal;
          font-size: 25px;
          line-height: 29px;
          letter-spacing: 0;
          text-transform: capitalize;
          color: #444444;
          margin-top: 10px;
          font-family: $primaryFonts;
        }
        select.form-control {
          width: 120px;
        }
      }
      .card-body {
        padding: 0 12px;
      }
    }
  }
  .favProgramListWrap {
    border: 1px solid #f2f2f2;
    border-radius: 3px;
    .favProgramList {
      padding: 20px 40px;
      border-bottom: 1px solid rgba(227, 233, 239, 0.5);
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      &:nth-child(even) {
        background-color: #fbfcfd;
      }
      .favIcon {
        width: 8%;
      }
      .programContent {
        width: 77%;
        h5 {
          font-weight: 800;
          font-size: 12px;
          line-height: 19px;
          letter-spacing: 0;
          text-transform: capitalize;
          color: #444444;
          margin: 0;
        }
        .programBlocks {
          display: flex;
          flex: 0 0 100%;
          gap: 15px;
          .programBlcok {
            width: 25%;
            margin-top: 15px;
            padding-right: 10px;
            span {
              font-weight: 400;
              font-size: 10px;
              line-height: 14px;
              letter-spacing: 0;
              text-transform: uppercase;
              color: #000000;
              display: block;
              &:first-child {
                font-weight: 700;
              }
            }
          }
        }
      }
      .programBtn {
        width: 15%;
        text-align: right;
        .list-btn{
          display: block;
          text-align: center;
          padding: 10px;
        }
        button {
          font-weight: bold;
          font-size: 10px;
          line-height: 10px;
          text-transform: uppercase;
          margin: 5px auto;
          box-shadow: none;
          border-radius: 2px;
          border: 1px solid transparent;
          width: 100%;
          min-width: 105px;
          padding: 9px 5px;
          transition: all 0.5s ease;
          &.solidBtn {
            color: #ffffff;
            background: $portalMainColor;
            &:hover {
              color: $portalMainColor;
              background: transparent;
              border: 1px solid $portalMainColor;
            }
          }
          &.outlineBtn {
            color: $portalMainColor;
            background: transparent;
            border: 1px solid $portalMainColor;
            &:hover {
              color: #ffffff;
              background: $portalMainColor;
            }
          }
        }
      }
    }
  }
  .wishlistWrap {
    background-color: #f7f7f7;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px 15px;
    justify-content: stretch;
    justify-items: stretch;
    align-items: stretch;
    padding: 40px 15px;
    .favPartnerList {
      // width: calc(33.33% - 15px);
      border-radius: 5px;
      // margin: 14px 7px 0;
      .favPartnerInner {
        padding: 15px 22px;
        position: relative;
        height: 100%;
        background-color: #ffffff;
        border: 1px solid #d8d8d8;
        box-sizing: border-box;
        box-shadow: none;
        border-radius: 6px;
        cursor: pointer;
        transition: all 0.5s ease-in-out;
        font-family: $primaryFonts;
        &:hover,
        &:focus {
          box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.1);
          border-radius: 6px;
          border: 1px solid #fff;
        }
        .favIcon {
          position: absolute;
          top: 14px;
          right: 20px;
        }
        .partnerImg {
          min-height: 63px;
          max-width: 172px;
        }
        .partnerName {
          font-weight: bold;
          font-size: 17px;
          line-height: 20px;
          color: #444444;
          margin: 15px 0;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .countryRating {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 11px;
          line-height: 14px;
          color: #444444;

          .countryFlag {
            display: flex;
            align-items: center;
            width: 30%;
            flex: 0 0 30%;
            margin-right: 5%;
            img {
              margin-right: 6px;
            }
          }
          .rating {
            flex-direction: row;
            width: 65%;
            flex: 0 0 65%;
            align-items: center;
            justify-content: flex-end;
            img {
              width: 16px;
              height: 16px;
              margin-right: 5px;
              margin-top: -4px;
            }
            span {
              margin-right: 5px;
            }
            label {
              margin: 0;
              padding: 0;
              font-size: 11px;
              line-height: 14px;
              color: #444444;
              width: calc(90% - 20px);
              flex: 0 0 calc(90% - 20px);
              &:before {
                display: none !important;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1199px) {
    .wishlistWrap {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 767px) {
    .wishlistWrap {
      grid-template-columns: repeat(1, 1fr);
    }
    .favouriteHeading {
      .left {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
      }
      .right {
        .checksame {
          min-width: 160px;
          width: auto;
        }
      }
    }
    .applicationListing {
      .header {
        .left {
          padding-bottom: 0 !important;
          padding-top: 0 !important;
        }
      }
    }
  }
}

// inner page sidebar css
@media only screen and (max-width: 991px) {
  body {
    .foe-studen-container {
      padding-top: 0px !important;
    }

    // .innerSidebarWrap {
    //   margin-left: -210px;
    //   width: 200px;
    //   position: fixed;
    //   top: 58px;
    //   left: 0;
    //   transition: all 0.5s ease;
    //   height: calc(100vh - 63px);
    //   padding: 24px 14px 24px 28px;
    //   z-index: 999;
    //   background-color: #fff;
    //   box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    //   .uni-left-card {
    //     border-radius: 0;
    //     border-left: 0 !important;
    //     box-shadow: none;
    //     .card-body {
    //       padding: 0;
    //       .left-list {
    //         li {
    //           padding: 13px 0;
    //           font-weight: 500;
    //           font-size: 11px;
    //           line-height: 13px;
    //           letter-spacing: 0;
    //           color: #444444;
    //           &.active {
    //             color: $portalMainColor;
    //             font-weight: bold;
    //           }
    //           .submenu {
    //             padding-left: 10px;
    //           }
    //         }
    //       }
    //     }
    //   }
    //   &.active {
    //     margin-left: 0;
    //     &::before {
    //       content: "";
    //       width: 100vw;
    //       height: 100vh;
    //       position: absolute;
    //       top: 0;
    //       left: 100%;
    //       background-color: rgba(0, 0, 0, 0.5);
    //       z-index: 9999;
    //       transition: all 0.5s ease;
    //     }
    //   }
    // }
    .left-dash-align {
      border: 0;
    }
  }
  .foe-student-box {
    &.foe-prefpartner {
      min-height: 200px;
    }
    .foe-prefpartner {
      min-height: 200px;
    }
    &.foe-institute .foe-about-boxx {
      width: 100%;
    }
    .pgro-search {
      .foe-service-body {
        font-size: 23px !important;
        line-height: 25px;
        padding: 0 0 20px;
      }
      // .search_wrap {
      //   margin: 0;
      //   .foe-subject {
      //     height: 47px;
      //   }
      // }
    }
  }
  .foe-institute-box {
    .thumb_list {
      padding-top: 15px;
      & > .col-12,
      & > .insBottom {
        padding-left: 5px !important;
      }
      .row {
        padding-left: 0 !important;
        .thumb_fav {
          position: absolute;
          top: 2px;
          right: -10px;
        }
      }
      .insBottom {
        margin-top: 15px !important;
        font-size: 16px;
        padding-bottom: 30px;
      }
    }
  }
  .foe-ins-container .pp_list_sec {
    padding: 20px 5px 0;
    .pp_list_wrap {
      .list_logo img {
        max-width: 120px;
      }
      .cont-rgt-grap2 {
        margin-top: 12px;
      }
      .fav_view span {
        padding-right: 20px;
      }
      .list_fav {
        position: static;
        .profile-btn button.btn.btn-primary3 {
          float: left;
          margin-top: 12px;
          width: 82px;
          padding: 0 2px;
        }
        .cont-rgt-contry {
          position: absolute;
          top: 0;
          right: 15px;
        }
        .profile-btn {
          margin-bottom: 0;
        }
      }
    }
    .partner_list {
      ul {
        flex-wrap: wrap;
        li {
          width: 20%;
          margin-right: 0;
          margin-bottom: 17px;
          padding: 0 5px;
          .part_img {
            margin: 0 auto;
          }
        }
      }
      .score-back.btn-primary2 {
        margin: 20px 0 0 !important;
        width: 82px;
        height: 30px;
        min-height: 30px;
      }
      .partner_list_top {
        margin-bottom: 15px;
      }
      .cont-rgt-grap .pp-bottom p:not(:first-child) {
        display: inline-block;
        span {
          margin: 0 20px 0 0;
        }
      }
    }
    .insBottom3 {
      margin-bottom: 0;
    }
  }
  // .foe-ins-container
  //   .foe-inst-list
  //   .stud-init-list
  //   #pagination
  //   #page-numbers
  //   button#pagination-next {
  //   margin-left: 0;
  // }
  // .foe-ins-container
  //   .foe-inst-list
  //   .stud-init-list
  //   #pagination
  //   #page-numbers
  //   button#pagination-back {
  //   margin-right: 0;
  // }
  // span#page-numbers button {
  //   margin-bottom: 10px;
  // }
  .preferred_about {
    .img-block {
      height: 267px;
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .partner-short-info {
      max-width: 510px;
      width: 100%;
      height: auto;
      padding: 0 15px;
      .pp-logo-pos {
        padding: 0;
        max-width: 86px;
        padding-top: 6px !important;
      }
      .cont-rgt-contry2 {
        padding: 0;
        position: static;
        margin-bottom: 15px !important;
        // #pp-comp-name {
        //   font-size: 16px;
        //   line-height: 21px;
        //   position: absolute;
        //   top: 15px;
        //   left: 115px;
        //   max-width: 200px;
        //   br {
        //     display: none;
        //   }
        // }
        .pp-branch-loc {
          text-transform: none;
        }
        .cont-rgt-contry2-pos {
          margin: 0 !important;
        }
        .cont-rgt-contry {
          margin-top: 0 !important;
          margin-bottom: 0 !important;
        }
        .heart-icon {
          position: absolute;
          top: 10px;
          right: 10px;
        }
      }
      @media only screen and (max-width: 767px) {
        margin: auto;
        max-width: 100%;
      }
    }
    .agent-about .row {
      padding: 30px 20px !important;
      .col-md-4.about-left-block {
        order: 3;
        > div {
          padding-left: 0;
          padding-right: 0;
        }
        .student-statistic-pos {
          margin-top: 30px;
        }
      }
      .col-md-8.about-left-block {
        order: 2;
        table th,
        table td {
          vertical-align: middle;
          padding: 1px 8px;
          .pp-th-title2 {
            font-size: 11px;
          }
        }
        .pref-part-list3 {
          margin-bottom: 0;
          padding: 10px 0;
        }
        .pref-part.service-part .list-group li.list-group-item {
          font-size: 11px;
          padding: 2px 10px;
          &::before {
            width: 4px;
            height: 4px;
            left: -2px;
            top: 6px;
          }
        }
      }
      .review-title.about-title {
        order: 1;
        font-size: 23px;
        line-height: 27px;
        margin-top: -60px !important;
      }
    }
    .about-facility {
      padding-top: 10px;
      padding-bottom: 10px;
      h2 {
        font-size: 23px;
      }
      // ul,
      // ol,
      // li {
      //   list-style: unset !important;
      // }
    }
    .agent-static {
      padding: 32px 0;
      h3 {
        margin-top: 0;
        padding-bottom: 0;
      }
    }
    .contact-us-session {
      padding-left: 15px !important;
      padding-right: 15px !important;
      h2.about-title {
        font-size: 23px;
        line-height: 27px;
        padding: 0;
        margin-bottom: 12px !important;
      }
      .pp-contact-us {
        margin-left: 0;
        margin-top: 0 !important;
      }
      .agent-pr-list .office_wrap {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 10px;
        h3 {
          font-size: 17px;
          margin-bottom: 9px;
        }
        .office_blocks {
          .office_block {
            // width: 50%;
            // margin-bottom: 20px;
            // padding-left: 5px;
            // padding-right: 5px;
            p,
            span {
              font-size: 11px;
            }
            .nav-link.btn {
              min-width: 120px;
              padding: 10px 10px;
              margin-bottom: 10px;
              margin-top: 0;
            }
          }
        }
      }
    }
    .review-section {
      // box-shadow: none !important;
      // border-radius: 0;
      #StaffMember1 {
        padding: 0 15px !important;
        > .row {
          margin: 0;
        }
        h2.campustitle {
          font-size: 23px;
          margin-bottom: 30px !important;
        }
        .pref-part {
          margin-bottom: 30px !important;
          .card {
            // max-width: 100%;
            // margin-left: 0 !important;
            // margin-right: 0 !important;
            max-width: 47.5%;
            flex: 0 0 47.5%;
          }
        }
      }
      .review-section-pp {
        > .row {
          margin: 0;
          padding: 0;
          > div {
            padding-left: 0 !important;
            padding-right: 0 !important;
            margin-left: 0 !important;
            margin-right: 0 !important;
          }
        }
        .review-title {
          font-size: 23px;
          line-height: 27px;
          padding: 0;
          margin-top: 36px !important;
          margin-bottom: 23px !important;
        }
        .select-wrapper {
          padding: 40px 0 0 !important;
        }
        .review_progress {
          margin-top: 40px;
        }
        .review_progress > .row {
          width: 100%;
          margin: 0;
          .align-left6 {
            margin: 0 !important;
          }
          #pp-progress1 {
            width: 100%;
          }
          span:first-child,
          span:last-child {
            padding: 0;
            margin: 0;
            font-size: 11px;
          }
          // span:last-child {
          //   padding: 0;
          // }
        }
      }
    }
    .my_review_sec {
      &#save-review h3 {
        padding: 0;
      }
      #ContactUs2 {
        margin: 0;
        padding: 0;
      }
      .w-100 > .col-md-12 {
        padding-left: 0;
        padding-right: 0;
        > .row {
          padding: 0 15px;
          .comment-bgcolor {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
        > .media {
          margin-bottom: 23px;
        }
      }
      .media-left #pp-img3 {
        width: 46px;
        height: 46px;
        margin-left: 0 !important;
        margin-bottom: 0;
        margin-top: -2px;
      }
      .media-body {
        .media .col-10 > div {
          padding: 0;
        }
        .media .col-10 {
          flex: 0 0 70%;
          max-width: 70%;
        }
        .media .review-date {
          flex: 0 0 30%;
          max-width: 30%;
          font-size: 11px;
          padding-left: 0;
        }
        .review-list-group-item-align {
          margin-left: 0 !important;
          > a {
            width: 50%;
            max-width: 50%;
            padding: 0;
            .pp-thumbup1-align1 {
              margin-left: 0;
            }
            .pp-edit-comment-btn3 {
              margin: 0;
            }
          }
        }
      }
      .comment-bgcolor {
        > .col-md-12 {
          padding: 0;
        }
        .media {
          flex-wrap: wrap;
          width: 100%;
          // .media-left {
          //   width: 100%;
          // }
          .media-body {
            padding-left: 0 !important;
          }
        }
        &:not(.add_comment) {
          .media-left {
            width: 100%;
          }
        }
      }
      // }
    }
    .all_review {
      margin: 0 !important;
      padding: 50px 20px;
      > .col-md-12 {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 20px;
        > .media {
          margin-bottom: 15px;
        }
        > .row {
          padding: 0 15px;
          .comment-bgcolor {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }
      .review-bottom {
        .col-6 {
          padding: 0;
        }
        h3 {
          margin-left: 0;
          margin-bottom: 0 !important;
        }
        .pp-review-link {
          margin: 0;
        }
      }
      .media {
        .media-left {
          #pp-img3 {
            width: 46px;
            height: 46px;
          }
        }
      }
    }
    // .border-bottom-line .filter-btn.nav-tabs {
    //   a.nav-link {
    //     padding: 0 8px !important;
    //     min-height: 34px;
    //     height: 34px;
    //     border-bottom: 0 !important;
    //     font-family: $portalMainColor;
    //     font-weight: 700;
    //     font-size: 10px;
    //     line-height: 12px;
    //     text-align: center;
    //     text-transform: uppercase;
    //     color: #ffffff;
    //     &::before {
    //       display: none;
    //     }
    //   }
    // }
    .PPtabSec {
      overflow-x: auto;
      margin-left: 150px;
      #ProfileTab {
        min-width: 560px;
        float: none;
        a.nav-link {
          margin-bottom: 0 !important;
        }
      }
    }
  }
  .register-popup .modal-saveSearch {
    width: 100%;
  }
  .sendMessageSec .row,
  .sendMessageSec .personalinfo,
  .sendMessageSec [class*="col-"] {
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
  }
  .sendMessageSec .personal-box-inner {
    margin-bottom: 10px !important;
  }
  .sendMessageSec {
    .destination-box {
      .uni-label-header {
        font-size: 20px;
      }
      .uni-label-pp-name {
        font-size: 14px;
        line-height: 18px;
      }
      .uni-label-pp-address {
        font-size: 10px;
        border-bottom: 1px solid #444;
        padding-bottom: 20px;
      }
      .uni-label-pp-address .uni-label-pp-hr {
        display: none;
      }
      .pr-header {
        padding-left: 0 !important;
        margin-bottom: 0px !important;
      }
      .uni-label-pp-header {
        margin: 0;
      }
      .valigntop2 {
        .spanRight {
          margin-right: 0;
          text-align: right;
        }
      }
      .personal-box-inner {
        .form-control {
          margin-left: 0;
        }
        .uni-label2 {
          width: 100%;
        }
        .form-control2 {
          width: 100%;
          border-color: #bdbdbd;
        }
      }
    }
    .personalinfo {
      .uni-label-pp-hr2,
      .uni-label-pp-hr3 {
        display: none;
      }
      .backarrow {
        margin: 0 0 30px !important;
      }
    }
  }
  .leaveReviewSec {
    table.ratingReview {
      width: 100%;
      tr td {
        width: 100%;
        display: block;
      }
      .form-control5 {
        width: 100%;
      }
      .leavereview-title {
        margin-bottom: 18px;
      }
      textarea {
        height: 110px;
      }
    }
    table.ratingReview
      tr:not(:first-child)
      td.leavereview-title:not(.sendmessage-row) {
      display: none;
    }
  }
}

@media (max-width: 767px) {
  .preferred_about .review-section #StaffMember1 .pref-part .card {
    max-width: 100%;
    flex: 0 0 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

@media only screen and (max-width: 1199px) and (min-width: 992px) {
  header.foe-header-container .foe-py-2.container {
    padding-top: 40px;
  }
  .foe-inbox-container {
    .LeftInboxMenu {
      flex: 0 0 20%;
      max-width: 20%;
    }
    .chatDataList,
    .meetingListData {
      flex: 0 0 100%;
      max-width: 100%;
      .listing {
        flex: 0 0 40%;
        max-width: 40%;
      }
      .details {
        flex: 0 0 60%;
        max-width: 60%;
      }
    }
    // .viewMessageSection,
    // .rightInboxSec {
    //   flex: 0 0 80%;
    //   max-width: 80%;
    // }
  }
}

@media only screen and (max-width: 991px) {
  .foe-inbox-container {
    top: 0;
    padding-top: 0;
    min-height: calc(100vh - 58px);
    // margin-top: 63px;
    .LeftInboxMenu {
      border-right: 0;
      padding: 12px 16px;
      min-height: 55px;
      background-color: #dbecff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .btn.btn-compose {
        font-size: 9px;
        line-height: 9px;
        width: auto;
        height: 31px;
        padding: 5px 16px;
        border-radius: 9px;
        margin: 0;
        display: none;
        img {
          width: 11px;
          margin: -2px 6px 0 0;
        }
      }
      .inboxMenuList {
        width: 100%;
        li {
          padding: 0;
          margin-bottom: 18px;
          &.chatLink {
            padding-right: 5px;
            margin-bottom: 0;
            > a {
              text-transform: uppercase;
              font-weight: bold;
            }
          }
          a {
            font-weight: 500;
            font-size: 11px;
            line-height: 13px;
            padding: 0;
            background-color: transparent;
            img,
            svg {
              display: none;
            }
            &.active {
              font-size: 11px;
              background-color: transparent;
            }
          }
        }
      }
      .btn.btn-meetings {
        margin: 0 !important;
        padding: 0;
        width: 100%;
        font-weight: bold;
        font-size: 11px;
        line-height: 13px;
        padding-right: 5px;
        height: auto;
        img:not(.collapseIcon) {
          display: none;
        }
        .collapseIcon {
          margin-left: auto;
        }
      }
      &.inboxPageHeader {
        .btn.btn-compose {
          display: flex;
        }
      }
    }
  }
  .inboxSidebarMenuBtn {
    min-width: 20px;
    min-height: 20px;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    text-transform: uppercase;
    color: #444444;
    padding-left: 35px;
    background-repeat: no-repeat;
    background-position: left center;
    background-image: url(../assets//img//icons/sidebarOpenIcon.svg);
  }
  .inboxSidebarMenuBtnClose {
    width: 20px;
    height: 20px;
    margin-bottom: 20px;
    background-image: url(../assets//img//icons/inbox-menu-close.svg);
  }
  .forMobileInboxMenu {
    margin-left: -210px;
    width: 200px;
    position: fixed;
    top: 58px;
    left: 0;
    transition: all 0.5s ease;
    height: calc(100vh - 58px);
    padding: 24px 14px 24px 28px;
    z-index: 999;
    background-color: #fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    &.active {
      margin-left: 0;
      &:before {
        content: "";
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9999;
        transition: all 0.5s ease;
      }
    }
    .collapse {
      .inboxMenuList {
        padding-top: 18px;
      }
    }
  }
  .about-section .agent-about .ppAboutLeft {
    padding-right: 15px;
  }
  .favSidebarWrap {
    margin-left: -210px;
    width: 200px;
    position: fixed;
    top: 58px;
    left: 0;
    transition: all 0.5s ease;
    height: calc(100vh - 63px);
    padding: 24px 14px 24px 28px;
    z-index: 999;
    background-color: #fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    &.active {
      margin-left: 0;
      &::before {
        content: "";
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9999;
        transition: all 0.5s ease;
      }
    }
  }
  .profile-wishlist {
    padding-top: 0 !important;
    .prof-wish-menu {
      padding: 20px 17px 0;
      border-right: 0 !important;
      .card-body .left-list {
        a {
          padding: 0;
        }
        li.list-group-item {
          text-align: left !important;
          border-right: 0;
          padding: 13px 0px 13px 0 !important;
          &.active {
            border-right: 0;
            font-weight: 700 !important;
          }
        }
      }
    }
    .ProfileWishListSec {
      padding: 0 17px;
      .uni-left-card .card-body {
        padding: 0;
        .favouriteHeading .favrite-header {
          font-weight: bold;
          font-size: 14px;
          line-height: 16px;
          margin: 0;
        }
      }
    }
    .wishlistWrap .favPartnerList {
      width: 100%;
    }
    .favProgramListWrap {
      .favProgramList {
        padding: 21px 20px;
        position: relative;
        .favIcon {
          position: absolute;
          top: 21px;
          right: 14px;
        }
        .programContent {
          width: 100%;
          h5 {
            padding-right: 32px;
            line-height: 16px;
            margin-bottom: 3px;
          }
          .programBlocks {
            flex-wrap: wrap;
            .programBlcok {
              width: 45%;
              margin-top: 15px;
            }
          }
        }
        .programBtn {
          width: 100%;
          text-align: left;
          button {
            margin: 15px 14px 0 0;
          }
        }
      }
    }
  }
}

// @media only screen and (min-width: 992px) {
//   .header-fixed header.foe-header-container.app-header {
//     min-height: 177px;
//     top: -10px;
//   }
// }
.wrap-scroll {
  padding-right: 20px;
}

@media (max-width: 767px) {
  .sendMessageSec {
    .align-left1 {
      margin-left: 0 !important;
    }
    .form-control {
      padding: 0 15px !important;
    }
  }
}

.react-confirm-alert-body {
  padding: 50px 30px;
  color: #444;
  font-size: 14px;
  box-shadow: -5px 0 30px -10px rgba(0, 0, 0, 0.25);
  font-family: $primaryFonts;
  h1 {
    font-size: 30px;
    font-weight: 500;
    color: #000;
    margin-bottom: 20px;
  }
  .react-confirm-alert-button-group {
    margin: 40px 0 0;
    justify-content: center;
    display: flex;
    button {
      min-width: 100px;
      margin: 0 10px 0;
    }
  }
}
