@import "../../scss/style.scss";

header {
  background-color: #f2f2f2;
  padding: 17px 0;
  width: 100%;
  border-bottom: none;
  .logo {
    width: auto;
    height: 55px;
    display: block;
    margin: 0;
  }
  .rightText {
    text-align: right;
    font-family: $primaryFonts;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    color: #4f4f4f;
    display: flex;
    align-items: center;
    svg {
      width: auto;
      height: 12px;
      margin: -3px 12px 0;
      path {
        fill: #494949;
      }
    }
  }
}

.cardSection {
  .head {
    font-family: $primaryFonts;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #1b2125;
    padding: 40px 0 30px;
    a {
      color: #1b2125;
      text-decoration: none;
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
    svg {
      width: 13px;
      height: auto;
      margin: 0 10px 0 0;
      path {
        fill: #788995;
      }
    }
  }
  .selectOptions {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 80%;
    li {
      position: relative;
      margin: 0 0 12px;
      border: 1px solid #f2f2f2;
      box-sizing: border-box;
      border-radius: 3px;
      position: relative;
      padding: 22px;
      label {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        cursor: pointer;
        input.form-check-input {
          width: 18px;
          height: 18px;
          margin: 0 15px 0 0;
          padding: 0;
          position: relative;
        }
        .details {
          width: 100%;
          table {
            width: 100%;
            border: none;
            td {
              font-family: $primaryFonts;
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 16px;
              letter-spacing: 0.05em;
              color: #323c47;
              vertical-align: top;
              .title {
                font-family: $primaryFonts;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 19px;
                color: #1b2125;
                margin: 0 0 15px;
                display: block;
              }
              .cardImage {
                width: auto;
                height: 26px;
                margin-left: 10px;
                border: 1px solid #e0e0e0;
                border-radius: 4px;
                padding: 4px 6px;
              }
              .btn.btn-verified {
                padding: 5px 8px;
                background: #ffffff;
                border: 1px solid #4da1ff;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: $primaryFonts;
                font-style: normal;
                font-weight: 600;
                font-size: 11px;
                line-height: 12px;
                letter-spacing: 0.01em;
                color: #4a90e2;
                svg {
                  margin-right: 5px;
                  width: 12px;
                  height: auto;
                  path {
                    stroke: #4a90e2;
                  }
                }
              }
              .bankDetails {
                font-weight: 600;
                font-size: 13px;
                line-height: 18px;
              }

              .dragNdrop {
                width: 100%;
                margin: 20px 0 0;
                padding: 0;
                text-align: center;
                background: #eef8ff;
                border: 1px dashed #4da1ff;
                border-radius: 8px;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 12px;
                color: #444444;
                p {
                  margin: 0;
                }

                .btn.btn-primary {
                  font-family: $primaryFonts;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 16px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  letter-spacing: 0.05em;
                  color: #2f80ed;
                  padding: 20px;
                  margin: 0;
                  background-color: transparent;
                  border: none;
                  svg {
                    margin-right: 5px;
                  }
                }
              }
              .fileList {
                display: block;
                width: 100%;
                margin: 10px 0 0;
                .box {
                  display: flex;
                  justify-content: flex-start;
                  align-items: flex-start;
                  background: #fafafa;
                  border-radius: 8px;
                  width: 100%;
                  margin: 10px 0;
                  padding: 10px;
                  position: relative;
                  .fileIcon {
                    width: 31px;
                    height: 31px;
                    display: block;
                  }
                  .fileName {
                    padding-left: 15px;
                    width: 80%;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;
                    letter-spacing: 0.05em;
                    color: #000000;
                    word-break: break-all;
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    .fileIcon {
                      margin: 0 10px 0 0;
                    }
                    .fileSize {
                      opacity: 0.5;
                      font-size: 11px;
                      display: block;
                      padding-top: 3px;
                    }
                  }
                  .fileRemove {
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    width: 12px;
                    height: 12px;
                    display: block;
                    transform: translate(0, -50%);
                  }
                }
              }
            }
          }
        }
      }

      &.active {
        background: #edf6ff;
        border: 1px solid #4da1ff;
        border-radius: 3px;
        label {
          .details{
            table{
              td{
                .dragNdrop{
                  background-color: #ffffff;
                }
              }
            }
          }
        }
      }
    }
  }
}

.cartSection {
  background-color: #f8fafb;
  min-height: calc(100vh - 100px);
  font-family: $primaryFonts;
  font-style: normal;
  padding: 0 50px !important;
  text-align: center;
  .head {
    padding: 40px 0 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e4eaee;
    strong.title {
      font-weight: normal;
      font-size: 24px;
      line-height: 28px;
      color: #1b2125;
    }
    span {
      background: #0095f8;
      border-radius: 100px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      text-transform: uppercase;
      color: #ffffff;
    }
  }
  table {
    width: 100%;
    td {
      padding: 20px 10px;
      vertical-align: top;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.05em;
      text-transform: capitalize;
      text-align: left;
      color: #4f4f4f;
      .uniLogo {
        background: #ffffff;
        border-radius: 3px;
        width: 88px;
        height: 88px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          object-fit: cover;
        }
      }
      &.uniName {
        font-weight: normal;
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0.05em;
        text-transform: capitalize;
        color: #4f4f4f;
      }
    }
    tfoot {
      tr {
        td {
          padding: 5px 10px;
          color: #828282;
          &.text-right {
            font-style: italic;
          }
        }
        &.subTotal {
          border-top: 1px solid #e4eaee;
          td {
            padding: 15px 10px 5px;
          }
        }
        &.subTotal2 {
          td {
            padding: 5px 10px 15px;
          }
        }
        &.total {
          border-top: 1px solid #4F4F4F;
          td {
            padding: 20px 10px;
            font-size: 16px;
            line-height: 24px;
            color: #4f4f4f;
          }
        }
      }
    }
  }
  .btn.btn-primary {
    margin: 40px auto 20px;
    padding: 8px 15px;
    background-color: #4da1ff;
    border-radius: 3px;
    font-family: $primaryFonts;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    text-align: center;
    text-transform: capitalize;
    color: #ffffff;
    width: 150px;
    justify-content: center;
    align-items: center ;
  }
}
