@import "../../scss/style.scss";

.viewMeetingInput {
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 3.77577px;
  padding: 15px;
  margin: 12px 0;

  .strong {
    font-weight: 600;
  }
}

.viewMeetingTextArea {
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 3.77577px;
  padding: 15px;
  min-height: 100px;
  overflow: auto;
}

.description {
  .section {
    margin: 40px 0 0;

    .title {
      font-weight: bold;
      font-size: 12px;
      line-height: 22px;
      color: #444444;
      margin-bottom: 10px;
    }

    h3 {
      &.title {
        font-family: $primaryFonts;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 40px;
        letter-spacing: 0.25px;
        color: #333333;

        svg {
          background-color: #DADADA;
          border-radius: 50px;
          width: 28px;
          height: 28px;
          margin-right: 8px;
          padding: 6px;
        }
      }
    }

    .content {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: #444444;
      padding: 0;
    }

    .participantsList {
      margin: 0;
      padding: 0;
      list-style: none;
      width: 200px;

      li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 3px;
        cursor: pointer;

        span {
          padding: 5px 10px;
          width: 30px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $white;
          font-style: normal;
          font-weight: 500;
          font-size: 11px;
          line-height: 14px;
          text-transform: uppercase;
          border-radius: 3px;
          margin-right: 3px;
          background-color: $epGreen;
        }

        em {
          padding: 7px 5px;
          width: calc(100% - 33px);
          height: 25px;
          display: block;
          font-family: $primaryFonts;
          font-style: italic;
          font-weight: bold;
          font-size: 10px;
          line-height: 12px;
          text-align: left;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          color: #444444;
          text-shadow: 0px 0px 5px #E6E6E6;
          border-radius: 3px;
          background-color: #F2F2F2;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .copyLink {
      a {
        display: block;
        width: 100%;
        height: auto;
        padding: 8px 20px;
        word-break: break-all;
        background-color: #eff6ff;
        border: none;
        border-radius: 8px;
        font-family: $primaryFonts;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 1.8;
        letter-spacing: 0.25px;
        color: #0f6fde;
        
      }

      input {
        background: #F2F2F2;
        border-radius: 8px;
        border: none;
        font-family: $primaryFonts;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 40px;
        letter-spacing: 0.25px;
        color: #323C47;
        padding: 2px 20px;
        height: 40px;
      }

      .btn {
        &.btn-link {
          font-family: $primaryFonts;
          font-style: normal;
          font-weight: 600;
          font-size: 11px;
          line-height: 30px;
          color: #4DA1FF;
          padding: 2px 20px;
          height: auto;
          color: #4DA1FF;
        }

      }
    }
  }
}

.blueStrip {
  width: 100%;
  background-color: $primary-color;

  .leftSection {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: $primaryFonts;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 29px;
    color: #FBFBFB;
    padding: 20px 0;

    .backArrow {
      width: 28px;
      height: 24px;
      margin-right: 20px;
    }
  }

  .rightSection {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .btn {
      border-radius: 4px;
      border-color: $white;
      font-family: $primaryFonts;
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 15px;
      letter-spacing: 0.05em;
      padding: 8px 15px;
      margin-left: 10px;
      min-width: 130px;

      &.btn-outline-white {
        color: $white;

        &:hover,
        &:focus {
          background-color: $white;
          color: #323C47;
        }
      }

      &.btn-white {
        background-color: $white;
        color: #323C47;

        &:hover,
        &:focus {
          background-color: $primary-dark;
          color: $white;
        }
      }
    }
  }
}

.ViewMeeting {
  section {
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 4px;
    overflow: hidden;

    head {
      background: #EFEFEF;
      border-bottom: 1px solid #E5E5E5;
      box-sizing: border-box;
      padding: 18px 40px;
      font-family: $primaryFonts;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 25px;
      display: flex;
      align-items: center;
      color: #444444;
    }

    .content {
      padding: 30px 40px;

      .basicForm {
        label {
          font-family: $primaryFonts;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: normal;
          text-transform: none;
          color: #333333;
          padding: 10px 0 !important;
        }

        h4 {
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
          color: #323C47;
          margin: 0;
          padding: 0;
        }

        .addParticipants {
          font-family: $primaryFonts;
          font-style: normal;
          font-weight: 600;
          font-size: 11px;
          line-height: 13px;
          text-align: center;
          color: $primary-color;
          padding: 8px 13px;
          background: $white;
          border: 1px solid $primary-color;
          box-sizing: border-box;
          border-radius: 4px;

          &:hover,
          &:focus {
            outline: none;
            box-shadow: none;
            background-color: $primary-color;
            color: $white;
          }
        }

        .participantsList {
          margin: 0;
          padding: 0;
          list-style: none;
          width: 200px;

          li {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 3px;
            cursor: pointer;

            span {
              padding: 5px 10px;
              width: 30px;
              height: 25px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: $white;
              font-style: normal;
              font-weight: 500;
              font-size: 11px;
              line-height: 14px;
              text-transform: uppercase;
              color: $white;
              border-radius: 3px;
              margin-right: 3px;
              background-color: #c00;
            }

            em {
              padding: 7px 5px;
              width: calc(100% - 33px);
              height: 25px;
              display: block;
              font-family: $primaryFonts;
              font-style: italic;
              font-weight: bold;
              font-size: 10px;
              line-height: 12px;
              text-align: left;
              letter-spacing: 0.1em;
              text-transform: uppercase;
              color: #444444;
              text-shadow: 0px 0px 5px #E6E6E6;
              border-radius: 3px;
              background-color: #F2F2F2;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}



.modal-dialog.assignModal {
  max-width: 450px;
  width: 90%;
  margin: auto;

  .modal-content {
    .modal-header {
      background-color: $white;
      border: none !important;
      padding: 16px 30px !important;

      .closeBtn {
        svg {
          path {
            stroke: #9C9C9C;
          }
        }
      }

      .modal-title {
        color: #333333;

        .whiteIcon {
          background-color: $primary-color !important;
          width: 28px !important;
          height: 28px !important;

          svg {
            padding: 1px;

            path {
              fill: $primary-color;
              stroke: $white;
            }
          }
        }
      }
    }
  }

  .modal-body {

    //max-height: 75vh;
    .box {
      width: 85%;
      padding: 0;
      margin: 0;
      float: left;

      .assignModalSearch {
        background-color: #f1f1f1;
        border-radius: 8px;

        input.form-control {
          margin: 0;
          border: none !important;
          background-color: transparent;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.25px;
          color: #3c444c;
        }

        .btn.btn-secondary {
          background-color: transparent;
          border: none;
        }
      }

      .listing {
        width: 100%;
        height: auto;
        background: #ffffff;
        box-shadow: 1px 1px 12px rgba(144, 168, 190, 0.33);
        border-radius: 8px;
        margin: 14px 0 0;
        padding: 0;

        h4 {
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 22px;
          color: #3c444c;
          padding: 18px 20px 12px;
          margin: 0;
          cursor: pointer;
          background: url("../../assets/img/arrow_down_gray_icon.svg") no-repeat right 20px center / 12px 12px;
        }

        .list {
          margin: 0;
          padding: 0;
          list-style: none;

          li {
            width: 100%;
            border-bottom: 1px solid #e5e5e5;
            padding: 8px 20px;
            // input.form-check-input {
            // 	//display: none;
            // }
          }

          &.staff {
            li {
              .form-check-label {
                display: flex;
                align-items: center;
                clear: both;
                cursor: pointer;

                &::before {
                  content: "";
                  display: block;
                  width: 20px;
                  height: 20px;
                  border-radius: 20px;
                  margin-right: 8px;
                  background: rgba($color: $grey-rgb-color,
                      $alpha: 0.17) url("../../assets/img/university/tick_white_icon.svg") no-repeat center center / 11px 11px;
                }

                &.selected {
                  &::before {
                    background: $primary-color url("../../assets/img/university/tick_white_icon.svg") no-repeat center center / 11px 11px;
                  }
                }

                input {
                  display: none;
                }

                .profilePic {
                  width: 33px;
                  height: 33px;
                  border-radius: 33px;
                  background-repeat: no-repeat;
                  background-position: center center;
                  background-size: cover;
                  margin-right: 10px;
                  text-indent: -5000px;
                  overflow: hidden;
                }

                .personalDetials {
                  strong {
                    display: block;
                  }
                }

                .groupName {
                  width: 25%;
                  text-align: right;
                  text-transform: capitalize;
                  color: $primary-color;
                  margin: auto 0 auto auto;
                }
              }
            }
          }
        }
      }
    }

    .alphaSorting {
      width: 15%;
      float: right;

      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        width: 100%;

        li {
          text-align: center;

          label {
            display: block;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.25px;
            color: #3c444c;
            margin: 0;
            padding: 0;
            cursor: pointer;

            input.form-check-input {
              display: none !important;
            }
          }
        }
      }
    }
  }
}