@import "../../../scss/style.scss";
body.header-fixed .messageSection {
  .replyBox {
    padding: 50px;
    .replySection {
      background: #ffffff;
      box-shadow: 1px 1px 12px rgba(144, 168, 190, 0.33);
      header {
        padding: 20px 25px 0;
        margin-bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .dropdown {
          .dropdown-toggle {
            &::after {
              width: 0;
              height: 0;
              padding: 0;
              margin: 0;
              margin-left: 5px;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-top: 5px solid #9c9c9c;
              content: "";
            }
          }
        }
        button.dropdown-toggle.btn.btn-link {
          padding: 8px 0;
          display: flex;
          align-items: center;
          img {
            display: inline-block;
            margin-right: 5px;
          }
        }

        .emailAdddress {
          width: calc(100% - 150px);
          margin: 0;
          padding: 0 20px 0 10px;
          list-style: none;
          li {
            padding: 0 0 4px;
            align-items: center;
            &.show {
              display: flex;
            }
            &.hide {
              display: none;
            }
            span.type {
              width: 20px;
              font-family: $primaryFonts;
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 14px;
              color: #333333;
            }
            div {
              flex-grow: 1;
              margin-left: 10px;
              span {
                width: auto;
              }
              .tagify {
                border: none;
                font-family: $primaryFonts;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 13px;
                color: #333333;
                .tagify__tag > div::before {
                  box-shadow: none;
                  border: 0.3px solid #9c9c9c;
                  box-sizing: border-box;
                  border-radius: 24px;
                }
              }
            }
          }
        }
        .rightIcons {
          width: 80px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          span {
            cursor: pointer;
            font-family: $primaryFonts;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0;
            color: #444444;
            opacity: 0.5;
            transition: all 0.3s ease-in-out;
            &:hover,
            &:focus {
              opacity: 1;
            }
          }
          .DeleteIcon {
            cursor: pointer;
            path {
              fill: #e0e0e0;
            }
            &:hover,
            &:focus {
              path {
                fill: #555;
              }
            }
          }
        }
      }
      .replyBody {
        padding: 20px 25px;
        .attachement {
          padding: 25px 0 0;
        }
      }
      footer {
        padding: 20px !important;
        margin: 0 !important;
        .btn {
          background: $primary-dark;
          border: 1px solid $primary-dark;
          border-radius: 3px !important;
          padding: 8px 25px;
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 15px;
          text-align: center;
          color: $white;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            margin: 0 5px 0 0;
            path {
              fill: transparent;
              stroke: $white;
            }
          }
          &:hover,
          &:focus {
            background-color: $primary-dark;
          }
        }
        .AddAttachment {
          display: block;
          cursor: pointer;
        }
      }
    }

    .editorSection {
      padding: 0;

      .text {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #323c47;
        strong {
          font-weight: bold;
        }
      }

      .dragNdrop {
        width: 100%;
        margin: 20px 0 0px;
        padding: 20px;
        text-align: center;
        background: #eef8ff;
        border: 1px dashed #4da1ff;
        border-radius: 4px;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 12px;
        color: #444444;
        p {
          margin: 0;
        }

        .btn.btn-primary {
          border-radius: 4px;
          background: #4da1ff;
          border: 1px solid #4da1ff;
          font-style: normal;
          font-weight: 600;
          font-size: 11px;
          line-height: 13px;
          color: #ffffff;
          padding: 6px 11px;
          margin: 0 0 15px;
          img {
            margin-right: 5px;
          }
        }
      }
      .fileList {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;
        margin: 0 0 0;
        .detail {
          width: 100%;
          margin: 0 0 10px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 13px;
          color: #444444;
          .HasAttachmentIcon {
            display: inline-block;
            width: 18px;
            height: 21px;
            margin-right: 10px;
            path {
              stroke: #333;
            }
          }
        }
        .box {
          width: 40%;
          border: 3px solid #edf6ff;
          border-radius: 8px;
          background-color: $white;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          text-decoration: none;
          color: $text-dark;
          justify-content: space-between;
          align-items: center;
          padding: 10px;
          margin: 10px 10px 0 0;
          position: relative;
          .fileIcon {
            width: 31px;
            height: 31px;
            display: block;
          }
          .fileName {
            padding-left: 5px;
            width: 80%;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 22px;
            color: #333333;
            word-break: break-all;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            .fileSize {
              font-size: 12px;
            }
          }
          .fileRemove {
            position: absolute;
            top: 10px;
            right: 14px;
            width: 11px;
            height: 11px;
            display: block;
            cursor: pointer;
          }
          .DownloadAttachemnet {
            position: absolute;
            bottom: 5px;
            right: 8px;
            width: 20px;
            height: 20px;
            display: block;
          }
        }
      }

      .attachement {
        .detail {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 13px;
          color: #bdbdbd;
          img {
            display: inline-block;
            width: 16px;
            height: 20px;
          }
        }
        .fileList {
          .box {
            width: 40%;
            border: 3px solid #edf6ff;
            border-radius: 8px;
            background-color: $white;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            text-decoration: none;
            color: $text-dark;
            justify-content: space-between;
            align-items: center;
            padding: 10px 15px;
            .fileName {
              padding: 0;
              .fileSize {
                text-decoration: none;
                opacity: 1;
                margin: 0;
              }
              .DownloadAttachemnet {
                width: 16px;
                height: 20px;
                opacity: 1;
                right: 0;
              }
            }
          }
        }
      }
    }

    &.hide {
      display: none;
    }
    &.show {
      display: block;
    }

    @media only screen and (max-width: 991px) {
      .replySection {
        header {
          padding: 5px 10px;
          flex-wrap: wrap;
          .emailAdddress {
            flex: 0 0 100%;
          }
        }
        .replyBody {
          padding: 0;
          margin: 10px 0;
        }
        footer {
          padding: 20px 20px 20px 30px !important;
        }
      }
    }
  }
}
