@import "../../scss/style.scss";
.upload-docment {
  .newBg {
    padding: 30px 60px !important;

    .title {
      font-size: 18px !important;
      line-height: 25px !important;
    }
    .uploadDocs {
      margin-top: 30px;
      .infoIcon {
        width: 14px;
        height: 14px;
        margin-left:13px;
        path {
          fill: #2d6163;
        }
      }
      .docsName {
        font-family:$primaryFonts;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        padding: 10px 10px;
        display: block;
        color: #000000;
      }

      .fileInput {
        border-radius: 0 4px 4px 0 !important;
        border: 1px solid #c0c6cc !important;
        border-radius: 5px !important;
        display: flex;
        flex-wrap: nowrap;
        gap: 0;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 20px;
        input {
          border: none !important;
          width: 100%;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #000000;
          padding: 10px 20px;
        }
        .iconBox {
          background-color: transparent !important;
          border: none !important;
          padding: 10px;
          width: 40px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          svg path {
            width: 10px;
            height: auto;
            fill: #dadada;
          }
          &.upload {
            svg path {
              width: 15px;
              height: auto;
              fill: #dadada;
            }
          }
        }
      }
    }

    .pdfList {
      .selectedPdfs {
        .pdfFileRemove {
          right: 0;
          svg {
            width: 10px;
            height: 10px;
          }
        }
      }
    }

    .selectedPdfs {
      max-width: unset;
      width: 100%;
      height: auto;
      background: #ffffff;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      margin-top: 20px;
      padding: 15px 30px;

      .pdfName {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #000000;
      }

      .viewDoc {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 27px;
        text-decoration-line: underline;
        color: #979797;
        margin-top: 5px;
      }

      .pdfSize {
        font-family: $primaryFonts;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 221.44%;
        color: #979797;
      }

      .verification {
        display: flex;
        align-items: stretch;
        justify-content: flex-end;

        .btn {
          border-radius: 2px;
          font-style: normal;
          font-weight: 400;
          font-size: 9px;
          line-height: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #828282;
          padding: 8px 15px;
          white-space: nowrap;

          img {
            width: 14px;
            height: 14px;
            margin-right: 8px;
            display: inline-block;
          }

          &.btn-requestVerification {
            background: #ff4f4f;
            border: 1px solid #ff4f4f;
            color: #fff;
          }

          &.btn-pending {
            background: rgba(232, 232, 232, 0.4);
            border: 1px solid #d9d9d9;
            color: #828282;
          }

          &.btn-verified {
            background: #fff;
            border: 1px solid #fff;
            color: #27ae60;
            padding: 0;
          }

          &.btn-rejected {
            background: #fff;
            border: 1px solid #fff;
            color: #ff4f4f;
            padding: 0;
          }
        }
      }

      @media screen and (max-width: 991px) {
        .verification {
          .btn {
            margin: 20px auto 10px;
          }
        }
      }
    }
  }
}
.upload-docment .dragNdrop {
  width: 100%;
}

.dragNdrop {
  width: 327px;
  height: auto;
  margin: 45px 0;
  padding: 0;
  text-align: center;
  background: #eef8ff;
  border: 1px dashed #2f80ed;
  border-radius: 8px;
  font-family: $primaryFonts;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #2f80ed;
  cursor: pointer;

  svg {
    width: 18px;
    height: auto;
    margin-right: 5px;
  }

  p {
    margin: 0;
  }

  .dropzone {
    span {
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #2f80ed;
    }
  }

  .btn.btn-primary {
    border-radius: 4px;
    background: #4da1ff;
    border: 1px solid #4da1ff;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    color: #ffffff;
    padding: 6px 11px;
    margin: 0 0 15px;

    img {
      width: 16px;
      height: auto;
      margin-right: 5px;
    }
  }
}
.tooltip {
  &.bs-tooltip-auto {
    .tooltip-inner {
      background-color: #2d6163;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      color: #fff;
      max-width: 364px;
      height: 36px;
      font-family: $primaryFonts;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      padding: 10px;
    }
    .arrow::before {
      border-top-color: #2d6163 !important;
    }
  }
}
