@import "../../scss/style.scss";

.applicationOverview {
  background-color: #fff;
  width: 100%;
  border-radius: 8px;
  .view-prof-detail {
    .uniLogo {
      width: 160px;
      height: 70px;
      margin-bottom: 20px;
      img {
        display: block;
        object-fit: contain;
      }
    }
    h3.title {
      font-family: $primaryFonts;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      text-transform: capitalize;
      color: $text-dark;
      margin: 0;
      padding: 0;
    }
  }
  .view-app-detail {
    table {
      width: 100%;
      td.view-app-left {
        padding: 8px 10px 8px 0px;
        margin: 0;
        font-family: $primaryFonts;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        color: $text-dark;
        flex: auto;
        max-width: 40%;
      }
      td.view-app-right {
        padding: 8px 10px 8px 0px;
        margin: 0;
        font-family: $primaryFonts;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: $text-dark;
        flex: auto;
        max-width: 60%;
      }
    }
    .dates {
      margin-top: 20px;
      width: 80%;
      td {
        padding-right: 20px;
        label {
          font-family: $primaryFonts;
          font-style: normal;
          font-weight: bold;
          font-size: 10px;
          line-height: 10px;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          color: #bdbdbd;
        }
        input {
          font-family: $primaryFonts;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0.05em;
          text-transform: capitalize;
          color: $text-dark;
        }
      }
    }

    .progress-bar-new {
      background-color: $portalMainColor;
      height: 15px;
      border-radius: 20px;
    }
  }

  .tab-content.applicant-content {
    margin-top: -1px;
    background: #fff;
    border: none;
    max-width: 100%;
    .profile-student {
      //padding: 0;
      .nav-tabs {
        border: none !important;
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 3px;
        list-style: none;
        min-height: auto;
        li {
          position: relative;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
          letter-spacing: 0.05em;
          text-transform: capitalize;
          color: #4f4f4f;
          text-shadow: none;
          a {
            font-family: $primaryFonts;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            width: 100%;
            min-height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            letter-spacing: 0.05em;
            text-transform: capitalize;
            color: #4f4f4f;
            text-shadow: 0 0 5px #e6e6e6;
            background-color: #fff !important;
            padding: 14px 0 !important;
            margin: 0;
            &.active,
            &.selected {
              font-weight: bold !important;
              background-color: #fff !important;
              font-size: 12px !important;
              line-height: 14px !important;
              margin: 0 !important;
            }
          }
        }
      }
      .tab-content {
        width: 100% !important;
        max-width: 100%;
        background-color: #f6f8fa;
        margin: 0;
        .progress-box-23 .progress {
          background-color: #e0e0e0;
          height: 5px;
        }
        .progress-box-23 .progress-bar {
          background: $portalMainColor;
          border-radius: 6.5px;
        }
        .progress-box-23 {
          padding-left: 0px !important;
          padding-right: 0px !important;
        }
        .apppersonalinfo,
        .appeducation,
        .apptestscore,
        .appbackgroundinfo,
        .upload-docment {
          padding: 40px 45px;
        }

        .pr-header {
          h3 {
            font-style: normal;
            font-weight: normal;
            font-size: 25px;
            line-height: 29px;
            align-items: center;
            text-transform: capitalize;
          }
        }
        .testScore {
          .pr-header {
            margin: 0;
            padding: 0;
          }
        }

        label {
          font-style: normal;
          font-weight: 800;
          font-size: 9px;
          line-height: 12px;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          color: #979797;
        }
        select.form-control,
        select.custom-select,
        .form-control {
          margin: 0;
          border: none;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          align-items: center;
          letter-spacing: 0.05em;
          color: #000000;
        }

        .completeProfile {
          &.alert-dismissible {
            background: #ffffff;
            border: 1px solid #f8b7c3;
            border-radius: 2px;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: $text-dark;
            padding: 22px;
            margin-bottom: 24px;
            .close {
              font-family: $primaryFonts;
              font-size: 55px;
              font-weight: 400;
              color: #444;
              margin: 0;
              padding: 0 20px;
              text-shadow: none;
              opacity: 0.8;
              span {
                font-family: $primaryFonts;
                font-weight: 400;
              }
            }
          }
        }
        .personal-box-inner,
        .destination-box {
          margin-bottom: 40px;
          .app-personal-editbtn {
            // display: flex;
            // justify-content: space-around;
            // align-items: center;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            text-align: right;
            letter-spacing: 0.05em;
            color: #4da1ff;
            padding: 0 !important;
            margin: 0 !important;

            img {
              width: 14px;
              height: 14px;
              margin-top: -10px;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

.togle_switch.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 18px;
}

.togle_switch.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.togle_switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.togle_switch .slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.togle_switch input:checked + .slider {
  background-color: #2196f3;
}

.togle_switch input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.togle_switch input:checked + .slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}

.togle_switch .slider.round {
  border-radius: 34px;
}
.togle_switch .slider.round:before {
  border-radius: 50%;
}

.applicationOverviewTitle {
  display: flex;
  align-items: center;
  .badge {
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    text-transform: uppercase;
    border-radius: 4px;
    padding: 8px 18px;
    margin: 0 0 0 15px;
    display: flex;
    align-items: center;
    img {
      width: 14px;
      height: 14px;
      display: block;
      margin-right: 5px;
    }
    &.badge-new {
      background-color: #e9f1fb;
      color: #4da1ff;
    }
    &.badge-assigned,
    &.badge-processing,
    &.badge-document_verification {
      background-color: #eeeeee;
      color: #828282;
    }
    &.badge-accepted,
    &.badge-enrolment_confirmed {
      background-color: #dafce9;
      color: #1ed974;
    }
    &.badge-rejected,
    &.badge-declined {
      background-color: #ffeded;
      color: #ff4f4f;
    }
    &.badge-pending_payment {
      background-color: #fff1df;
      color: #ffa12a;
    }
    &.badge-coe_sent {
      background-color: #e7f2ff;
      color: #4da1ff;
    }
    &.badge-transparent {
      background-color: transparent;
      color: $text-dark;
      padding: 8px 5px;
      strong {
        display: inline-block;
        margin-right: 5px;
        font-size: 11px;
      }
    }
  }
}

.commentSection {
  background: #ffffff;
  border: 1px solid #c1dbff;
  box-sizing: border-box;
  box-shadow: 1px 1px 12px rgba(144, 168, 190, 0.4);
  border-radius: 5px;
  position: absolute;
  top: 75%;
  right: 16px;
  z-index: 50;
  opacity: 0;
  display: none;
  transition: all 0.3s linear;
  width: 100%;
  max-width: 380px;
  min-width: 380px;
  color: $text-dark;
  &.active {
    opacity: 1;
    display: block;
  }

  .msgBox {
    padding: 25px 20px;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        margin: 0 0 15px;
        padding: 0;
        list-style: none;
        span.avatar {
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          letter-spacing: 0.05em;
          text-transform: capitalize;
          color: #ffffff;
          background-image: none !important;
          border-radius: 50%;
          width: 28px;
          height: 28px;
          &.p,
          &.P {
            background-color: #b91950;
          }
          &.s,
          &.S {
            background-color: #f29724;
          }
          &.j,
          &.J {
            background-color: #5d3f96;
          }
        }
        .actionBtn {
          background-color: transparent !important;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: 0;
          top: 0;
          border-radius: 30px;
          border: none;
          padding: 0;
          margin: 0;
          img {
            display: block;
            margin: 0;
            opacity: 0.5;
          }
          &:hover,
          &:focus,
          &:focus-within,
          &:active:focus {
            outline: none;
            border: none;
            box-shadow: none;
            background-color: rgba($color: $grey_rgb_color, $alpha: 0.2);
            img {
              opacity: 1;
            }
          }
        }
        .details {
          padding: 0 10px !important;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.05em;
          text-transform: capitalize;
          color: #4f4f4f;
          strong {
            display: block;
          }
          date {
            display: block;
            opacity: 0.5;
            margin: 5px 0;
          }
          .msg {
            line-height: 16px;
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            &.edit {
              border: 1px solid #e5e5e5;
              border-radius: 5px;
            }
            .text {
              display: block;
              text-align: left;
              width: 100%;
              &.hide {
                display: none;
              }
              &.show {
                display: block;
              }
            }
            textarea {
              width: 100%;
              resize: none;
              height: 60px;
              background: #ffffff;
              border: none;
              padding: 10px 14px;
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 16px;
              &:hover,
              &:focus,
              &:focus-within {
                outline: none;
              }
              &.hide {
                display: none;
              }
              &.show {
                display: block;
              }
            }
            .btn {
              width: 60px;
              background: $text-dark;
              border-radius: 2px;
              margin: 10px;
              padding: 3px;
              &.hide {
                display: none;
              }
              &.show {
                display: block;
              }
            }
          }
        }
      }
    }
  }
  .addComment {
    width: 100%;
    height: 100px;
    resize: none;
    border: none;
    border-top: 1px solid #e6e6e6;
    padding: 15px 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    color: $text-dark;
    &:hover,
    &:focus,
    &:focus-within {
      outline: none;
    }
  }
  .commentFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    .buttons {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      .btn {
        width: 45%;
        margin: 0 5px;
        padding: 10px 15px;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        text-align: center;
        border-radius: 2px;
        border: none;
        &.btn-primary {
          background-color: #4da1ff;
          &:hover,
          &:focus {
            background-color: $primary-dark;
          }
        }
        &.btn-secondary {
          background-color: #e6e6e6;
          color: #4f4f4f;
          &:hover,
          &:focus {
            background-color: rgba($color: $grey-rgb-color, $alpha: 0.3);
          }
        }
      }
    }
    .icons {
      width: 80px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .btn {
        width: auto;
        margin: auto;
        padding: 10px 5px;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        text-align: center;
        border-radius: 2px;
        border: none;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 17px;
          height: 17px;
          display: block;
          margin: 0;
        }
        &:hover,
        &:focus {
          background-color: transparent;
          img {
            filter: brightness(60%);
          }
        }
      }
    }
  }
}

.msgAction {
  .popover-body {
    padding: 0;
    span {
      display: block;
      padding: 8px 30px 8px 12px;
      cursor: pointer;
      background-color: $primary-color;
      color: $white;
    }
  }
  .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
    border-bottom-color: $primary-color;
  }
  .bs-popover-auto[x-placement^="top"] > .arrow::after {
    border-top-color: $primary-color;
  }
}

.messageModalSection {
  .messageModalSection {
    display: flex;
    justify-content: flex-start;
    align-self: center;
    margin: -15px 0 25px;
    .userPic {
      width: 58px;
      height: 58px;
      border-radius: 58px;
      display: inline-block;
    }
    strong {
      display: inline-block;
      padding: 15px;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.05em;
      text-transform: capitalize;
      color: $text-dark;
    }
  }
  .tox.tox-tinymce {
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid #e9eff4;
  }
  .dragNdrop {
    width: 100%;
    margin: 20px 0 0;
    padding: 40px 20px;
    text-align: center;
    background: #eef8ff;
    border: 1px dashed #4da1ff;
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 12px;
    color: #444444;
    p {
      margin: 0;
    }

    .btn.btn-primary {
      border-radius: 4px;
      background: #4da1ff;
      border: 1px solid #4da1ff;
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 13px;
      color: #ffffff;
      padding: 6px 11px;
      margin: 0 0 15px;
      img {
        margin-right: 5px;
      }
    }
  }
  .fileList {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    margin: 10px 0 0;
    .box {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      background: #f2f2f2;
      border-radius: 2px;
      width: calc(50% - 20px);
      margin: 10px 10px;
      padding: 10px;
      position: relative;
      .fileIcon {
        width: 31px;
        height: 31px;
        display: block;
      }
      .fileName {
        padding-left: 15px;
        width: 80%;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 22px;
        color: #444444;
        word-break: break-all;
        .fileSize {
          opacity: 0.5;
          font-size: 12px;
        }
      }
      .fileRemove {
        position: absolute;
        top: 10px;
        right: 8px;
        width: 20px;
        height: 20px;
        display: block;
      }
    }
  }
}

.attachmentOption {
  background: #ffffff;
  box-shadow: 1px 1px 12px rgba(144, 168, 190, 0.4);
  border-radius: 5px;
  border: none !important;
  .dropdown-item {
    border: none;
    font-family: $primaryFonts;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 40px;
    letter-spacing: 0.05em;
    color: #000000;
    padding: 0 17px;
    margin: 0;
    &:hover,
    &:focus {
      background-color: rgba(77, 161, 255, 0.1);
    }
    &.header {
      font-weight: 500;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0.05em;
      color: #bdbdbd;
      padding: 16px 17px 0;
      &:hover,
      &:focus {
        background-color: transparent;
      }
    }
  }
}

.list-card {
  .uni-no-padd.card-body {
    ul.university-list.list-group {
      background: none transparent;
      height: auto;
      min-height: auto;
    }
  }
}

// New css

.commentPageHeader {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  box-shadow: 1px 1px 12px rgba(144, 168, 190, 0.33);
  background-color: #fff;
  margin-bottom: 30px;
  a {
    height: 90px;
    display: flex;
    align-items: center;
    margin: 0 !important;
  }
}
.commentListSec {
  height: 100vh;
  // overflow-y: auto;
  background: #fafafa;
  border-left: 1px solid #e6e6e6;
  .comment_top_section {
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e6e6e6;
    background-color: #ffffff;
    padding: 0 20px;
    margin-bottom: 26px;
    p {
      margin: 0;
      font-family: $primaryFonts;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 16px;
      letter-spacing: 0.05em;
      text-transform: capitalize;
      color: #333333;
    }
    .rightOptions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      select.form-control {
        width: 70px;
        border: none;
        margin-right: 15px;
      }
      select {
        font-weight: 400;
        font-size: 15px;
        color: #333;
        background-image: url(../../assets/img/arrow_down_gray_icon.svg);
        background-size: 10px;
        background-position: center right;
      }
      .count {
        position: relative;
        svg {
          width: 22px;
          height: auto;
          path {
            fill: #ffffff;
            stroke: #bdbdbd;
            stroke-width: 3px;
          }
        }
        .badge {
          position: absolute;
          top: -3px;
          right: -6px;
          z-index: 5;
          font-family: $primaryFonts;
          font-style: normal;
          font-weight: bold;
          font-size: 9px;
          line-height: 9px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          padding: 3px 6px;
          &.badge-primary {
            background-color: #4da1ff;
            color: #ffffff;
          }
        }
      }
    }
  }
  ul.commentList {
    margin: 0 10px;
    padding: 0;
    list-style: none;
    position: relative;
    li {
      padding: 14px 14px;
      // cursor: pointer;
      &:last-child {
        position: sticky;
        bottom: 0;
        left: 0;
        width: 100%;
      }
      .commentCounter {
        position: relative;
        display: inline-block;
        svg {
          width: 22px;
          height: auto;
          path {
            fill: #4da1ff;
          }
        }
        span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #fff;
          font-size: 12px;
        }
      }
      p {
        font-size: 12px;
        line-height: 130%;
        letter-spacing: 0.05em;
        text-transform: capitalize;
        color: #000000;
        font-weight: 600;
        margin: 8px 0 0px;
      }
      span {
        font-weight: 300;
        font-size: 12px;
        line-height: 130%;
        letter-spacing: 0.05em;
        color: #000000;
      }
      &.readComment {
        .commentCounter {
          svg {
            path {
              fill: #d7d7d7;
            }
          }
        }
        p,
        span {
          color: rgba(79, 79, 79, 0.5);
        }
      }
      // &:hover {
      // 	background-color: #ECF5FF;
      // }
    }
  }
}

.react-confirm-alert-overlay {
  z-index: 1500;
}

.commentBoxIn {
  padding: 12px 0px !important;
}

.commentBox {
  max-width: 350px;
  height: auto;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 20px;
  background: #ffffff;
  border-radius: 7px;

  .addComment {
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    background: #ffffff;
    border: 0.87817px solid #e9ebf0;
    border-radius: 7px;
    width: 100%;
    margin: auto;
    height: 96px;
    padding: 10px 24px;
    margin-bottom: 15px;
  }

  span.avatar {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    color: #ffffff;
    background-image: none !important;
    border-radius: 50%;
    width: 28px;
    height: 28px;

    &.p,
    &.P {
      background-color: #b91950;
    }

    &.s,
    &.S {
      background-color: #f29724;
    }

    &.j,
    &.J {
      background-color: #5d3f96;
    }
  }

  .commentUser {
    margin-left: 15px;
    margin-right: 20px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14.2016px;
    line-height: 19px;

    color: #000000;
  }

  .commentDate {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14.2016px;
    line-height: 21px;
    text-align: right;
    color: #bdbdbd;
  }

  .commentText {
    top: calc(50% - 85px / 2 - 486.19px);
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14.2016px;
    line-height: 21px;
    color: #4f4f4f;
  }
}

.applicationAddComment {
  .commentPageHeader {
    box-shadow: none;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 52px;
    .backarrow {
      display: inline-flex;
    }
  }
  .commentListSec {
    .comment_top_section {
      .comment_top_left {
        display: flex;
        align-items: center;
        .count {
          margin-left: 20px;
          position: relative;
          svg {
            width: 30px;
            height: 25px;
          }
          span.badge {
            position: absolute;
            top: -4px;
            right: -4px;
            background-color: #4da1ff;
          }
        }
      }
      .dropdown {
        .dropdown-menu {
          width: 90px;
          min-width: 1px;
          background: #ffffff;
          box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.33);
          border-radius: 4px;
          border: none;
          padding: 6px;
          .dropdown-item {
            border: none;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #323c47;
            padding: 10px 15px;
            border: 0;
          }
        }
        .dropdown-toggle,
        .btn-secondary {
          border: 0;
          height: auto;
          font-weight: 400;
          font-size: 15px;
          color: #333333;
          padding: 0;
          background-color: transparent;
          display: flex;
          align-items: center;
          &:after {
            background-image: url(../../assets/img/arrow_down_gray_icon.svg);
            background-color: transparent;
            background-repeat: no-repeat;
            background-position: center;
            margin-left: 5px;
          }
          &:focus,
          &:hover {
            background-color: transparent !important;
            border: 0 !important;
            box-shadow: none;
            &:after {
              background-color: transparent;
            }
          }
          &:after {
            width: 20px;
            height: 20px;
            border: 0;
            background-color: transparent;
          }
        }
      }
    }
    .commentList {
      .commentBox {
        button.blueBtn {
          background: #4da1ff;
          color: #fff;
          border-radius: 7px !important;
          width: 80px;
          height: 35px;
          padding: 0;
          border: 1px solid transparent;
          font-size: 12px;
          line-height: 12px;
          display: inline-block;
        }
        button.grayBtn {
          background: #f2f2f2;
          color: #4f4f4f;
          border-radius: 7px !important;
          width: 80px;
          height: 35px;
          padding: 0;
          border: 1px solid transparent;
          font-size: 12px;
          line-height: 12px;
          margin-right: 15px;
          display: inline-block;
        }
        span.avatar {
          width: 28px;
          height: 28px;
          flex: 0 0 28px;
          border-radius: 50%;
          color: #fff;
        }
        .commentTop {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        }
        .commentAction {
          button {
            font-weight: 600;
            font-size: 12px;
            line-height: 13px;
            background-color: transparent;
            border: 0;
            color: #444444;
            padding: 0;
            margin-left: 15px;
            display: inline-flex;
            align-items: center;
            svg {
              margin-right: 5px;
            }
            &:focus {
              outline: 0;
              box-shadow: none;
            }
            &.deleteBtn {
              color: #ed6368;
            }
            &.editBtn {
              color: #444444;
            }
            &.rplyBtn {
              color: #4da1ff;
            }
          }
        }
        .commentText {
          margin-top: 14px;
        }
        ul {
          padding: 0;
          margin: 15px 0 15px 10px;
          border-left: 1px solid #e9ebf0;
        }
        .collpaseBtn {
          font-weight: 400;
          font-size: 13px;
          line-height: 19px;
          color: #4da1ff;
          background: #f5f6fa;
          border-radius: 8px;
          width: 100px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: auto;
          cursor: pointer;
          svg {
            margin-right: 8px;
            transition: all 0.5s ease;
          }
          &.hideBtn {
            svg {
              transform: rotate(180deg);
            }
          }
        }
        .commentActionWrap {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          flex-wrap: wrap;
          margin-top: 26px;
        }
      }
    }
  }
  @media (max-width: 991px) {
    .commentPageHeader {
      margin-bottom: 30px;
      .backarrow {
        height: 50px;
        padding-left: 20px;
      }
    }
    .commentListSec {
      .comment_top_section {
        height: 50px;
      }
      .commentList {
        margin: 0;
        padding: 0;
      }
    }
  }
}

.pdfViewer {
  width: 100%;
  min-height: calc(85vh - 460px);
  height: 100vh;
}

.applicationPDFViewWrap {
  padding-right: 100px;
  @media (max-width: 1024px) {
    padding-right: 20px;
  }
  @media (max-width: 991px) {
    padding-left: 20px;
  }
}
