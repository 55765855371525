$grey-background-color: #626262;
// Variable overrides
$primary-green-color: #2B6A6C;
$secondary-dark-color: #54595E;
$dark-medium: #4F4F4F;
$Gainsboro: #D8D8D8;
$primary-light-color: #1f5c5e;
$light-black: rgba(0, 0, 0, 0.25);
$silver: #BDBDBD;
$primary-color: #4da1ff;
$primary-rgb-color: rgb(77, 161, 255);
$primary-dark: #0f6fde;
$grey-rgb-color: rgb(68, 68, 68);
$grey-light: #dadada;
$black: #000;
$white: #fff;
$offWhite: #f2f2f2;
$bluishWhite: #f7f9fc;
$text-dark: #444444;
//$btn-sencondary: #310909;
$btn-sencondary: #555;
$btn-sencondary-dark: #333;
$lable-color: #323c47;
$input-border: #e0e0e0;
$table-head: #f9f9f9;
$green-dark: #2d6163;

$red-color: #ff4f4f;
$red-status: #ff4f4f;
$green-color: #2ed47a;
$green-status: #2ed47a;
$blue-color: #4da1ff;
$blue-status: #4da1ff;

$secondary-label-color: #333333;
$black-background-color: #444444;

$studentOrange: #f4aa4e;
$ppRed: #973758;
$epGreen: #2d6163;

$portalMainColor: $epGreen;

$primaryFonts: "Open Sans", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
  Oxygen, Ubuntu, Cantarell, sans-serif;
$railwayFonts: Raleway;