@import "../../../scss/style.scss";

table.applicationLisitng {
  width: 100%;
  border: 1px solid $input-border;
  thead {
    tr {
      th {
        background-color: $table-head;
        border-top: 1px solid $input-border;
        border-bottom: 1px solid $input-border;
        position: relative;
        font-family: $primaryFonts;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        color: $text-dark;
        letter-spacing: 0.03em;
        text-align: center;
        vertical-align: middle;
        input[type="radio"],
        input[type="checkbox"] {
          position: relative;
          margin: 0;
          padding: 0;
        }
        .btn.btn-secondary {
          padding: 7px 18px;
          margin: 0;
          border-radius: 4px;
          background-color: $text-dark;
          color: $white;

          font-family: $primaryFonts;
          font-style: normal;
          font-weight: normal;
          font-size: 10px;
          line-height: 11px;
          align-items: center;
          letter-spacing: 0.5px;

          display: flex;
          justify-content: space-evenly;
          align-items: center;
          white-space: nowrap;
          &:hover,
          &:focus {
            background-color: $primary-color;
            border-color: $primary-dark;
          }
        }
      }
      &.fillterSection {
        th {
          background-color: $white;
          vertical-align: middle;
          text-align: center;
          input,
          select {
            font-family: $primaryFonts;
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 11px;
            align-items: center;
            letter-spacing: 0.5px;
          }
          input[type="radio"],
          input[type="checkbox"] {
            position: relative;
            margin: 0;
            padding: 0;
          }
          .btn.btn-secondary {
            padding: 7px 18px;
            margin: 0;
            border-radius: 4px;
            background-color: $text-dark;
            color: $white;

            font-family: $primaryFonts;
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 11px;
            align-items: center;
            letter-spacing: 0.5px;

            display: flex;
            justify-content: space-evenly;
            align-items: center;
            white-space: nowrap;
            &:hover,
            &:focus {
              background-color: $primary-color;
              border-color: $primary-dark;
            }
          }
        }
        @media (max-width: 768px) {
          input {
            width: 80%;
            margin: auto;
          }
        }
      }
    }
  }
  tbody {
    &.listing {
      td {
        vertical-align: middle;
        overflow: hidden;
        border: none;
        border-bottom: 1px solid rgba($color: $grey-rgb-color, $alpha: 0.17);
        border-top: 1px solid rgba($color: $grey-rgb-color, $alpha: 0.17);

        font-family: $primaryFonts;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        text-transform: capitalize;
        input[type="radio"],
        input[type="checkbox"] {
          position: relative;
          margin: 0;
          padding: 0;
        }
        .btn {
          padding: 7px 18px;
          margin: 0;
          border-radius: 4px;
          font-style: normal;
          font-family: $primaryFonts;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          display: flex;
          align-items: center;
          white-space: nowrap;
          img {
            margin: 0 6px 0 -6px;
          }
        }
        .receiptBtn {
          color: #4da1ff;
          background: #e7f2ff;
          border: 0.84px solid #4da1ff;
          border-radius: 1.6162px;
          font-weight: 600;
          font-size: 8.4px;
        }
        .invoiceBtn {
          color: #ffffff;
          background: #ffb960;
          border: 0.84px solid #ffb960;
          border-radius: 1.6162px;
          font-weight: 600;
          font-size: 8.4px;
        }
      }
    }
  }
}
.borderRight {
  border-right: 1px solid #dddfec;
  min-height: calc(100vh - 180px);
}
.rightTable {
  padding-top: 30px;
  padding-left: 20px;
  .title {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 22px;
  }
  .pageHeader .pageTitle {
    margin-bottom: 0;
    font-weight: 800;
    font-size: 12px;
    text-transform: uppercase;
  }
}
.paymentMethodListing {
  background-color: #fff;
  border: 1px solid #ededed;
  margin-top: 15px;
  .pageHeader {
    padding: 17px 32px;
    margin: 0;
    .pageTitle {
      text-transform: none;
      font-size: 15px;
    }
    .headerExtraItems {
      button.btn-outline-secondary {
        border: 0.85px solid #4da1ff;
        font-size: 10.2px;
        font-weight: bold;
        letter-spacing: 0.05em;
        color: #4da1ff;
      }
    }
  }
  .table {
    margin: 0;
    border: 0;
    thead th {
      background-color: #f6f8fa;
      text-align: left;
    }
    tbody.listing td {
      font-weight: normal;
      text-align: left;
      &:first-child {
        padding-left: 32px;
      }
      &:last-child {
        padding-right: 32px;
        text-align: right;
      }
      .btn.action_icon {
        background-color: transparent;
        padding: 13px 0;
        padding-left: 30px;
        border: 0;
        display: inline-block;
        .icon {
          margin: 0;
        }
      }
    }
  }
}
.addPaymentMethodSection {
  margin: 0;
  padding:20px 80px 50px;
  border-top: 0;
  fieldset.form-group {
    margin: 0;
    position: relative;
    span.vaslidateImg {
      position: absolute;
      bottom: 33px;
      right: 17px;
      z-index: 999;
    }
  }
  &.bgGray {
    background-color: #f3f3f3;
    border: 1px solid #ededed;
  }
  &.bgWhite {
    background-color: #ffffff;
    border: 1px solid #ffffff;
  }
  .methodBtn button.btn {
    font-weight: 600;
    font-size: 12.558px;
    letter-spacing: 0.05em;
    border-radius: 3.34879px;
    display: inline-block;
    padding: 10px 10px;
    min-width: 124px;
    min-height: 24px;
    line-height: normal;
    border-radius: 3.34879px;
    &.cancel-btn {
      background: #c4c4c4;
      border: 1px solid #c4c4c4;
    }
    &.save-btn {
      background: #4da1ff;
      border: 1px solid #4da1ff;
    }
  }
  .addForm {
    margin: 0 !important;
  }
  &.createProgram {
    background: rgba(77, 161, 255, 0.1);
    border: 1px solid #4da1ff;
    box-sizing: border-box;
    border-radius: 3px;
    .addForm input {
      font-weight: 500;
      font-size: 13px;
      margin-bottom: 20px;
      border: 1px solid #bdbdbd;
    }
  }
}
.createProgram {
  font-family: $primaryFonts;
  font-style: normal;
  .addForm {
    margin: 35px 0 0;
    h5 {
      font-family: $primaryFonts;
      font-style: normal;
      font-weight: 800;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #444444;
      margin: 25px 0;
    }
    legend {
      font-family: $primaryFonts;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 15px;
      letter-spacing: 0.05em;
      color: $portalMainColor;
      margin: 15px 0 25px;
    }
    label {
      font-family: $primaryFonts;
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      text-shadow: none;
      color: #323c47;
    }
    input,
    select,
    textarea {
      border: 1px solid #e9eff4;
      border-radius: 4px;
      font-family: $primaryFonts;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      letter-spacing: 0.05em;
      color: #333333;
      padding: 14px !important;
      margin: 0 0 26px;
      height: 45px;
      &::placeholder {
        opacity: 1 !important;
        color: #828282 !important;
      }
    }
    .form-check {
      padding: 0;
      margin: 0 0 20px;
      .form-check-label {
        font-family: $primaryFonts;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.5px;
        color: #333333;
        text-transform: none;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 30px;
        .form-check-input {
          width: 16px;
          height: 16px;
          position: relative;
          display: inline-block;
          margin: 0 15px 0 10px;
        }
        strong {
          margin-right: 4px;
        }
      }
    }
    textarea {
      resize: none;
    }

    .intakeDate {
      border: 1px solid #e9eff4;
      border-radius: 4px;
      margin: 0;
      padding: 0;
      li {
        margin: 0;
        padding: 6px;
        border-bottom: 1px solid #e9eff4;
        display: flex;
        align-items: center;
        .form-check-label {
          padding: 0;
          margin: 0;
          width: 100%;
        }
      }
    }
  }
}
.svgIconStroke {
  cursor: pointer;
  path {
    stroke: $primary-color;
  }
}
