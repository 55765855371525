.selectedPdfs {
  width: 100%;
  max-width: 327px;
  height: auto;
  background: #FFFFFF;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 14px 20px;
  position: relative;

  .pdfName {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0;
    color: #000000;
  }

  .pdfSize {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1px;
    line-height: 14px;
    color: #979797;
  }
  .pdfFileRemove{
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    width: 13px;
    height: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
      padding: 0;
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }
}