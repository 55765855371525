@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.docs-box {
  background: #f2f2f2;
  min-height: 125px;
}

.mand-label {
  background: #f2f2f2;
  min-height: 125px;
  background: #f2994a;
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  color: #fff;
  padding: 0px 12px 0px 12px;
  font-size: 12px;
}

.mand-label-optional {
  background: #bdbdbd;
  min-height: 125px;
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  color: #fff;
  padding: 0px 12px 0px 12px;
  font-size: 12px;
}

@media only screen and (max-width: 767px) {
  .tab-content.newTabUI .box .row.docs-box {
    margin-right: -15px;
    margin-left: -15px;
    margin-top: 0 !important;
  }
  .tab-content.newTabUI .box .row.docs-box .col-md-11 {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .mand-label,
  .mand-label-optional {
    transform: rotate(0deg);
    writing-mode: unset;
    min-height: unset;
    width: 100%;
    padding: 15px;
  }
}

.file-view {
  font-size: 10px;
  /* background:#E9EFF4; */
  position: relative;
  padding: 3px;
  background-color: #e9eff4;
  border: 1px solid #cadbdb;
  word-break: break-all;
}
.file-view a {
  display: inline-block;
  width: calc(100% - 20px);
}

.file-view-close {
  width: 16px;
}

.add-file-header {
  border: 1px solid #979797;
  border-radius: 4px 4px 2px 2px;
  min-height: 34px;
}

/* .addfilepopup {
  padding: 50px !important;
} */

.add_file_sec {
  max-width: 440px;
  padding: 20px 0px 0px;
}

.afh_icon {
  width: 50px;
  text-align: center;
  min-height: 100%;
  border-left: 1px solid #979797;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addfilepopup h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 50px;
  text-align: center;
  color: #000000;
  padding: 0;
  margin-bottom: 40px;
}

.add-file-header h2 {
  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 0;
  margin-left: 20px;
  color: #444444;
}

.add-file-content {
  border: 1px solid #979797;
  border-radius: 2px 2px 4px 4px;
  border-top: 0;
}

.add-file-content ul {
  margin: 0;
  padding: 0;
}

.add-file-content ul li {
  list-style: none;
  border-bottom: 0.5px solid #979797;
}

.add-file-content .upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  display: -webkit-flex;
  width: 100%;
  cursor: pointer;
}

.add-file-content .upload-btn-wrapper .btn {
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  width: 100%;
  font-family: Raleway;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-align: left;
  text-transform: uppercase;
  color: #444;
  display: flex;
  align-items: center;
}

.add-file-content .upload-btn-wrapper .btn img {
  margin-right: 12px;
}

.add-file-content .upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.drop-img-sec {
  margin-bottom: 30px;
}

/* .drop-img-sec > div {
  border: 1px solid #979797 !important;
  border-radius: 4px !important;
  padding: 18px 20px !important;
  width: 410px !important;
} */

.file-drop {
  position: relative;
  height: 100px;
  width: 100%;
}

.file-drop > .file-drop-target {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 2px;
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
  align-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  align-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  text-align: center;
}

.text-center.pp-btn {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.addfilepopup .text-center.pp-btn button.upload-btn,
.addfilepopup .text-center.pp-btn button.cancle-btn {
  background: #4da1ff;
  border: 1px solid #4da1ff;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  color: #ffffff;
  margin-right: 15px;
  padding: 10px 20px;
  cursor: pointer;
}

.addfilepopup .text-center.pp-btn button.cancle-btn {
  background: #fff;
  border: 1px solid #4da1ff;
  color: #4da1ff;
}

.addfilepopup .text-center.pp-btn button.upload-btn:hover,
.addfilepopup .text-center.pp-btn button.upload-btn:focus,
.addfilepopup .text-center.pp-btn button.cancle-btn:hover,
.addfilepopup .text-center.pp-btn button.cancle-btn:focus {
  background: #454545;
  border: 1px solid #454545;
  color: #ffffff;
}

#uploadDocType {
  border: 1px solid #979797;
  text-transform: uppercase !important;
  padding-right: 50px;
}
