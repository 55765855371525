@import "./scss2/style.scss";

@import "./applicationsListing.scss";

.eligibiltyModal {
  max-width: 500px;
  .eligibilityModalContent {
    padding: 68px;
    font-family: $primaryFonts;
    span {
      font-weight: 700;
      font-size: 30px;
      line-height: 15px;
      color: #444444;
      display: block;
      margin: 30px 0 21px;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 26px;
      color: #444444;
      margin-bottom: 0;
    }
  }
}

.dropdown {
  font-family: $primaryFonts;
  .dropdown-toggle,
  .btn-secondary {
    border: 1px solid #bdbdbd;
    border-radius: 6px;
    background-color: #fff;
    font-family: $primaryFonts;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    color: #7b7b7b;
    padding: 0 0 0 14px;
    height: 40px;
    &::after {
      content: "";
      background-image: url("../assets/img/arrow_down_gray_icon.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-color: rgba(244, 247, 249, 0.4);
      border: none;
      border-left: 1px solid #e2e2e2;
      border-radius: 0 4px 4px 0;
      width: 38px;
      height: 38px;
      margin: 1px 1px 1px 14px;
      padding: 6px 0;
      font-size: 20px;
      text-align: center;
    }
    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within,
    &:active {
      background-color: rgba(239, 239, 239, 0.6) !important;
      border: 1px solid #e2e2e2 !important;
      outline: none;
      box-shadow: none;
      &::after {
        background-color: rgba(239, 239, 239, 0.6) !important;
      }
    }
  }

  .dropdown-menu {
    background: #ffffff;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.33);
    border-radius: 4px;
    border: none;
    padding: 6px;
    .dropdown-item {
      border: none;
      // border-bottom: 1px solid #f2f2f2;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #323c47;
      padding: 10px 15px;
      &:hover,
      &:focus,
      &:focus-visible,
      &:focus-within,
      &:active {
        background-color: rgba(239, 239, 239, 0.6) !important;
        outline: none;
        box-shadow: none;
      }
    }
  }
}

._1Xr9x ._1hDjE ._iP5XP {
  letter-spacing: 0;
}

.scharts .pf-c-chart svg text tspan {
  font-weight: 400;
  font-size: 18px !important;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.05em !important;
  text-transform: capitalize;
  color: #444444;
  white-space: break-spaces;
  word-break: break-all;
  flex-wrap: wrap;
}

.confirmationModal {
  .modal-content {
    border: 0;
    border-radius: 0;
  }
  .modal-heading {
    font-weight: 600;
    font-size: 30px;
    line-height: 41px;
    color: #444444;
    margin-bottom: 16px;
    padding-bottom: 0;
  }
  .msgText {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #444444;
  }
  .actionBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 30px;
    .primaryBtn {
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      color: #ffffff;
      background: $portalMainColor;
      box-shadow: 1px 1px 12px rgba(144, 168, 190, 0.33);
      border-radius: 10px !important;
      text-shadow: none;
      min-width: 135px;
      min-height: 35px;
      border: 1px solid transparent;
    }
  }
}
